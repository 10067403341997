// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.preview-container {
  background: #5D6675;
}

.flex-80 {
  flex: 0 0 80%;
}

.container {
  padding: 20px;
  border-radius: 5px;
}

.preview-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #5D6675; /* Dark gray background */
  padding: 10px; /* Adjust padding as needed */
  color: #999999; /* Light gray text color */
}

.message {
  font-size: 14px;
  flex: 1 0;
  color: #F7F9F9;
  text-align: center;
  padding-left: 180px;
}

.button {
  background-color: #131F2E; /* Darker gray for button */
  border: none;
  padding: 5px 10px;
  border-radius: 3px;
  color: #F7F9F9; /* Blue text color */
  cursor: pointer;
}

.publish-button {
  margin-right: 10px;
  color: #60BFDA;
}

.text-container {
  display: flex;
  align-items: center;
  color: #F4FBFC; /* White text color */
  font-family: sans-serif;
  font-size: 18px;
  font-weight: 500;
}

.text-part {
  margin-right: 15px; /* Adjust spacing between text parts */
}

.selected {
  border-bottom: 1px solid #FA6020;
  color: #60BFDA;
}`, "",{"version":3,"sources":["webpack://./src/features/difaPacketBuilder/components/Header.scss"],"names":[],"mappings":"AAAA;EACI,mBAAA;AACJ;;AAEA;EACI,aAAA;AACJ;;AAEA;EACI,aAAA;EACA,kBAAA;AACJ;;AAEE;EACE,aAAA;EACF,8BAAA;EACA,mBAAA;EACA,yBAAA,EAAA,yBAAA;EACA,aAAA,EAAA,6BAAA;EACA,cAAA,EAAA,0BAAA;AACF;;AAEE;EACE,eAAA;EACA,SAAA;EACA,cAAA;EACA,kBAAA;EACA,mBAAA;AACJ;;AAEE;EACE,yBAAA,EAAA,2BAAA;EACA,YAAA;EAEA,iBAAA;EACA,kBAAA;EACA,cAAA,EAAA,oBAAA;EACA,eAAA;AAAJ;;AAGE;EACE,kBAAA;EACA,cAAA;AAAJ;;AAGE;EACE,aAAA;EACA,mBAAA;EACA,cAAA,EAAA,qBAAA;EACA,uBAAA;EACA,eAAA;EACA,gBAAA;AAAJ;;AAIE;EACE,kBAAA,EAAA,sCAAA;AADJ;;AAIE;EACE,gCAAA;EACA,cAAA;AADJ","sourcesContent":[".preview-container{\n    background: #5D6675;\n}\n\n.flex-80{\n    flex: 0 0 80%;\n}\n\n.container {\n    padding: 20px;\n    border-radius: 5px;\n    \n  }\n  .preview-container{\n    display: flex;\n  justify-content: space-between;\n  align-items: center;\n  background-color: #5D6675; /* Dark gray background */\n  padding: 10px; /* Adjust padding as needed */\n  color: #999999; /* Light gray text color */\n  }\n\n  .message {\n    font-size: 14px;\n    flex: 1 0;\n    color: #F7F9F9;\n    text-align: center;\n    padding-left: 180px;\n  }\n  \n  .button {\n    background-color: #131F2E; /* Darker gray for button */\n    border: none;\n\n    padding: 5px 10px;\n    border-radius: 3px;\n    color: #F7F9F9; /* Blue text color */\n    cursor: pointer;\n  }\n\n  .publish-button{\n    margin-right: 10px;\n    color: #60BFDA;\n  }\n  \n  .text-container {\n    display: flex;\n    align-items: center;\n    color: #F4FBFC; /* White text color */\n    font-family: sans-serif;\n    font-size: 18px;\n    font-weight: 500;\n\n  }\n  \n  .text-part {\n    margin-right: 15px; /* Adjust spacing between text parts */\n  }\n\n  .selected {\n    border-bottom: 1px solid #FA6020;\n    color: #60BFDA;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
