import React, { useState } from "react";
import DifaSwitch from "../../../common/switch/DifaSwitch";
import UploadDocuments from "../../../common/uploadFiles/UploadDocuments";
import PDFViewer from "../../../common/pdfViewer/FileViewer";
import { useDispatch, useSelector } from "react-redux";
import { setProduction } from "../../difaPacketBuilderSlice";

const Production: React.FC<{ source: string }> = ({ source }) => {
  const { production } = useSelector((state: any) => state.difaPacketBuilder);
  const dispatch = useDispatch();

  const [expanded, setExpanded] = useState(false);
  const onExpand = () => {
    setExpanded(!expanded);
    dispatch(setProduction({ ...production, isSelected: !expanded }));
  };
  const isPreview = source === "preview-packet";

  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);

  function handleFilesChange(files: File[]): void {
    setUploadedFiles(files);
    const fileUrl: string = URL.createObjectURL(files[0]);
    dispatch(setProduction({ ...production, fileLink: fileUrl }));
  }

  // const esp_sizing = `${process.env.PUBLIC_URL}/Horton_Unit_4AH_RB_Dec_ESPReport.pdf`;

  return (
    <>
      {isPreview ? (
        production?.isSelected ? (
          <PDFViewer file={production?.fileLink} />
        ) : (
          <h3 className="file-error-msg">Nothing selected to preview</h3>
        )
      ) : (
        <div className="difa-widget-containers">
          <div className="header-main">
            <div className="flex flex-row justify-between flex-wrap">
              <div>
                <h3>Production plot</h3>
                <h5>
                  Import or manually add production data to generate production
                  plots
                </h5>
              </div>
              <div className="flex flex-row items-center gap-3">
                <DifaSwitch checked={expanded} onChange={onExpand} />
                <h4 className="whitespace-nowrap">Include in report</h4>
              </div>
            </div>
          </div>
          <div className="block w-full border-t border-dark p-6">
            <UploadDocuments
              fileType="pdf"
              maxFileSize={100} // Max size: 100 MB
              onFilesChange={handleFilesChange}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default Production;
