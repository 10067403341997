import React, { ChangeEvent, useState } from 'react'
import DifaSwitch from '../../../common/switch/DifaSwitch'
import UploadDocuments from '../../../common/uploadFiles/UploadDocuments';
import { useDispatch, useSelector } from 'react-redux';
import { setSizingSummary } from '../../difaPacketBuilderSlice';


const SizingSchematic = () => {
    const { sizingSummary } = useSelector(
        (state: any) => state.difaPacketBuilder
    );
    const dispatch = useDispatch();


    const [expanded, setExpanded] = useState(false);
    const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);

    function handleFilesChange(files: File[]): void {
        setUploadedFiles(files);
        const fileUrl: string = URL.createObjectURL(files[0]);      
        dispatch(setSizingSummary({...sizingSummary, fileLink: fileUrl}))
    }

    const onExpand = (e: ChangeEvent<HTMLInputElement>) => {
        const checked = e.target.checked;
        setExpanded(checked);
        dispatch(setSizingSummary({...sizingSummary, isSelected: checked}))
    };

    return (
        <div className="difa-widget-containers">
            <div className="header-main">
                <div className="flex flex-row justify-between">
                    <div>
                        <h3>ESP sizing report</h3>
                        <h5>Provides an understanding of the the ESP design and expected performance.</h5>
                    </div>
                    <div className="flex flex-row items-center gap-3">
                        <DifaSwitch checked={expanded} onChange={onExpand} />
                        <h4 className="whitespace-nowrap">Include in report</h4>
                    </div>

                </div>
            </div>
            <div className="block w-full border-t border-dark p-6">
                <UploadDocuments
                    fileType="pdf"
                    maxFileSize={100} // Max size: 100 MB
                    onFilesChange={handleFilesChange}
                />
            </div>
        </div>
    )
}

export default SizingSchematic
