import React, { useRef, useEffect, useState } from "react";
import * as pdfjsLib from "pdfjs-dist";
import "pdfjs-dist/web/pdf_viewer.css";
import * as pdfjs from "pdfjs-dist";

pdfjs.GlobalWorkerOptions.workerSrc = `${process.env.PUBLIC_URL}/pdf.worker.mjs`;

const PDFViewer = ({ file }) => {
  const canvasRef = useRef();
  const [pdf, setPdf] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [numPages, setNumPages] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        const loadedPdf = await pdfjsLib.getDocument(file).promise;
        setPdf(loadedPdf);
        setNumPages(loadedPdf.numPages);
      } catch (error) {
        console.error("Error loading PDF:", error);
      }
    })();
  }, [file]);

  useEffect(() => {
    (async () => {
      if (!pdf) return;

      const page = await pdf.getPage(currentPage);
      const viewport = page.getViewport({ scale: 1.5 });
      const canvas = canvasRef.current;
      if (!canvas) return;
      const context = canvas.getContext("2d");

      canvas.height = viewport.height;
      canvas.width = viewport.width;

      const renderContext = {
        canvasContext: context,
        viewport: viewport,
      };
      page.render(renderContext);
    })();

  }, [pdf, currentPage]);
  const goToPrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const goToNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, numPages));
  };

  return (
    <div>
      <div className="text-center">
        <button
          onClick={goToPrevPage}
          className={`btnNextPrev m5 ${
            currentPage === 1 ? "cursor-not-allowed" : "cursor-pointer"
          }`}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        <button
          onClick={goToNextPage}
          className={`btnNextPrev ${
            currentPage === numPages ? "cursor-not-allowed" : "cursor-pointer"
          }`}
          disabled={currentPage === numPages}
        >
          Next
        </button>
      </div>
      <div className="pdf-viewer-container">
        {file ? (
          <canvas ref={canvasRef} />
        ) : (
          <h3 className="file-error-msg">No file found</h3>
        )}
      </div>
    </div>
  );
};

export default PDFViewer;