import React from "react";
import "./Loader.css";

const Loader = ({ isOverlay = false, showText = true }) => {
  return (
    <>
      <div className={`${isOverlay ? "spinner-overlay" : ''} ${!showText ? 'w-full' : ''} `}>
        <div className="spinner-container">
          <div className="spinner"></div>
          {showText && <div className="loader-label-loading">Loading...</div>}
        </div>
      </div>
    </>
  );
};

export default Loader;
