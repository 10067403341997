import React, { useState } from 'react'
import DifaSwitch from '../../../common/switch/DifaSwitch';
import { ReactComponent as SearchIcon } from "../../../../images/search-md.svg";
import GeneralReportTable from './documentationDetails/GeneralReportTable';
import IWOServiceReport from './documentationDetails/ServiceReport';
import AdditionalDocument from './documentationDetails/AdditionalDocument';
import OptimizeTicket from './documentationDetails/OptimizeTicket';
import { useDispatch, useSelector } from 'react-redux';
import { setWellInstallation } from '../../difaPacketBuilderSlice';

interface Tab {
  label: string;
  component: React.ComponentType<{ searchQuery: string, source: string }>;
}

const DPBDocumentation: React.FC<{ wellApiNumber: string, source: string }> = ({ wellApiNumber, source }) => {
  const { wellInstallation } = useSelector(
    (state: any) => state.difaPacketBuilder
);
const dispatch = useDispatch();

  const [expanded, setExpanded] = useState(false);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const isPreview = source === "preview-packet";
  const tabs: Tab[] = [
    { label: "General reports", component: GeneralReportTable },
    { label: "Service reports", component: IWOServiceReport },
    { label: "Optimization tickets", component: OptimizeTicket },

  ];
  if (!isPreview)
    tabs.push({ label: "Additional documents", component: AdditionalDocument });


  const onExpand = () => {
    setExpanded(!expanded);
    dispatch(setWellInstallation({...wellInstallation, isDocumentSelected: !expanded}))
    
  }

  const setInputPlaceholder = () => {
    switch (activeTabIndex) {
      case 0:
        return "Search report name";
      case 1:
        return "Search report number";
      case 2:
        return "Search Ticket Number";
      default:
        return "Search report name";
    }
  };

  const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (activeTabIndex === 0) {
      if (/^[a-zA-Z]*$/.test(value)) {
        setSearchQuery(value); // Accept only text
      }
    } else {
      if (/^\d*$/.test(value)) {
        setSearchQuery(value); // Accept only numbers
      }
    }
  };

  return (
    <div className="difa-widget-containers">
      <div className="header-main">
        <div className="flex flex-row justify-between flex-wrap">
          <div>
            <h3>Documentation</h3>
            <h5>Include relevant supporting documentation to help our customers understand the ESP's operational history.</h5>
          </div>
          {!isPreview &&
            <div className="flex flex-row items-center gap-3">
              <DifaSwitch checked={expanded} onChange={onExpand} />
              <h4 className="whitespace-nowrap">Include in report</h4>
            </div>
          }
        </div>
      </div>
      <div className="flex flex-col gap-2 py-5 px-6 border-t border-dark">
        <div className="flex justify-between items-center gap-4 flex-wrap">
          <div className="tab-section !p-0 !border-b-0">
            <div className="tabs">
              {tabs.map((tab, index) => (
                <div
                  key={index}
                  className={`tab ${index === activeTabIndex ? "active" : ""}`}
                  onClick={() => setActiveTabIndex(index)}
                >
                  {tab.label}
                </div>
              ))}
            </div>
          </div>
          {!isPreview && activeTabIndex !== 3 && <div className="w-full md:w-60">
            <div className="relative">
              <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                <SearchIcon width="20" height="20" />
              </div>
              <input
                type="search"
                id="default-search"
                className="flex items-center w-full p-2 ps-10 text-base font-medium rounded-lg border border-dark bg-dark-800"
                placeholder={setInputPlaceholder()}
                value={searchQuery}
                onChange={handleSearchInputChange}
                required
              />
            </div>
          </div>}
        </div>
        {tabs.map((tab, index) => {
          return (
            <>
              {
                index === activeTabIndex &&
                <div
                  key={index}
                  className={`documentaion-table-container ${index === activeTabIndex ? "block" : "hidden"}`}
                >
                  {React.createElement(tab?.component, { searchQuery, source })}
                </div>
              }
            </>

          );
        })}
      </div>
    </div>
  )
}

export default DPBDocumentation
