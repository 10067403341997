import { useLocation } from "react-router-dom";
import Header from "../difaPacketBuilder/components/Header";
import { useDispatch, useSelector } from "react-redux";
import "../difaPacketBuilder/difaPacketBuilder.scss";
import { useEffect, useState } from "react";
import { fetchDifaDetails } from "../DifaPages/DifaDetailsSlice";
import WellRunLifeInformation from "../difaPacketBuilder/components/WellRunLifeInformation";
import { fetchOptimizationTickets, fetchRundaysDetails } from "../preInspectionSummary/PreInspectionSummarySlice";
import EspSizing from "../difaPacketBuilder/components/espSizing/EspSizing";
import InstallAndWellOperation from "../difaPacketBuilder/components/installWellOperation/InstallAndWellOperation";
import Production from "../difaPacketBuilder/components/production/Production";
import { fetchGeneralReport } from "../difaPacketBuilder/difaPacketBuilderSlice";
const PAGE_WELL_RUN_LIFE = "Well and run life information";
const PAGE_ESP_SIZING = "ESP sizing";
const PAGE_INSTALL_AND_WELL_OPERATIONS = "Install and well operations";
const PAGE_PRODUCTION = "Production";
const PAGE_PULL_AND_INBOUND = "Pull and inbound";

const DifaPacketPreview = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { difaId, customerName, wellName } = location.state || {};
  const { difaDetails } = useSelector((state: any) => state.difaDetails);

  const [currentPage, setCurrentPage] = useState(PAGE_WELL_RUN_LIFE);

  useEffect(() => {
          dispatch(fetchDifaDetails({ difaId }));
      }, [dispatch, difaId]);
  
      useEffect(() => {
          if (difaDetails?.wellApiNumber !== undefined) {
              dispatch(fetchRundaysDetails({ wellApiNumber: difaDetails?.wellApiNumber }));
              dispatch(fetchGeneralReport({ wellApiNumber: difaDetails?.wellApiNumber, difaId: difaDetails?.difaId }));
              dispatch(fetchOptimizationTickets({ wellName: encodeURIComponent(difaDetails?.wellName) })); 
          }
      }, [dispatch, difaDetails]);
  

  return (
    <div className="packet-builder">
      <Header
        source="preview"
        pageName="DIFA packet builder"
        difaPacketName="DIFA packet #12345"
        customerName={customerName}
        wellName={wellName}
      />
      <div className="container preview-header">
        <div className="text-container">
          <span className={`text-part cursor-pointer ${currentPage ===PAGE_WELL_RUN_LIFE && 'selected'}`} onClick={() => setCurrentPage(PAGE_WELL_RUN_LIFE)}>Run life Summary</span>
          <span className={`text-part cursor-pointer ${currentPage ===PAGE_PULL_AND_INBOUND && 'selected'}`} onClick={() => setCurrentPage(PAGE_PULL_AND_INBOUND)}>Inbound and pull</span>
          <span className={`text-part cursor-pointer ${currentPage ===PAGE_PRODUCTION && 'selected'}`} onClick={() => setCurrentPage(PAGE_PRODUCTION)}>Production</span>
          <span className={`text-part cursor-pointer ${currentPage ===PAGE_INSTALL_AND_WELL_OPERATIONS && 'selected'}`} onClick={() => setCurrentPage(PAGE_INSTALL_AND_WELL_OPERATIONS)}>Well operations and install</span>
          <span className={`text-part cursor-pointer ${currentPage ===PAGE_ESP_SIZING && 'selected'}`} onClick={() => setCurrentPage(PAGE_ESP_SIZING)}>ESP sizing</span>
        </div>
      </div>
      <div className="flex flex-col md:flex-row gap-5 p-7 h-fit">
        <div className="flex-auto flex flex-col w-full md:w-3/4 gap-5">
          {currentPage === PAGE_WELL_RUN_LIFE && (
            <WellRunLifeInformation difaId={difaId} />
          )}
          {currentPage === PAGE_ESP_SIZING && (
            <EspSizing
              wellApiNumber={difaDetails?.wellApiNumber}
              source="preview-packet"
            />
          )}
          {currentPage === PAGE_INSTALL_AND_WELL_OPERATIONS && (
            <InstallAndWellOperation
              wellApiNumber={difaDetails?.wellApiNumber}
              difaId={difaDetails?.difaId}
              source="preview-packet"
            />
          )}
          {currentPage === PAGE_PRODUCTION && <Production source="preview-packet"/>}
        </div>
      </div>
    </div>
  );
};

export default DifaPacketPreview;
