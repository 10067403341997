import React, { useEffect, useState } from "react";

import "./InstallReport.scss";
import "./SectionsReport.scss";
import closeSlider from "../../../images/close-slider.svg";
import downloadReport from "../../../images/download-report.svg";
import sectionExpand from "../../../images/chevron-down.svg";
import sectionCollapse from "../../../images/chevron-up.svg";
import checkMark from "../../../images/check.svg";
import { useSelector } from "react-redux";
import Helpers from "../../../utilities/Helpers";
import { sentenceCase } from "../../common/services/UtilityService";

interface Toggle {
  open: boolean;
  onCloseModal: any;
}

const AttributeCard: React.FC = () => {
  const { pullReport } = useSelector(
    (state: any) => state.preInspectionSummary
  );
  return (
    <div className="attribute-card">
      <h2 className="default-attribute-header">Attributes</h2>
      <div className="attribute-row">
        <span className="label">Reason for pull</span>
        <span className="value">
          {Helpers.markNullValues(pullReport.reasonForPull)}
        </span>
      </div>
      <div className="attribute-row">
        <span className="label">Insert install date</span>
        <span className="value">
          {Helpers.dateFormatter(pullReport.insertInstallDate)}
        </span>
      </div>
      <div className="attribute-row">
        <span className="label">Pull date</span>
        <span className="value">
          {Helpers.dateFormatter(pullReport.pullDate)}
        </span>
      </div>
      <div className="attribute-row">
        <span className="label">Calculated days installed</span>
        <span className="value">
          {Helpers.isFieldEmpty(pullReport.calculatedDaysInstalled)
            ? "-"
            : `${pullReport.calculatedDaysInstalled} days`}
        </span>
      </div>
      <div className="attribute-row">
        <span className="label">Field service tech</span>
        <span className="value">
          {sentenceCase(pullReport.fieldServiceTech)}
        </span>
      </div>
      <div className="attribute-row">
        <span className="label">Customer</span>
        <span className="value">{sentenceCase(pullReport.customer)}</span>
      </div>
      <div className="attribute-row">
        <span className="label">Well name</span>
        <span className="value">
          {Helpers.markNullValues(pullReport.wellName)}
        </span>
      </div>
      <div className="attribute-row">
        <span className="label">Well API #</span>
        <span className="value">
          {Helpers.markNullValues(pullReport.wellApiNumber)}
        </span>
      </div>
      <div className="attribute-row">
        <span className="label">Field</span>
        <span className="value">{sentenceCase(pullReport.field)}</span>
      </div>
      <div className="attribute-row">
        <span className="label">County</span>
        <span className="value">{sentenceCase(pullReport.county)}</span>
      </div>
      <div className="attribute-row">
        <span className="label">State</span>
        <span className="value">{sentenceCase(pullReport.state)}</span>
      </div>
    </div>
  );
};

const ESPDetails: React.FC = () => {
  const { pullReport } = useSelector(
    (state: any) => state.preInspectionSummary
  );
  const details: Record<string, any> = {
    "Bolt on head": pullReport?.boltOnHead,
    "Discharge pressure sub": pullReport?.dischargePressureSub,
    "Subs / swedge & collar": Helpers.markNullValues(
      pullReport?.subsOrSwedgeCollar
    ),
    "Tubing size": pullReport?.tubingSize,
    "Number of joints": Helpers.markNullValues(pullReport?.numberOfJoints),
    "DH equipment claps installed": pullReport?.dhEquipmentClampsInstalled,
    "Drain valve size": Helpers.markNullValues(pullReport?.drainValveSize),
    "Check valve size": Helpers.markNullValues(pullReport?.checkValveSize),
    "Sand guard": Helpers.markNullValues(pullReport?.sandGuard),
    "Cable clamp type": pullReport?.cableClampType,
    "Cable clamp size": Helpers.markNullValues(pullReport?.cableClampSize),
  };

  return (
    <div className="attribute-card">
      <h2 className="default-attribute-header">ESP details</h2>
      <div>
        {Object.entries(details).map(([label, value], index) => (
          <div key={index} style={styles.row}>
            <span>{label}</span>
            <span>{value}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

const Section: React.FC<{ title: string }> = ({ title }) => {
  const { pullReport } = useSelector(
    (state: any) => state.preInspectionSummary
  );
  const [isExpanded, setIsExpanded] = useState(false);

  const tblCommons = [
    {
      headers: ["Serial #", "Port number", "Item Description"],
    },
  ];

  const tblEvents1 = {
    "Customer rep": Helpers.isFieldEmpty(pullReport.customerRep)
      ? Helpers.markNullValues(pullReport.customerRep)
      : sentenceCase(pullReport?.customerRep),
    Consultant: Helpers.isFieldEmpty(pullReport.consultant)
      ? Helpers.markNullValues(pullReport.consultant)
      : sentenceCase(pullReport.consultant),
    "Additional field service technician": Helpers.isFieldEmpty(
      pullReport.additionalFieldServiceTechnician
    )
      ? Helpers.markNullValues(pullReport.additionalFieldServiceTechnician)
      : sentenceCase(pullReport.additionalFieldServiceTechnician),
    "Cable service tech": Helpers.isFieldEmpty(pullReport.cableServiceTech)
      ? Helpers.markNullValues(pullReport.cableServiceTech)
      : sentenceCase(pullReport.cableServiceTech),
    "CT service tech": Helpers.isFieldEmpty(pullReport.ctServiceTech)
      ? Helpers.markNullValues(pullReport.ctServiceTech)
      : sentenceCase(pullReport.ctServiceTech),
    "Cable service company": Helpers.isFieldEmpty(
      pullReport.cableServiceCompany
    )
      ? Helpers.markNullValues(pullReport.cableServiceCompany)
      : sentenceCase(pullReport.cableServiceCompany),
    "Well service company": Helpers.isFieldEmpty(pullReport.wellServiceCompany)
      ? Helpers.markNullValues(pullReport.wellServiceCompany)
      : sentenceCase(pullReport.wellServiceCompany),
  };
  const tblEvents2 = [
    {
      headers: ["Time", "Sequence of events", "Exceptions"],
    },
  ];

  const tblWellhead = [
    {
      headers: ["Size", "Wellhead type"],
      data: [
        Helpers.markNullValues(pullReport?.wellHeadSize),
        Helpers.markNullValues(pullReport?.wellheadType),
      ],
    },
    {
      headers: [
        "Serial #",
        "TYPE",
        "CONDITION",
        "BODY",
        "FLING",
        "BOLTS",
        "SLPS",
        "HNG",
        "ORNGS",
        "PTLS",
      ],
      data: [
        Helpers.markNullValues(pullReport?.wellheadSerialNumber),
        Helpers.markNullValues(pullReport?.penetratorType),
        Helpers.markNullValues(pullReport?.penetratorCondition),
        Helpers.markNullValues(pullReport?.wellheadConditionBody),
        Helpers.markNullValues(pullReport?.wellheadConditionFlange),
        Helpers.markNullValues(pullReport?.wellheadConditionBolts),
        Helpers.markNullValues(pullReport?.wellheadConditionSlips),
        Helpers.markNullValues(pullReport?.wellheadConditionHanger),
        Helpers.markNullValues(pullReport?.wellheadConditionOrings),
        Helpers.markNullValues(pullReport?.wellheadConditionPlates),
      ],
    },
  ];

  const tblForeignMaterials = {
    "Foreign material / samples send in with equipment (yes/no)":
      Helpers.formatYesNo(
        pullReport.foreignMaterialorSamplesSentInWithEquipmentYorN
      ),
    "If yes, who has possession of material/samples": Helpers.markNullValues(
      pullReport.ifYesWhoHasPossessionOfMaterialorSamples
    ),
    "Pull kit used": Helpers.isFieldEmpty(pullReport.pullKitUsed)
      ? Helpers.markNullValues(pullReport.pullKitUsed)
      : Helpers.formatYesNo(pullReport.pullKitUsed),
  };
  const tblForeignMaterialsExtra = [
    {
      headersMain: ["Types of foreign material sent in for analysis"],
      headers: [
        "SCALE",
        "SAND",
        "RUBBER",
        "METAL",
        "ASPHALTENES",
        "FE SULFIDE",
        "H2O",
        "UNKNOWN",
      ],
      data: [
        Helpers.markNullValues(
          pullReport?.typesOfForeignMaterialSentInForAnalysisScale
        ),
        Helpers.markNullValues(
          pullReport?.typesOfForeignMaterialSentInForAnalysisSand
        ),
        Helpers.markNullValues(
          pullReport?.typesOfForeignMaterialSentInForAnalysisRubber
        ),
        Helpers.markNullValues(
          pullReport?.typesOfForeignMaterialSentInForAnalysisMetal
        ),
        Helpers.markNullValues(
          pullReport?.typesOfForeignMaterialSentInForAnalysisAsphaltenes
        ),
        Helpers.markNullValues(
          pullReport?.typesOfForeignMaterialSentInForAnalysisFeSulfide
        ),
        Helpers.markNullValues(
          pullReport?.typesOfForeignMaterialSentInForAnalysisH20
        ),
        Helpers.markNullValues(
          pullReport?.typesOfForeignMaterialSentInForAnalysisUnknown
        ),
      ],
    },
    {
      headersMain: ["Types of system samples"],
      headers: ["CABLE", "SPICE", "MLE/POTHEAD", "BAND/CLAMP"],
      data: [
        Helpers.markNullValues(
          pullReport?.typesOfSystemSamplesSentInForAnalysisCable
        ),
        Helpers.markNullValues(
          pullReport?.typesOfSystemSamplesSentInForAnalysisSplice
        ),
        Helpers.markNullValues(
          pullReport?.typesOfSystemSamplesSentInForAnalysisMleOrPothead
        ),
        Helpers.markNullValues(
          pullReport?.typesOfSystemSamplesSentInForAnalysisBandorClmp
        ),
      ],
    },
  ];

  const tblCableBand = [
    {
      headersMain: ["Cable band condition"],
      headers: ["GOOD", "LOOSE", "SNAGGED", "MISSING", "CORRODED", "CRACKED"],
      data: [
        Helpers.markNullValues(pullReport?.cableBandConditionGood),
        Helpers.markNullValues(pullReport?.cableBandConditionLoose),
        Helpers.markNullValues(pullReport?.cableBandConditionSnagged),
        Helpers.markNullValues(pullReport?.cableBandConditionMissing),
        Helpers.markNullValues(pullReport?.cableBandConditionCorroded),
        Helpers.markNullValues(pullReport?.cableBandConditionCracked),
      ],
    },
    {
      headersMain: ["Band material", pullReport?.bandMaterial],
      headers: [
        "# of cable locations identified for DIFA",
        pullReport?.numberOfCableLocationsIdentifiedForDifa,
      ],
    },
    {
      headersMain: ["Cable clamp condition (CANNON, LASALLE, ETC.)"],
      headers: ["GOOD", "LOOSE", "SNAGGED", "MISSING", "CORRODED", "CRACKED"],
      data: [
        Helpers.markNullValues(
          pullReport?.cableClampConditionCannonLasalleEtcGood
        ),
        Helpers.markNullValues(
          pullReport?.cableClampConditionCannonLasalleEtcLoose
        ),
        Helpers.markNullValues(
          pullReport?.cableClampConditionCannonLasalleEtcSnagged
        ),
        Helpers.markNullValues(
          pullReport?.cableClampConditionCannonLasalleEtcMissing
        ),
        Helpers.markNullValues(
          pullReport?.cableClampConditionCannonLasalleEtcCorroded
        ),
        Helpers.markNullValues(
          pullReport?.cableClampConditionCannonLasalleEtcCracked
        ),
      ],
    },
    {
      headersMain: ["ESP body"],
      headers: [
        "CORROSION",
        "SCALE",
        "SPLIT",
        "DRAG MARKS",
        "PARAFFIN",
        "SAND",
      ],
      data: [
        Helpers.markNullValues(pullReport?.espBodyCorrosion),
        Helpers.markNullValues(pullReport?.espBodyScale),
        Helpers.markNullValues(pullReport?.espBodySplit),
        Helpers.markNullValues(pullReport?.espBodyDragMarks),
        Helpers.markNullValues(pullReport?.espBodyParaffin),
        Helpers.markNullValues(pullReport?.espBodySand),
      ],
    },
    {
      headersMain: ["Tubing"],
      headers: ["CORROSION", "SCALE", "SPLIT", "PARAFFIN", "SAND"],
      data: [
        Helpers.markNullValues(pullReport?.tubingCorrosion),
        Helpers.markNullValues(pullReport?.tubingScale),
        Helpers.markNullValues(pullReport?.tubingSplit),
        Helpers.markNullValues(pullReport?.tubingParaffin),
        Helpers.markNullValues(pullReport?.tubingSand),
      ],
    },
    {
      headersMain: ["Check / drain condition"],
      headers: ["GOOD", "PLUGGED", "WASHED OUT"],
      data: [
        Helpers.markNullValues(pullReport?.checkOrDrainConditionGood),
        Helpers.markNullValues(pullReport?.checkOrDrainConditionPlugged),
        Helpers.markNullValues(pullReport?.checkOrDrainConditionWashedOut),
      ],
    },
  ];

  const tblSurface = [
    {
      headers: [
        "VSD serial #",
        "IME #",
        "Part #",
        "Item description",
        "Returned",
      ],
      data: [
        Helpers.markNullValues(pullReport?.vsdOrSwitchboardSerialNumber),
        Helpers.markNullValues(pullReport?.ime),
        Helpers.markNullValues(pullReport?.vsdOrSwitchboardPartNumber),
        Helpers.markNullValues(pullReport?.vsdOrSwitchboardItemDescription),
        Helpers.formatYesNo(pullReport?.vsdOrSwitchboardReturned),
      ],
    },
    {
      headers: [
        "Transformer serial # ",
        "Part #",
        "Item description",
        "Returned",
      ],
      data: [
        Helpers.markNullValues(pullReport?.transformerSerialNumber),
        Helpers.markNullValues(pullReport?.transformerPartNumber),
        Helpers.markNullValues(pullReport?.transformerSerialItemDescription),
        Helpers.markNullValues(pullReport?.transformerSerialReturned),
      ],
    },
    {
      headers: [
        "Harmonic filter serial # ",
        "Part #",
        "Item description",
        "Returned",
      ],
      data: [
        Helpers.markNullValues(pullReport?.harmonicFilterSerialNumber),
        Helpers.markNullValues(pullReport?.harmonicFilterPartNumber),
        Helpers.markNullValues(pullReport?.harmonicFilterItemDescription),
        Helpers.markNullValues(pullReport?.harmonicFilterReturned),
      ],
    },
  ];

  const tblSurface1 = {
    "Last shut down": Helpers.markNullValues(pullReport?.lastShutdown),
    "Overload settings": Helpers.markNullValues(pullReport.overloadSetting),
    "Underload settings": Helpers.markNullValues(pullReport.underloadSetting),
    "Fuse size - V/A": Helpers.markNullValues(pullReport.fuseSizevorA),
    "Lighting protection -Y/N": Helpers.formatYesNo(
      pullReport.lightningProtectionYorN
    ),
    "If yes, what type installed": Helpers.markNullValues(
      pullReport.ifYesWhatTypeInstalled
    ),
  };

  const tblDesander = [
    {
      headers: ["Serial #", "Item description"],
      data: [
        Helpers.markNullValues(pullReport?.desanderPartNo),
        Helpers.markNullValues(pullReport?.desanderItemDescription),
      ],
    },
  ];

  const tblDesander1 = {
    "Amount of sand": Helpers.markNullValues(pullReport?.desanderAmountOfSand),
    "Cup conditions": Helpers.markNullValues(pullReport.desanderCupConditions),
    Size: Helpers.markNullValues(pullReport.desanderSize),
    "Sand present": Helpers.markNullValues(pullReport.desanderSandPresent),
    Quantity: Helpers.markNullValues(pullReport.desanderQuantity),
  };

  /* Pumps collection started */
  const pumps = pullReport?.pullEquipmentInfoDto?.filter((item: any) => {
    return item.equipmentType === "Pump";
  });
  const pumpsEquipments: Array<any> = [];
  pumps?.forEach((item: any) => {
    let equipmentsData: any = {};
    item?.pullEquipmentDataDto?.forEach((pull: any) => {
      return (equipmentsData[pull?.key] = pull?.value);
    });

    return pumpsEquipments.push({
      ...equipmentsData,
      serial: item.serialNumberOrOnReel,
    });
  });

  /* Pumps collection ended */

  /* Gas Handling collection started*/
  const gasHandling = pullReport.pullEquipmentInfoDto.filter((item: any) => {
    return item.equipmentType === "GAS_HANDLINGOrGAS_SEP";
  });
  const gasHandlingEquipments: Array<any> = [];
  gasHandling?.forEach((item: any) => {
    let equipmentsData: any = {};
    item?.pullEquipmentDataDto?.forEach((child: any) => {
      return (equipmentsData[child?.key] = child?.value);
    });

    return gasHandlingEquipments.push({
      ...equipmentsData,
      serial: item.serialNumberOrOnReel,
    });
  });
  /* Gas Handling collection ended */

  /* Cable collection started*/
  const cables = pullReport?.pullEquipmentInfoDto?.filter((item: any) => {
    return item.equipmentType === "Cable";
  });
  const cablesEquipments: Array<any> = [];
  cables?.forEach((item: any) => {
    let equipmentsData: any = {};
    item?.pullEquipmentDataDto?.forEach((child: any) => {
      return (equipmentsData[child?.key] = child?.value);
    });

    return cablesEquipments.push({
      ...equipmentsData,
      serial: item.serialNumberOrOnReel,
    });
  });
  /* Cables collection ended */

  /* Intake collection started*/
  const intakes = pullReport.pullEquipmentInfoDto.filter((item: any) => {
    return item.equipmentType === "Intake";
  });
  const intakesEquipments: Array<any> = [];
  intakes?.forEach((item: any) => {
    let equipmentsData: any = {};
    item?.pullEquipmentDataDto?.forEach((child: any) => {
      return (equipmentsData[child?.key] = child?.value);
    });

    return intakesEquipments.push({
      ...equipmentsData,
      serial: item.serialNumberOrOnReel,
    });
  });
  /* Intake collection ended */

  /* Motor collection started*/
  const motors = pullReport.pullEquipmentInfoDto.filter((item: any) => {
    return item.equipmentType === "Motor";
  });
  const motorsEquipments: Array<any> = [];
  motors?.forEach((item: any) => {
    let equipmentsData: any = {};
    item?.pullEquipmentDataDto?.forEach((child: any) => {
      return (equipmentsData[child?.key] = child?.value);
    });

    return motorsEquipments.push({
      ...equipmentsData,
      serial: item.serialNumberOrOnReel,
    });
  });
  /* Motor collection ended */

  /* Sensors collection started*/
  const sensors = pullReport.pullEquipmentInfoDto.filter((item: any) => {
    return item.equipmentType === "Sensor";
  });
  const sensorsEquipments: Array<any> = [];
  sensors?.forEach((item: any) => {
    let equipmentsData: any = {};
    item?.pullEquipmentDataDto?.forEach((child: any) => {
      return (equipmentsData[child?.key] = child?.value);
    });

    return sensorsEquipments.push({
      ...equipmentsData,
      serial: item.serialNumberOrOnReel,
    });
  });
  /* Sensors collection ended */

  /* Protector collection started*/
  const protectors = pullReport.pullEquipmentInfoDto.filter((item: any) => {
    return item.equipmentType === "ProtectorOrSeal";
  });
  const protectorsEquipments: Array<any> = [];
  protectors?.forEach((item: any) => {
    let equipmentsData: any = {};
    item?.pullEquipmentDataDto?.forEach((child: any) => {
      return (equipmentsData[child?.key] = child?.value);
    });

    return protectorsEquipments.push({
      ...equipmentsData,
      serial: item.serialNumberOrOnReel,
    });
  });
  /* Protector collection ended */

  /* Phase/grounding collection ended */
  const electricalMetadata = ["UT_MOTOR", "CT_MOTOR", "LT_MOTOR"];
  let systemElectricDataFirst: any = [];
  let systemElectricDataRemaining: any = [];
  pullReport?.pullEquipmentValuesDto &&
    Object.keys(pullReport?.pullEquipmentValuesDto)?.forEach((item: any) => {
      if (
        electricalMetadata.includes(
          pullReport?.pullEquipmentValuesDto[item].equipmentType
        )
      )
        return systemElectricDataFirst.push(
          pullReport?.pullEquipmentValuesDto[item]
        );
      else
        return systemElectricDataRemaining.push(
          pullReport?.pullEquipmentValuesDto[item]
        );
    });
  const orderForBatch1 = [
    "MOTOROrCABLE_LANDED",
    "MOTOROrCABLEAtSURFACE",
    "ALL_MOTOR_COMBINED",
    "CABLE_ONLY",
  ];
  const orderForBatch2 = ["UT_MOTOR", "CT_MOTOR", "CT_MOTOR", "LT_MOTOR"];
  systemElectricDataFirst = systemElectricDataFirst.sort((a: any, b: any) => {
    return (
      orderForBatch2.indexOf(a.equipmentType) -
      orderForBatch2.indexOf(b.equipmentType)
    );
  });
  systemElectricDataRemaining = systemElectricDataRemaining.sort(
    (a: any, b: any) => {
      return (
        orderForBatch1.indexOf(a.equipmentType) -
        orderForBatch1.indexOf(b.equipmentType)
      );
    }
  );
  /* Phase/grounding collection ended */

  return (
    <div className="section">
      <div
        className="section-header"
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <span>{title}</span>
        <img src={isExpanded ? sectionCollapse : sectionExpand} alt="chevron" />
      </div>

      {isExpanded && title === "Wellhead" ? (
        <div className="section-open-section-content" key="wellhead">
          {tblWellhead.map((set, index) => (
            <div key={index} className="subsection-styling mb10 bb">
              <div className="flex-display commons wellhead">
                {set.headers.map((header, i) => (
                  <div key={i} className="headerStyle">
                    {header}
                  </div>
                ))}
              </div>
              <div className="flex-display commons wellhead">
                {set.data.map((datum, i) => (
                  <div key={i} className="cellStyle">
                    {datum}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      ) : isExpanded && title === "Surface equipment" ? (
        <div className="section-open-section-content" key="surface">
          {tblSurface.map((set, index) => (
            <div key={index} className="subsection-styling mb10 bb">
              <div className="flex-display commons commons">
                {set.headers.map((header, i) => (
                  <div key={i} className="headerStyle">
                    {header}
                  </div>
                ))}
              </div>
              <div className="flex-display commons commons">
                {set.data.map((datum, i) => (
                  <div key={i} className="cellStyle">
                    {datum}
                  </div>
                ))}
              </div>
            </div>
          ))}
          <div className="subsection-styling mb10">
            {Object.entries(tblSurface1).map(([label, value], index) => (
              <div key={index} style={styles.row}>
                <span>{label}</span>
                <span>{value}</span>
              </div>
            ))}
          </div>
        </div>
      ) : isExpanded && title === "Desander" ? (
        <div className="section-open-section-content" key="surface">
          {tblDesander.map((set, index) => (
            <div key={index} className="subsection-styling mb10 bb">
              <div className="flex-display commons commons">
                {set.headers.map((header, i) => (
                  <div key={i} className="headerStyle">
                    {header}
                  </div>
                ))}
              </div>
              <div className="flex-display commons commons">
                {set.data.map((datum, i) => (
                  <div key={i} className="cellStyle">
                    {datum}
                  </div>
                ))}
              </div>
            </div>
          ))}
          <div className="subsection-styling mb10">
            {Object.entries(tblDesander1).map(([label, value], index) => (
              <div key={index} style={styles.row}>
                <span>{label}</span>
                <span>{value}</span>
              </div>
            ))}
          </div>
        </div>
      ) : isExpanded && title === "Sequence of events" ? (
        <div className="section-open-section-content" key="seqEvents">
          <div className="subsection-styling mb10">
            {Object.entries(tblEvents1).map(([label, value], index) => (
              <div key={index} className="attribute-row">
                <span className="label">{label}</span>
                <span className="value">{value}</span>
              </div>
            ))}
          </div>
          <div className="subsection-styling bb">
            {tblEvents2.map((set, index) => (
              <div key={index}>
                <div className="flex-display commons commons">
                  {set.headers.map((header, i) => (
                    <div key={i} className="headerStyle">
                      {header}
                    </div>
                  ))}
                </div>
              </div>
            ))}
            {pullReport?.pullEventsExceptionsDto?.map((datum: any, i: any) => (
              <div className="sequence-rows commons">
                <div className="sequence-cells">
                  {Helpers.isFieldEmpty(datum.eventTime) ||
                  Helpers.isFieldEmpty(datum.eventAMPM)
                    ? "-"
                    : `${datum?.eventTime} ${datum?.eventAMPM}`}
                </div>
                <div className="sequence-cells">
                  {Helpers.markNullValues(datum?.eventDescription)}
                </div>
                <div className="sequence-cells">
                  {Helpers.markNullValues(datum?.exceptionDescription)}
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : isExpanded && title === "Phase to phase and phase to ground" ? (
        <div className="section-open-section-content" key="divSytemIntegrity">
          <div className="subsection-styling mb10">
            <table
              className="electrical-readings"
              cellSpacing="0"
              cellPadding="0"
            >
              <thead>
                <tr>
                  <th rowSpan={2} className="sub-section-header"></th>
                  <th colSpan={3}>Phase to phase</th>
                  <th colSpan={3}>Phase to ground</th>
                </tr>
                <tr className="miniheader">
                  <th>A-B</th>
                  <th>B-C</th>
                  <th className="section-divider">C-A</th>
                  <th>A-GND</th>
                  <th>B-GND</th>
                  <th>C-GND</th>
                </tr>
              </thead>
              <tbody>
                {systemElectricDataRemaining?.map((item: any, i: string) => {
                  return (
                    <tr key={`system${i}`}>
                      <td>{Helpers.MotorsAndPhases[item.equipmentType]}</td>
                      <td>
                        {Helpers.markNullValues(item.abValue)}{" "}
                        {Helpers.isFieldEmpty(item.abValue) ? "" : "Ω"}
                      </td>
                      <td>
                        {Helpers.markNullValues(item.bcValue)}{" "}
                        {Helpers.isFieldEmpty(item.bcValue) ? "" : "Ω"}
                      </td>
                      <td>
                        {Helpers.markNullValues(item.caValue)}{" "}
                        {Helpers.isFieldEmpty(item.caValue) ? "" : "Ω"}
                      </td>
                      <td>
                        {Helpers.markNullValues(item.agndValue)}{" "}
                        {Helpers.isFieldEmpty(item.agndValue) ? "" : "Ω"}
                      </td>
                      <td>
                        {Helpers.markNullValues(item.bgndValue)}{" "}
                        {Helpers.isFieldEmpty(item.bgndValue) ? "" : "Ω"}
                      </td>
                      <td>
                        {Helpers.markNullValues(item.cgndValue)}{" "}
                        {Helpers.isFieldEmpty(item.cgndValue) ? "" : "Ω"}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="subsection-styling ">
            <table
              className="electrical-readings"
              cellSpacing="0"
              cellPadding="0"
            >
              <thead>
                <tr>
                  <th rowSpan={2} className="sub-section-header"></th>
                  <th colSpan={3}>Phase to phase</th>
                  <th colSpan={3}>Phase to ground</th>
                </tr>
                <tr className="miniheader">
                  <th>A-B</th>
                  <th>B-C</th>
                  <th className="section-divider">C-A</th>
                  <th>A-GND</th>
                  <th>B-GND</th>
                  <th>C-GND</th>
                </tr>
              </thead>
              <tbody>
                {systemElectricDataFirst?.map((item: any, i: string) => {
                  return (
                    <tr key={`system${i}`}>
                      <td>{Helpers.MotorsAndPhases[item.equipmentType]}</td>
                      <td>
                        {Helpers.markNullValues(item.abValue)}{" "}
                        {Helpers.isFieldEmpty(item.abValue) ? "" : "Ω"}
                      </td>
                      <td>
                        {Helpers.markNullValues(item.bcValue)}{" "}
                        {Helpers.isFieldEmpty(item.bcValue) ? "" : "Ω"}
                      </td>
                      <td>
                        {Helpers.markNullValues(item.caValue)}{" "}
                        {Helpers.isFieldEmpty(item.caValue) ? "" : "Ω"}
                      </td>
                      <td>
                        {Helpers.markNullValues(item.agndValue)}{" "}
                        {Helpers.isFieldEmpty(item.agndValue) ? "" : "Ω"}
                      </td>
                      <td>
                        {Helpers.markNullValues(item.bgndValue)}{" "}
                        {Helpers.isFieldEmpty(item.bgndValue) ? "" : "Ω"}
                      </td>
                      <td>
                        {Helpers.markNullValues(item.cndValue)}{" "}
                        {Helpers.isFieldEmpty(item.cgndValue) ? "" : "Ω"}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      ) : isExpanded &&
        title === "Cable band, clamp, ESP body, tubing, and check/drain" ? (
        <div className="section-open-section-content" key="divCableBand">
          {tblCableBand.map((set, index) => (
            <div key={index} className="subsection-styling mb10 bb">
              <div className={`flex-display cable-${index}`}>
                {set.headersMain?.map((header, i) => (
                  <div key={i} className="headerStyle">
                    {header}
                  </div>
                ))}
              </div>
              <div className={`flex-display cable-${index}`}>
                {set?.headers?.map((header, i) => (
                  <div key={i} className="headerStyle">
                    {header}
                  </div>
                ))}
              </div>
              {set.data && (
                <div className="flex-display ">
                  {set?.data?.map((datum, i) => (
                    <div key={i} className="cellStyle">
                      {datum}
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      ) : isExpanded && title === "Cable" ? (
        <div className="section-open-section-content" key="divCable">
          <div className="subsection-styling mb10 bb">
            {tblCommons.map((set, index) => (
              <div key={index}>
                <div className="flex-display commons pumps">
                  {set.headers.map((header, i) => (
                    <div key={i} className="headerStyle">
                      {header}
                    </div>
                  ))}
                </div>
              </div>
            ))}
            {cables?.map((datum: any, i: any) => (
              <div className="sequence-rows pumps">
                <div className="sequence-cells">
                  {Helpers.markNullValues(datum?.serialNumberOrOnReel)}
                </div>
                <div className="sequence-cells">
                  {Helpers.markNullValues(datum?.partNumber)}
                </div>
                <div className="sequence-cells">
                  {Helpers.markNullValues(datum?.itemDescription)}
                </div>
              </div>
            ))}
          </div>
          <div className="subsection-styling mb10">
            <table
              className="electrical-readings pullReport-cables"
              cellSpacing="0"
              cellPadding="0"
            >
              <thead>
                <tr>
                  <th className="sub-section-header"></th>
                  <th colSpan={6}>Cable condition</th>
                </tr>
                <tr className="miniheader">
                  <th>Serial #</th>
                  <th>DAMAGED</th>
                  <th>GAS BLOWN</th>
                  <th>STRETCHED</th>
                  <th>CORROSION</th>
                  <th>SPLICE FAIL</th>
                  <th>FORGN MATL</th>
                </tr>
              </thead>
              <tbody>
                {cablesEquipments?.map((item: any, idx: number) => {
                  return (
                    <tr key={`pullReportsPumps${idx}`}>
                      <td>{Helpers.markNullValues(item.serial)}</td>
                      <td>
                        {Helpers.handlPullEquipmentStatus(
                          item.CABLE_CONDITION_DAMAGED
                        ) ? (
                          <img src={checkMark} alt="chevron" />
                        ) : (
                          "-"
                        )}
                      </td>
                      <td>
                        {Helpers.handlPullEquipmentStatus(
                          item.CABLE_CONDITION_GAS
                        ) ? (
                          <img src={checkMark} alt="chevron" />
                        ) : (
                          "-"
                        )}
                      </td>
                      <td>
                        {Helpers.handlPullEquipmentStatus(
                          item.CABLE_CONDITION_STRETCHED
                        ) ? (
                          <img src={checkMark} alt="chevron" />
                        ) : (
                          "-"
                        )}
                      </td>
                      <td>
                        {Helpers.handlPullEquipmentStatus(
                          item.CABLE_CONDITION_CORROSION
                        ) ? (
                          <img src={checkMark} alt="chevron" />
                        ) : (
                          "-"
                        )}
                      </td>
                      <td>
                        {Helpers.handlPullEquipmentStatus(
                          item.CABLE_CONDITION_SPLICE
                        ) ? (
                          <img src={checkMark} alt="chevron" />
                        ) : (
                          "-"
                        )}
                      </td>
                      <td>
                        {Helpers.handlPullEquipmentStatus(
                          item.CABLE_CONDITION_FORGN
                        ) ? (
                          <img src={checkMark} alt="chevron" />
                        ) : (
                          "-"
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      ) : isExpanded && title === "Foreign materials" ? (
        <div className="section-open-section-content" key="divForeignMaterials">
          <div className="subsection-styling mb10">
            {Object.entries(tblForeignMaterials).map(
              ([label, value], index) => (
                <div key={index} className="attribute-row">
                  <span className="label">{label}</span>
                  <span className="value">{value}</span>
                </div>
              )
            )}
          </div>
          {tblForeignMaterialsExtra.map((set, index) => (
            <div key={index} className="subsection-styling mb10 bb">
              <div className="flex-display ">
                {set.headersMain?.map((header, i) => (
                  <div key={i} className="headerStyle">
                    {header}
                  </div>
                ))}
              </div>
              <div className="flex-display ">
                {set.headers.map((header, i) => (
                  <div key={i} className="headerStyle">
                    {header}
                  </div>
                ))}
              </div>
              <div className="flex-display ">
                {set.data.map((datum, i) => (
                  <div key={i} className="cellStyle">
                    {datum}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      ) : isExpanded && title === "Pump" ? (
        <div className="section-open-section-content" key="divPumps">
          <div className="subsection-styling mb10 bb">
            {tblCommons.map((set, index) => (
              <div key={index}>
                <div className="flex-display commons pumps">
                  {set.headers.map((header, i) => (
                    <div key={i} className="headerStyle">
                      {header}
                    </div>
                  ))}
                </div>
              </div>
            ))}
            {pumps?.map((datum: any, i: any) => (
              <div className="sequence-rows pumps">
                <div className="sequence-cells">
                  {datum?.serialNumberOrOnReel}
                </div>
                <div className="sequence-cells">{datum?.partNumber}</div>
                <div className="sequence-cells">{datum?.itemDescription}</div>
              </div>
            ))}
          </div>
          <div className="subsection-styling mb10">
            <table
              className="electrical-readings pullReport-pumps"
              cellSpacing="0"
              cellPadding="0"
            >
              <thead>
                <tr>
                  <th className="sub-section-header"></th>
                  <th colSpan={5}>Shaft rotation</th>
                  <th colSpan={4}>Foriegn material</th>
                </tr>
                <tr className="miniheader">
                  <th>Serial #</th>
                  <th>FREE</th>
                  <th>STIFF</th>
                  <th>LCKD</th>
                  <th>SDPLY</th>
                  <th className="section-divider">TWSTD</th>
                  <th>HEAD</th>
                  <th>BODY</th>
                  <th>SCRN</th>
                  <th>ALL</th>
                </tr>
              </thead>
              <tbody>
                {pumpsEquipments?.map((item: any, idx: number) => {
                  return (
                    <tr key={`pullReportsPumps${idx}`}>
                      <td>{Helpers.markNullValues(item.serial)}</td>
                      <td>
                        {Helpers.handlPullEquipmentStatus(
                          item.SHAFT_ROTATION_FREE
                        ) ? (
                          <img src={checkMark} alt="chevron" />
                        ) : (
                          "-"
                        )}
                      </td>
                      <td>
                        {Helpers.handlPullEquipmentStatus(
                          item.SHAFT_ROTATION_STIFF
                        ) ? (
                          <img src={checkMark} alt="chevron" />
                        ) : (
                          "-"
                        )}
                      </td>
                      <td>
                        {Helpers.handlPullEquipmentStatus(
                          item.SHAFT_ROTATION_LCKD
                        ) ? (
                          <img src={checkMark} alt="chevron" />
                        ) : (
                          "-"
                        )}
                      </td>
                      <td>
                        {Helpers.handlPullEquipmentStatus(
                          item.SHAFT_ROTATION_SDPLY
                        ) ? (
                          <img src={checkMark} alt="chevron" />
                        ) : (
                          "-"
                        )}
                      </td>
                      <td>
                        {Helpers.handlPullEquipmentStatus(
                          item.SHAFT_ROTATION_TWSTD
                        ) ? (
                          <img src={checkMark} alt="chevron" />
                        ) : (
                          "-"
                        )}
                      </td>
                      <td>
                        {Helpers.handlPullEquipmentStatus(
                          item.FOREIGN_MATERIAL_HEAD
                        ) ? (
                          <img src={checkMark} alt="chevron" />
                        ) : (
                          "-"
                        )}
                      </td>
                      <td>
                        {Helpers.handlPullEquipmentStatus(
                          item.FOREIGN_MATERIAL_BODY
                        ) ? (
                          <img src={checkMark} alt="chevron" />
                        ) : (
                          "-"
                        )}
                      </td>
                      <td>
                        {Helpers.handlPullEquipmentStatus(
                          item.FOREIGN_MATERIAL_SCRN
                        ) ? (
                          <img src={checkMark} alt="chevron" />
                        ) : (
                          "-"
                        )}
                      </td>
                      <td>
                        {Helpers.handlPullEquipmentStatus(
                          item.FOREIGN_MATERIAL_ALL
                        ) ? (
                          <img src={checkMark} alt="chevron" />
                        ) : (
                          "-"
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      ) : isExpanded && title === "Gas handling / gas separator" ? (
        <div className="section-open-section-content" key="divGasHandling">
          <div className="subsection-styling mb10 bb">
            {tblCommons.map((set, index) => (
              <div key={index}>
                <div className="flex-display commons pumps">
                  {set.headers.map((header, i) => (
                    <div key={i} className="headerStyle">
                      {header}
                    </div>
                  ))}
                </div>
              </div>
            ))}
            {gasHandling?.map((datum: any, i: any) => (
              <div className="sequence-rows pumps">
                <div className="sequence-cells">
                  {Helpers.markNullValues(datum?.serialNumberOrOnReel)}
                </div>
                <div className="sequence-cells">
                  {Helpers.markNullValues(datum?.partNumber)}
                </div>
                <div className="sequence-cells">
                  {Helpers.markNullValues(datum?.itemDescription)}
                </div>
              </div>
            ))}
          </div>
          <div className="subsection-styling mb10">
            <table
              className="electrical-readings pullReport-pumps"
              cellSpacing="0"
              cellPadding="0"
            >
              <thead>
                <tr>
                  <th className="sub-section-header"></th>
                  <th colSpan={5}>Shaft rotation</th>
                  <th colSpan={4}>Foriegn material</th>
                </tr>
                <tr className="miniheader">
                  <th>Serial #</th>
                  <th>FREE</th>
                  <th>STIFF</th>
                  <th>LCKD</th>
                  <th>SDPLY</th>
                  <th className="section-divider">TWSTD</th>
                  <th>HEAD</th>
                  <th>BODY</th>
                  <th>SCRN</th>
                  <th>ALL</th>
                </tr>
              </thead>
              <tbody>
                {gasHandlingEquipments?.map((item: any, idx: number) => {
                  return (
                    <tr key={`pullReportsPumps${idx}`}>
                      <td>{Helpers.markNullValues(item.serial)}</td>
                      <td>
                        {Helpers.handlPullEquipmentStatus(
                          item.SHAFT_ROTATION_FREE
                        ) ? (
                          <img src={checkMark} alt="chevron" />
                        ) : (
                          "-"
                        )}
                      </td>
                      <td>
                        {Helpers.handlPullEquipmentStatus(
                          item.SHAFT_ROTATION_STIFF
                        ) ? (
                          <img src={checkMark} alt="chevron" />
                        ) : (
                          "-"
                        )}
                      </td>
                      <td>
                        {Helpers.handlPullEquipmentStatus(
                          item.SHAFT_ROTATION_LCKD
                        ) ? (
                          <img src={checkMark} alt="chevron" />
                        ) : (
                          "-"
                        )}
                      </td>
                      <td>
                        {Helpers.handlPullEquipmentStatus(
                          item.SHAFT_ROTATION_SDPLY
                        ) ? (
                          <img src={checkMark} alt="chevron" />
                        ) : (
                          "-"
                        )}
                      </td>
                      <td>
                        {Helpers.handlPullEquipmentStatus(
                          item.SHAFT_ROTATION_TWSTD
                        ) ? (
                          <img src={checkMark} alt="chevron" />
                        ) : (
                          "-"
                        )}
                      </td>
                      <td>
                        {Helpers.handlPullEquipmentStatus(
                          item.FOREIGN_MATERIAL_HEAD
                        ) ? (
                          <img src={checkMark} alt="chevron" />
                        ) : (
                          "-"
                        )}
                      </td>
                      <td>
                        {Helpers.handlPullEquipmentStatus(
                          item.FOREIGN_MATERIAL_BODY
                        ) ? (
                          <img src={checkMark} alt="chevron" />
                        ) : (
                          "-"
                        )}
                      </td>
                      <td>
                        {Helpers.handlPullEquipmentStatus(
                          item.FOREIGN_MATERIAL_SCRN
                        ) ? (
                          <img src={checkMark} alt="chevron" />
                        ) : (
                          "-"
                        )}
                      </td>
                      <td>
                        {Helpers.handlPullEquipmentStatus(
                          item.FOREIGN_MATERIAL_ALL
                        ) ? (
                          <img src={checkMark} alt="chevron" />
                        ) : (
                          "-"
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      ) : isExpanded && title === "Intake" ? (
        <div className="section-open-section-content" key="divIntake">
          <div className="subsection-styling mb10 bb">
            {tblCommons.map((set, index) => (
              <div key={index}>
                <div className="flex-display commons pumps">
                  {set.headers.map((header, i) => (
                    <div key={i} className="headerStyle">
                      {header}
                    </div>
                  ))}
                </div>
              </div>
            ))}
            {intakes?.map((datum: any, i: any) => (
              <div className="sequence-rows pumps">
                <div className="sequence-cells">
                  {Helpers.markNullValues(datum?.serialNumberOrOnReel)}
                </div>
                <div className="sequence-cells">
                  {Helpers.markNullValues(datum?.partNumber)}
                </div>
                <div className="sequence-cells">
                  {Helpers.markNullValues(datum?.itemDescription)}
                </div>
              </div>
            ))}
          </div>
          <div className="subsection-styling mb10">
            <table
              className="electrical-readings pullReport-pumps"
              cellSpacing="0"
              cellPadding="0"
            >
              <thead>
                <tr>
                  <th className="sub-section-header"></th>
                  <th colSpan={5}>Shaft rotation</th>
                  <th colSpan={4}>Foriegn material</th>
                </tr>
                <tr className="miniheader">
                  <th>Serial #</th>
                  <th>FREE</th>
                  <th>STIFF</th>
                  <th>LCKD</th>
                  <th>SDPLY</th>
                  <th className="section-divider">TWSTD</th>
                  <th>HEAD</th>
                  <th>BODY</th>
                  <th>SCRN</th>
                  <th>ALL</th>
                </tr>
              </thead>
              <tbody>
                {intakesEquipments?.map((item: any, idx: number) => {
                  return (
                    <tr key={`pullReportsPumps${idx}`}>
                      <td>{Helpers.markNullValues(item.serial)}</td>
                      <td>
                        {Helpers.handlPullEquipmentStatus(
                          item.SHAFT_ROTATION_FREE
                        ) ? (
                          <img src={checkMark} alt="chevron" />
                        ) : (
                          "-"
                        )}
                      </td>
                      <td>
                        {Helpers.handlPullEquipmentStatus(
                          item.SHAFT_ROTATION_STIFF
                        ) ? (
                          <img src={checkMark} alt="chevron" />
                        ) : (
                          "-"
                        )}
                      </td>
                      <td>
                        {Helpers.handlPullEquipmentStatus(
                          item.SHAFT_ROTATION_LCKD
                        ) ? (
                          <img src={checkMark} alt="chevron" />
                        ) : (
                          "-"
                        )}
                      </td>
                      <td>
                        {Helpers.handlPullEquipmentStatus(
                          item.SHAFT_ROTATION_SDPLY
                        ) ? (
                          <img src={checkMark} alt="chevron" />
                        ) : (
                          "-"
                        )}
                      </td>
                      <td>
                        {Helpers.handlPullEquipmentStatus(
                          item.SHAFT_ROTATION_TWSTD
                        ) ? (
                          <img src={checkMark} alt="chevron" />
                        ) : (
                          "-"
                        )}
                      </td>
                      <td>
                        {Helpers.handlPullEquipmentStatus(
                          item.FOREIGN_MATERIAL_HEAD
                        ) ? (
                          <img src={checkMark} alt="chevron" />
                        ) : (
                          "-"
                        )}
                      </td>
                      <td>
                        {Helpers.handlPullEquipmentStatus(
                          item.FOREIGN_MATERIAL_BODY
                        ) ? (
                          <img src={checkMark} alt="chevron" />
                        ) : (
                          "-"
                        )}
                      </td>
                      <td>
                        {Helpers.handlPullEquipmentStatus(
                          item.FOREIGN_MATERIAL_SCRN
                        ) ? (
                          <img src={checkMark} alt="chevron" />
                        ) : (
                          "-"
                        )}
                      </td>
                      <td>
                        {Helpers.handlPullEquipmentStatus(
                          item.FOREIGN_MATERIAL_ALL
                        ) ? (
                          <img src={checkMark} alt="chevron" />
                        ) : (
                          "-"
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      ) : isExpanded && title === "Motor" ? (
        <div className="section-open-section-content" key="divGasHandling">
          <div className="subsection-styling mb10 bb">
            {tblCommons.map((set, index) => (
              <div key={index}>
                <div className="flex-display commons pumps">
                  {set.headers.map((header, i) => (
                    <div key={i} className="headerStyle">
                      {header}
                    </div>
                  ))}
                </div>
              </div>
            ))}
            {motors?.map((datum: any, i: any) => (
              <div className="sequence-rows pumps">
                <div className="sequence-cells">
                  {Helpers.markNullValues(datum?.serialNumberOrOnReel)}
                </div>
                <div className="sequence-cells">
                  {Helpers.markNullValues(datum?.partNumber)}
                </div>
                <div className="sequence-cells">
                  {Helpers.markNullValues(datum?.itemDescription)}
                </div>
              </div>
            ))}
          </div>

          <div className="subsection-styling mb10">
            <table
              className="electrical-readings pullReport-motor"
              cellSpacing="0"
              cellPadding="0"
            >
              <thead>
                <tr>
                  <th className="sub-section-header"></th>
                  <th colSpan={4}>Motor Shaft rotation</th>
                  <th colSpan={5}>Motor oil top</th>
                </tr>
                <tr className="miniheader">
                  <th>Serial #</th>
                  <th>FREE</th>
                  <th>STIFF LCKD</th>
                  <th>TWST BRKN</th>
                  <th className="section-divider">SDL</th>
                  <th>CLR</th>
                  <th>DSCLR</th>
                  <th>BLK</th>
                  <th>BRASS</th>
                  <th>H2O</th>
                </tr>
              </thead>
              <tbody>
                {motorsEquipments?.map((item: any, idx: number) => {
                  return (
                    <tr key={`pullReportsPumps${idx}`}>
                      <td>{Helpers.markNullValues(item.serial)}</td>
                      <td>
                        {Helpers.handlPullEquipmentStatus(
                          item.MTR_SHAFT_ROTATION_FREE
                        ) ? (
                          <img src={checkMark} alt="chevron" />
                        ) : (
                          "-"
                        )}
                      </td>
                      <td>
                        {Helpers.handlPullEquipmentStatus(
                          item.MTR_SHAFT_ROTATION_STIFFLCKD
                        ) ? (
                          <img src={checkMark} alt="chevron" />
                        ) : (
                          "-"
                        )}
                      </td>
                      <td>
                        {Helpers.handlPullEquipmentStatus(
                          item.MTR_SHAFT_ROTATION_TWSTBRKN
                        ) ? (
                          <img src={checkMark} alt="chevron" />
                        ) : (
                          "-"
                        )}
                      </td>
                      <td>
                        {Helpers.handlPullEquipmentStatus(
                          item.MTR_SHAFT_ROTATION_SDL
                        ) ? (
                          <img src={checkMark} alt="chevron" />
                        ) : (
                          "-"
                        )}
                      </td>
                      <td>
                        {Helpers.handlPullEquipmentStatus(
                          item.MOTOR_OIL_TOP_CLR
                        ) ? (
                          <img src={checkMark} alt="chevron" />
                        ) : (
                          "-"
                        )}
                      </td>
                      <td>
                        {Helpers.handlPullEquipmentStatus(
                          item.OTOR_OIL_TOP_DSCLR
                        ) ? (
                          <img src={checkMark} alt="chevron" />
                        ) : (
                          "-"
                        )}
                      </td>
                      <td>
                        {Helpers.handlPullEquipmentStatus(
                          item.MOTOR_OIL_TOP_BLCK
                        ) ? (
                          <img src={checkMark} alt="chevron" />
                        ) : (
                          "-"
                        )}
                      </td>
                      <td>
                        {Helpers.handlPullEquipmentStatus(
                          item.MOTOR_OIL_TOP_BRASS
                        ) ? (
                          <img src={checkMark} alt="chevron" />
                        ) : (
                          "-"
                        )}
                      </td>
                      <td>
                        {Helpers.handlPullEquipmentStatus(
                          item.MOTOR_OIL_TOP_H2O
                        ) ? (
                          <img src={checkMark} alt="chevron" />
                        ) : (
                          "-"
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="subsection-styling mb10">
            <table
              className="electrical-readings pullReport-pumps"
              cellSpacing="0"
              cellPadding="0"
            >
              <thead>
                <tr>
                  <th className="sub-section-header"></th>
                  <th colSpan={5}>Motor oil bottom</th>
                  <th colSpan={4}> Foreign material</th>
                </tr>
                <tr className="miniheader">
                  <th>Serial #</th>
                  <th>CLR</th>
                  <th>DSCLR</th>
                  <th>BLCK</th>
                  <th>BRASS</th>
                  <th className="section-divider">H2O</th>
                  <th>HEAD</th>
                  <th>BASE</th>
                  <th>BODY</th>
                  <th>ALL</th>
                </tr>
              </thead>
              <tbody>
                {protectorsEquipments?.map((item: any, idx: number) => {
                  return (
                    <tr key={`pullReportsPumps${idx}`}>
                      <td>{Helpers.markNullValues(item.serial)}</td>
                      <td>
                        {Helpers.handlPullEquipmentStatus(
                          item.MOTOR_OIL_BOTTOM_CLR
                        ) ? (
                          <img src={checkMark} alt="chevron" />
                        ) : (
                          "-"
                        )}
                      </td>
                      <td>
                        {Helpers.handlPullEquipmentStatus(
                          item.MOTOR_OIL_BOTTOM_DSCLR
                        ) ? (
                          <img src={checkMark} alt="chevron" />
                        ) : (
                          "-"
                        )}
                      </td>
                      <td>
                        {Helpers.handlPullEquipmentStatus(
                          item.MOTOR_OIL_BOTTOM_BLCK
                        ) ? (
                          <img src={checkMark} alt="chevron" />
                        ) : (
                          "-"
                        )}
                      </td>
                      <td>
                        {Helpers.handlPullEquipmentStatus(
                          item.MOTOR_OIL_BOTTOM_BRASS
                        ) ? (
                          <img src={checkMark} alt="chevron" />
                        ) : (
                          "-"
                        )}
                      </td>
                      <td>
                        {Helpers.handlPullEquipmentStatus(
                          item.MOTOR_OIL_BOTTOM_H2O
                        ) ? (
                          <img src={checkMark} alt="chevron" />
                        ) : (
                          "-"
                        )}
                      </td>
                      <td>
                        {Helpers.handlPullEquipmentStatus(
                          item.FOREIGN_MATERIAL_HEAD
                        ) ? (
                          <img src={checkMark} alt="chevron" />
                        ) : (
                          "-"
                        )}
                      </td>
                      <td>
                        {Helpers.handlPullEquipmentStatus(
                          item.FOREIGN_MATERIAL_BASE
                        ) ? (
                          <img src={checkMark} alt="chevron" />
                        ) : (
                          "-"
                        )}
                      </td>
                      <td>
                        {Helpers.handlPullEquipmentStatus(
                          item.FOREIGN_MATERIAL_BODY
                        ) ? (
                          <img src={checkMark} alt="chevron" />
                        ) : (
                          "-"
                        )}
                      </td>
                      <td>
                        {Helpers.handlPullEquipmentStatus(
                          item.FOREIGN_MATERIAL_ALL
                        ) ? (
                          <img src={checkMark} alt="chevron" />
                        ) : (
                          "-"
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      ) : isExpanded && title === "Sensor" ? (
        <div className="section-open-section-content" key="divGasHandling">
          <div className="subsection-styling mb10 bb">
            {tblCommons.map((set, index) => (
              <div key={index}>
                <div className="flex-display commons pumps">
                  {set.headers.map((header, i) => (
                    <div key={i} className="headerStyle">
                      {header}
                    </div>
                  ))}
                </div>
              </div>
            ))}
            {sensors?.map((datum: any, i: any) => (
              <div className="sequence-rows pumps">
                <div className="sequence-cells">
                  {Helpers.markNullValues(datum?.serialNumberOrOnReel)}
                </div>
                <div className="sequence-cells">
                  {Helpers.markNullValues(datum?.partNumber)}
                </div>
                <div className="sequence-cells">
                  {Helpers.markNullValues(datum?.itemDescription)}
                </div>
              </div>
            ))}
          </div>
          <div className="subsection-styling mb10">
            <table
              className="electrical-readings pullReport-pumps"
              cellSpacing="0"
              cellPadding="0"
            >
              <thead>
                <tr>
                  <th className="sub-section-header"></th>
                  <th colSpan={5}>Shaft rotation</th>
                  <th colSpan={4}>Foriegn material</th>
                </tr>
                <tr className="miniheader">
                  <th>Serial #</th>
                  <th>CLR</th>
                  <th>DSCLR</th>
                  <th>BLCK</th>
                  <th>BRASS</th>
                  <th className="section-divider">H2O</th>
                  <th>HEAD</th>
                  <th>BODY</th>
                  <th>SCRN</th>
                  <th>ALL</th>
                </tr>
              </thead>
              <tbody>
                {sensorsEquipments?.map((item: any, idx: number) => {
                  return (
                    <tr key={`pullReportsPumps${idx}`}>
                      <td>{Helpers.markNullValues(item.serial)}</td>
                      <td>
                        {Helpers.handlPullEquipmentStatus(
                          item.SHAFT_ROTATION_FREE
                        ) ? (
                          <img src={checkMark} alt="chevron" />
                        ) : (
                          "-"
                        )}
                      </td>
                      <td>
                        {Helpers.handlPullEquipmentStatus(
                          item.SHAFT_ROTATION_STIFF
                        ) ? (
                          <img src={checkMark} alt="chevron" />
                        ) : (
                          "-"
                        )}
                      </td>
                      <td>
                        {Helpers.handlPullEquipmentStatus(
                          item.SHAFT_ROTATION_LCKD
                        ) ? (
                          <img src={checkMark} alt="chevron" />
                        ) : (
                          "-"
                        )}
                      </td>
                      <td>
                        {Helpers.handlPullEquipmentStatus(
                          item.SHAFT_ROTATION_SDPLY
                        ) ? (
                          <img src={checkMark} alt="chevron" />
                        ) : (
                          "-"
                        )}
                      </td>
                      <td>
                        {Helpers.handlPullEquipmentStatus(
                          item.SHAFT_ROTATION_TWSTD
                        ) ? (
                          <img src={checkMark} alt="chevron" />
                        ) : (
                          "-"
                        )}
                      </td>
                      <td>
                        {Helpers.handlPullEquipmentStatus(
                          item.FOREIGN_MATERIAL_HEAD
                        ) ? (
                          <img src={checkMark} alt="chevron" />
                        ) : (
                          "-"
                        )}
                      </td>
                      <td>
                        {Helpers.handlPullEquipmentStatus(
                          item.FOREIGN_MATERIAL_BODY
                        ) ? (
                          <img src={checkMark} alt="chevron" />
                        ) : (
                          "-"
                        )}
                      </td>
                      <td>
                        {Helpers.handlPullEquipmentStatus(
                          item.FOREIGN_MATERIAL_SCRN
                        ) ? (
                          <img src={checkMark} alt="chevron" />
                        ) : (
                          "-"
                        )}
                      </td>
                      <td>
                        {Helpers.handlPullEquipmentStatus(
                          item.FOREIGN_MATERIAL_ALL
                        ) ? (
                          <img src={checkMark} alt="chevron" />
                        ) : (
                          "-"
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      ) : isExpanded && title === "Protector" ? (
        <div className="section-open-section-content" key="divGasHandling">
          <div className="subsection-styling mb10 bb">
            {tblCommons.map((set, index) => (
              <div key={index}>
                <div className="flex-display commons pumps">
                  {set.headers.map((header, i) => (
                    <div key={i} className="headerStyle">
                      {header}
                    </div>
                  ))}
                </div>
              </div>
            ))}
            {protectors?.map((datum: any, i: any) => (
              <div className="sequence-rows pumps">
                <div className="sequence-cells">
                  {Helpers.markNullValues(datum?.serialNumberOrOnReel)}
                </div>
                <div className="sequence-cells">
                  {Helpers.markNullValues(datum?.partNumber)}
                </div>
                <div className="sequence-cells">
                  {Helpers.markNullValues(datum?.itemDescription)}
                </div>
              </div>
            ))}
          </div>
          <div className="subsection-styling mb10">
            <table
              className="electrical-readings pullReport-protector"
              cellSpacing="0"
              cellPadding="0"
            >
              <thead>
                <tr>
                  <th className="sub-section-header"></th>
                  <th colSpan={4}>Shaft - top end</th>
                  <th colSpan={5}>Oil - middle section</th>
                </tr>
                <tr className="miniheader">
                  <th>Serial #</th>
                  <th>FREE</th>
                  <th>STIFF LCKD</th>
                  <th>TWST BRKN</th>
                  <th className="section-divider">SDPLY</th>
                  <th>CLR</th>
                  <th>DSCLR</th>
                  <th>BLK</th>
                  <th>BRASS</th>
                  <th>H2O</th>
                </tr>
              </thead>
              <tbody>
                {protectorsEquipments?.map((item: any, idx: number) => {
                  return (
                    <tr key={`pullReportsPumps${idx}`}>
                      <td>{Helpers.markNullValues(item.serial)}</td>
                      <td>
                        {Helpers.handlPullEquipmentStatus(
                          item.SHAFT_TOP_END_FREE
                        ) ? (
                          <img src={checkMark} alt="chevron" />
                        ) : (
                          "-"
                        )}
                      </td>
                      <td>
                        {Helpers.handlPullEquipmentStatus(
                          item.SHAFT_TOP_END_STIFFLCKD
                        ) ? (
                          <img src={checkMark} alt="chevron" />
                        ) : (
                          "-"
                        )}
                      </td>
                      <td>
                        {Helpers.handlPullEquipmentStatus(
                          item.SHAFT_TOP_END_TWSTBRKN
                        ) ? (
                          <img src={checkMark} alt="chevron" />
                        ) : (
                          "-"
                        )}
                      </td>
                      <td>
                        {Helpers.handlPullEquipmentStatus(
                          item.SHAFT_TOP_END_SDPLY
                        ) ? (
                          <img src={checkMark} alt="chevron" />
                        ) : (
                          "-"
                        )}
                      </td>
                      <td>
                        {Helpers.handlPullEquipmentStatus(
                          item.OIL_MIDDLE_SECTION_CLR
                        ) ? (
                          <img src={checkMark} alt="chevron" />
                        ) : (
                          "-"
                        )}
                      </td>
                      <td>
                        {Helpers.handlPullEquipmentStatus(
                          item.OIL_MIDDLE_SECTION_DSCLR
                        ) ? (
                          <img src={checkMark} alt="chevron" />
                        ) : (
                          "-"
                        )}
                      </td>
                      <td>
                        {Helpers.handlPullEquipmentStatus(
                          item.OIL_MIDDLE_SECTION_BLCK
                        ) ? (
                          <img src={checkMark} alt="chevron" />
                        ) : (
                          "-"
                        )}
                      </td>
                      <td>
                        {Helpers.handlPullEquipmentStatus(
                          item.OIL_MIDDLE_SECTION_BRASS
                        ) ? (
                          <img src={checkMark} alt="chevron" />
                        ) : (
                          "-"
                        )}
                      </td>
                      <td>
                        {Helpers.handlPullEquipmentStatus(
                          item.OIL_MIDDLE_SECTION_H2O
                        ) ? (
                          <img src={checkMark} alt="chevron" />
                        ) : (
                          "-"
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="subsection-styling mb10">
            <table
              className="electrical-readings pullReport-pumps"
              cellSpacing="0"
              cellPadding="0"
            >
              <thead>
                <tr>
                  <th className="sub-section-header"></th>
                  <th colSpan={5}>Oil - bottom section</th>
                  <th colSpan={4}>Oil - middle section</th>
                </tr>
                <tr className="miniheader">
                  <th>Serial #</th>
                  <th>CLR</th>
                  <th>DSCLR</th>
                  <th>BLCK</th>
                  <th>BRASS</th>
                  <th className="section-divider">H2O</th>
                  <th>FREE</th>
                  <th>STIFF LCKD</th>
                  <th>TWST BRKN</th>
                  <th>SDPLY</th>
                </tr>
              </thead>
              <tbody>
                {protectorsEquipments?.map((item: any, idx: number) => {
                  return (
                    <tr key={`pullReportsPumps${idx}`}>
                      <td>{Helpers.markNullValues(item.serial)}</td>
                      <td>
                        {Helpers.handlPullEquipmentStatus(
                          item.OIL_BOTTOM_SECTION_CLR
                        ) ? (
                          <img src={checkMark} alt="chevron" />
                        ) : (
                          "-"
                        )}
                      </td>
                      <td>
                        {Helpers.handlPullEquipmentStatus(
                          item.OIL_BOTTOM_SECTION_DSCLR
                        ) ? (
                          <img src={checkMark} alt="chevron" />
                        ) : (
                          "-"
                        )}
                      </td>
                      <td>
                        {Helpers.handlPullEquipmentStatus(
                          item.OIL_BOTTOM_SECTION_BLCK
                        ) ? (
                          <img src={checkMark} alt="chevron" />
                        ) : (
                          "-"
                        )}
                      </td>
                      <td>
                        {Helpers.handlPullEquipmentStatus(
                          item.OIL_BOTTOM_SECTION_BRASS
                        ) ? (
                          <img src={checkMark} alt="chevron" />
                        ) : (
                          "-"
                        )}
                      </td>
                      <td>
                        {Helpers.handlPullEquipmentStatus(
                          item.OIL_BOTTOM_SECTION_H2O
                        ) ? (
                          <img src={checkMark} alt="chevron" />
                        ) : (
                          "-"
                        )}
                      </td>
                      <td>
                        {Helpers.handlPullEquipmentStatus(
                          item.SHAFT_BOTTOM_END_FREE
                        ) ? (
                          <img src={checkMark} alt="chevron" />
                        ) : (
                          "-"
                        )}
                      </td>
                      <td>
                        {Helpers.handlPullEquipmentStatus(
                          item.SHAFT_BOTTOM_END_STIFFLCKD
                        ) ? (
                          <img src={checkMark} alt="chevron" />
                        ) : (
                          "-"
                        )}
                      </td>
                      <td>
                        {Helpers.handlPullEquipmentStatus(
                          item.SHAFT_BOTTOM_END_TWSTBRKN
                        ) ? (
                          <img src={checkMark} alt="chevron" />
                        ) : (
                          "-"
                        )}
                      </td>
                      <td>
                        {Helpers.handlPullEquipmentStatus(
                          item.SHAFT_BOTTOM_END_SDPLY
                        ) ? (
                          <img src={checkMark} alt="chevron" />
                        ) : (
                          "-"
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      ) : isExpanded && title === "Motor lead" ? (
        <div className="section-open-section-content" key="divMotorLead">
          <div className="subsection-styling mb10 bb">
            {tblCommons.map((set, index) => (
              <div key={index}>
                <div className="flex-display commons pumps">
                  {set.headers.map((header, i) => (
                    <div key={i} className="headerStyle">
                      {header}
                    </div>
                  ))}
                </div>
              </div>
            ))}
            <div className="sequence-rows pumps">
              <div className="sequence-cells">
                {Helpers.markNullValues(pullReport?.motorLeadSerialNo)}
              </div>
              <div className="sequence-cells">
                {Helpers.markNullValues(pullReport?.motorLeadPartNo)}
              </div>
              <div className="sequence-cells">
                {Helpers.markNullValues(pullReport?.motorLeadItemDescription)}
              </div>
            </div>
          </div>

          <div className="subsection-styling mb10">
            <table
              className="electrical-readings pullReport-pumps"
              cellSpacing="0"
              cellPadding="0"
            >
              <thead>
                <tr>
                  <th className="sub-section-header"></th>
                  <th colSpan={3}>MLE conditions</th>
                </tr>
                <tr className="miniheader">
                  <th>Serial #</th>
                  <th>POTHEAD</th>
                  <th>CABLE</th>
                  <th>SPLICE</th>
                </tr>
              </thead>
              <tbody>
                {/* {motorsEquipments?.map((item: any, idx: number) => {
                    return ( */}
                <tr key={`pullReportsML`}>
                  <td>
                    {Helpers.markNullValues(pullReport.motorLeadSerialNo)}
                  </td>

                  <td>
                    {Helpers.markNullValues(pullReport.mleConditionPothead)}
                  </td>
                  <td>
                    {Helpers.handlPullEquipmentStatus(
                      pullReport.mleConditionCable
                    ) ? (
                      <img src={checkMark} alt="chevron" />
                    ) : (
                      "-"
                    )}
                  </td>
                  <td>
                    {Helpers.handlPullEquipmentStatus(
                      pullReport.mleConditionSplice
                    ) ? (
                      <img src={checkMark} alt="chevron" />
                    ) : (
                      "-"
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="subsection-styling mb10">
            <table
              className="electrical-readings pullReport-pumps pullReport-motorLead"
              cellSpacing="0"
              cellPadding="0"
            >
              <thead>
                <tr>
                  <th className="sub-section-header"></th>
                  <th>MLE placement - on reel</th>
                  <th colSpan={4}>
                    {" "}
                    MLE placement - Cut in field and who has it
                  </th>
                </tr>
                <tr className="miniheader">
                  <th>SPOOLER</th>
                  <th>HOTSHOT</th>
                  <th>CONSULTANT</th>
                  <th>CUSTOMER</th>
                </tr>
              </thead>
              <tbody>
                <tr key={`pullReportsMLE`}>
                  <td>
                    {Helpers.markNullValues(pullReport.mlePlacementSpooler)}
                  </td>
                  <td>
                    {Helpers.markNullValues(pullReport.mlePlacementHotshot)}
                  </td>
                  <td>{sentenceCase(pullReport.mlePlacementConsultant)}</td>
                  <td>{sentenceCase(pullReport.mlePlacementCustomer)}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

const SectionList: React.FC = () => {
  const sections = [
    "Pump",
    "Gas handling / gas separator",
    "Intake",
    "Protector",
    "Motor",
    "Sensor",
    "Desander",
    "Motor lead",
    "Cable",
    "Wellhead",
    "Surface equipment",
    "Cable band, clamp, ESP body, tubing, and check/drain",
    "Foreign materials",
    "Phase to phase and phase to ground",
    "Sequence of events",
  ];

  return (
    <div className="section-list">
      {sections.map((section, index) => (
        <Section key={index} title={section} />
      ))}
    </div>
  );
};

const PullReport: React.FC<Toggle> = ({ open, onCloseModal }) => {
  const { pullReport } = useSelector(
    (state: any) => state.preInspectionSummary
  );
  useEffect(() => {
    document
      .getElementById("slider")
      ?.classList.toggle(open ? "open" : "close");

    return () => {
      document.getElementById("slider")?.classList.toggle("close");
    };
  }, [open]);

  document.querySelectorAll(".section-header")?.forEach((header) => {
    header.addEventListener("click", () => {
      header.parentElement?.classList.toggle("section-open");
    });
  });
  const onClick = () => {
    onCloseModal();
  };
  return (
    <div className="main-component">
      <CardHeader onClose={onClick} downloadLink={pullReport?.fileLink} />
      {Helpers.isObjectNotEmpty(pullReport) ? (
        <>
          <AttributeCard />
          <ESPDetails />
          <SectionList />
        </>
      ) : (
        <div>
          <div className="text-lg text-center py-40">No data found</div>
        </div>
      )}
    </div>
  );
};

const CardHeader: React.FC<any> = ({ onClose, downloadLink }) => {
  return (
    <div className="report-card-header">
      <div className="header-icon" onClick={onClose}>
        <img
          src={closeSlider}
          onClick={onClose}
          alt="icon"
          width="24"
          height="24"
        />
      </div>
      <div className="header-title">Pull report</div>
      <div className={`${downloadLink ? "" : "disabled"} download-button `}>
        <a href={downloadLink} download>
          <img
            src={downloadReport}
            alt="download-icon"
            width="129"
            height="20"
          />
        </a>
      </div>
    </div>
  );
};

const styles = {
  container: {
    backgroundColor: "#001023",
    padding: "24px",
    borderRadius: "8px",
  },
  header: {
    color: "#f4fbfc",
    fontFamily: "Mulish",
    fontWeight: 600,
    fontSize: "18px",
    lineHeight: "28px",
    marginBottom: "20px",
    marginLeft: "100px !important",
  },
  h2: {
    marginLeft: "1px !important",
  },
  row: {
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "20px",
    color: "#f7f9f9",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "8px",
    borderBottom: "1px solid #4a5463",
    height: "36px",
  },
  label: {
    flex: "1",
    textAlign: "left",
    fontFamily: "Mulish",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "20px",
  },
  value: {
    flex: "1",
    textAlign: "right",
    fontFamily: "Mulish",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "20px",
  },
};

export default PullReport;
