import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as DownArrow } from "../../../../images/chevron-down.svg";
import { ReactComponent as ArrowUpIcon } from "../../../../images/arrow-narrow-up.svg";
import { ReactComponent as ArrowDownIcon } from "../../../../images/arrow-down.svg";
import { formatDateInNumbers } from "../../../common/services/UtilityService";
import { fetchServiceReport } from "../../PreInspectionSummarySlice";
import ServiceReport from "../ServiceReport";

const PriorRuns: React.FC<{ searchQuery: string }> = ({ searchQuery }) => {
  const [showServiceReport, setShowServiceReport] = useState(false);
  const dispatch = useDispatch();
  const { difaRundaysDetails = [], loading } = useSelector(
    (state: any) => state.preInspectionSummary
  );

  const openReport = (wellApiNumber: string) => {
    dispatch(fetchServiceReport({ wellApiNumber: wellApiNumber }));
    setShowServiceReport(true);
  };

  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc");

  const handleSort = () => {
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };

  const sortedDifaTrackings = Array.isArray(difaRundaysDetails)
    ? [...difaRundaysDetails].sort((a, b) => {
      const dateA = new Date(a.difaFailedDate).getTime();
      const dateB = new Date(b.difaFailedDate).getTime();
      return sortOrder === "asc" ? dateA - dateB : dateB - dateA;
    })
    : [];

  const filteredDifaTrackings = sortedDifaTrackings.filter((item) =>
    item?.difaId.includes(searchQuery)
  );

  const formatPullReason = (pullReason: string | undefined) => {
    if (!pullReason) return "-";
    const parts = pullReason.split("-");
    if (parts.length > 1) {
      return (
        <>
          <p className="font-semibold">{parts[0]}</p>
          <p>{parts[1]}</p>
        </>
      );
    }
    return <p>{pullReason}</p>;
  };

  return (
    <div className="table-main relative border border-dark overflow-auto shadow-md sm:rounded-lg max-h-80">
      <table className="w-full text-sm text-left">
        <thead className="text-xs top-0 sticky font-semibold text-neutral-grey border-b border-dark">
          <tr>
            <th scope="col" className="px-3 py-4">
              <div className="flex items-center whitespace-nowrap">
                <span>DIFA ID #</span>
                <div className="cursor-pointer">
                  <DownArrow className="ms-1.5" />
                </div>
              </div>
            </th>
            <th scope="col" className="px-3 py-4">
              <div className="flex items-center">
                <span>Run life project #</span>
                <div className="cursor-pointer">
                  <DownArrow className="ms-1.5" />
                </div>
              </div>
            </th>
            <th scope="col" className="px-3 py-4">
              <div className="flex items-center">
                <span>Install date</span>
                <div className="cursor-pointer">
                  <DownArrow className="ms-1.5" />
                </div>
              </div>
            </th>
            <th scope="col" className="px-3 py-4">
              <div className="flex items-center">
                <span>Failed date</span>
                <div className="flex items-center cursor-pointer">
                  {sortOrder === "asc" ? <ArrowDownIcon onClick={handleSort} className="ms-1.5" /> : <ArrowUpIcon onClick={handleSort} className="ms-1.5" />}
                  <DownArrow className="ms-1.5" />
                </div>
              </div>
            </th>
            <th scope="col" className="px-3 py-4">
              <div className="flex items-center">
                <span>Pull date</span>
                <div className="cursor-pointer">
                  <DownArrow className="ms-1.5" />
                </div>
              </div>
            </th>
            <th scope="col" className="px-3 py-4">
              <div className="flex items-center">
                <span>Rundays</span>
                <div className="cursor-pointer">
                  <DownArrow className="ms-1.5" />
                </div>
              </div>
            </th>
            <th scope="col" className="px-3 py-4">
              <div className="flex items-center">
                <span>DIFA scheduled</span>
                <div className="cursor-pointer">
                  <DownArrow className="ms-1.5" />
                </div>
              </div>
            </th>
            <th scope="col" className="px-3 py-4">
              <div className="flex items-center">
                <span>Pull reason</span>
                <div className="cursor-pointer">
                  <DownArrow className="ms-1.5" />
                </div>
              </div>
            </th>
            <th scope="col" className="px-3 py-4">
              <div className="flex items-center">
                <span>Failure cause</span>
                <div className="cursor-pointer">
                  <DownArrow className="ms-1.5" />
                </div>
              </div>
            </th>
            <th scope="col" className="px-3 py-4">
              <div className="flex items-center">
                <span>Failed item</span>
                <div className="cursor-pointer">
                  <DownArrow className="ms-1.5" />
                </div>
              </div>
            </th>
            <th scope="col" className="px-3 py-4">
              <div className="flex items-center">
                <span>Primary failed component</span>
                <div className="cursor-pointer">
                  <DownArrow className="ms-1.5" />
                </div>
              </div>
            </th>
            <th scope="col" className="px-3 py-4">
              <div className="flex items-center">
                <span>Failure descriptor</span>
                <div className="cursor-pointer">
                  <DownArrow className="ms-1.5" />
                </div>
              </div>
            </th>
          </tr>
        </thead>
        <tbody className="font-normal text-sm text-neutral-grey">
          {loading ? (
            <tr className="border-b border-dark">
              <td colSpan={12} className="text-lg text-center py-5">
                Loading...
              </td>
            </tr>
          ) : filteredDifaTrackings.length > 0 ? (
            filteredDifaTrackings.map((difaRundaysDetail: any, index: number) => (
              <tr key={index} className="border-b border-dark">
                <td className="difa-id px-3 py-4 whitespace-nowrap" onClick={() => openReport(difaRundaysDetail?.wellApiNumber)}>{difaRundaysDetail?.difaId}</td>
                <td className="px-3 py-4 whitespace-nowrap">{difaRundaysDetail?.runLifeProjectNumber ?? "-"}</td>
                <td className="px-3 py-4 whitespace-nowrap">{formatDateInNumbers(difaRundaysDetail?.installDate)}</td>
                <td className="px-3 py-4 whitespace-nowrap">{difaRundaysDetail?.difaFailedDate ? formatDateInNumbers(difaRundaysDetail?.difaFailedDate) : "-"}</td>
                <td className="px-3 py-4 whitespace-nowrap">{formatDateInNumbers(difaRundaysDetail.pullDate)}</td>
                <td className="px-3 py-4 whitespace-nowrap">{difaRundaysDetail?.rundaysToPullStartDate}</td>
                <td className="px-3 py-4 whitespace-nowrap">{formatDateInNumbers(difaRundaysDetail?.actionDate)}</td>
                <td className="px-3 py-4 whitespace-nowrap">
                  <div className="flex flex-col">
                    {formatPullReason(difaRundaysDetail?.pullReason)}
                  </div>
                </td>
                <td className="px-3 py-4 whitespace-nowrap">{difaRundaysDetail?.failureCauseGeneral !== "" ? difaRundaysDetail?.failureCauseGeneral : "-"}</td>
                <td className="px-3 py-4 whitespace-nowrap">{difaRundaysDetail?.primaryFailedSubComponent || "-"}</td>
                <td className="px-3 py-4 whitespace-nowrap">{difaRundaysDetail?.primaryFailedComponent !== "" ? difaRundaysDetail?.primaryFailedComponent : "-"}</td>
                <td className="px-3 py-4 whitespace-nowrap">{difaRundaysDetail?.failureDescriptors !== "" ? difaRundaysDetail?.failureDescriptors : "-"}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={12} className="text-lg text-center py-5">
                No data found
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {showServiceReport && (
        <ServiceReport
          open={showServiceReport}
          onCloseModal={setShowServiceReport}
        />
      )}
    </div>

  );
};

export default PriorRuns;