import React, { useState } from "react";
import { useSelector } from "react-redux";
import { ReactComponent as DownArrow } from "../../../../images/chevron-down.svg";
import { ReactComponent as ArrowUpIcon } from "../../../../images/arrow-narrow-up.svg";
import { ReactComponent as ArrowDownIcon } from "../../../../images/arrow-down.svg";
import {
  formatDateInNumbers,
  getFirstLastEngineerLetter,
} from "../../../common/services/UtilityService";
import TicketView from "./TicketView";

const OptimizationTickets: React.FC<{ searchQuery: string }> = ({ searchQuery }) => {
  const [showTicket, setShowTicket] = useState(false);
  const { optimizationTickets, loading } = useSelector(
    (state: any) => state.preInspectionSummary
  );

  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc");

  const handleSort = () => {
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };

  const sortedTickets = optimizationTickets.length > 0 && [...optimizationTickets].sort((a, b) => {
    const dateA = new Date(a?.lastUpdated).getTime();
    const dateB = new Date(b?.lastUpdated).getTime();
    return sortOrder === "asc" ? dateA - dateB : dateB - dateA;
  });

  const filteredDifaTickets = sortedTickets && sortedTickets.filter(
    (item) =>
      item?.ticketId &&
      item?.ticketId.toString().includes(searchQuery)
  );

  const [selectedTicket, setSelectedTicket] = useState<any>(null);

  function openTicket(index: number): void {
    const ticket = Array.isArray(filteredDifaTickets) ? filteredDifaTickets[index] : null;
    setSelectedTicket(ticket);
    setShowTicket(true);
  }

  return (
    <div className="table-main relative border border-dark overflow-auto shadow-md sm:rounded-lg max-h-80">
      <table className="w-full text-left">
        <thead className="text-xs font-semibold top-0 sticky text-neutral-grey border-b border-dark">
          <tr className="table-row">
            <th scope="col" className="px-3 py-4">
              <div className="flex items-center">
                Ticket #
                <div className="cursor-pointer">
                  <DownArrow className="ms-1.5" />
                </div>
              </div>
            </th>
            <th scope="col" className="px-3 py-4">
              <div className="flex items-center">
                Requested
                <div className="cursor-pointer">
                  <DownArrow className="ms-1.5" />
                </div>
              </div>
            </th>
            <th scope="col" className="px-3 py-4">
              <div className="flex items-center">
                Last updated
                <div className="flex items-center cursor-pointer">
                  {sortOrder === "asc" ? <ArrowDownIcon onClick={handleSort} className="ms-1.5" /> : <ArrowUpIcon onClick={handleSort} className="ms-1.5" />}
                  <DownArrow className="ms-1.5" />
                </div>
              </div>
            </th>
            <th scope="col" className="px-3 py-4">
              <div className="flex items-center">
                Assignee
                <div className="cursor-pointer">
                  <DownArrow className="ms-1.5" />
                </div>
              </div>
            </th>
            <th scope="col" className="px-3 py-4">
              <div className="flex items-center">
                Subject
                <div className="cursor-pointer">
                  <DownArrow className="ms-1.5" />
                </div>
              </div>
            </th>
          </tr>
        </thead>
        <tbody className="text-sm font-normal text-neutral-grey">
          {loading ? (
            <tr className="table-row">
              <td colSpan={5} className="text-lg text-center py-5">
                Loading...
              </td>
            </tr>
          ) : filteredDifaTickets && filteredDifaTickets.length > 0 ? (
            filteredDifaTickets.map((optimizeTicket: any, index: number) => (
              <tr key={index} className="border-b border-dark">
                <td className="px-3 py-3 difa-id" onClick={() => openTicket(index)}>{optimizeTicket?.ticketId}</td>
                <td className="px-3 py-3">
                  {formatDateInNumbers(optimizeTicket?.ticketDate)}
                </td>
                <td className="px-3 py-3">
                  {formatDateInNumbers(optimizeTicket?.lastUpdated)}
                </td>
                <td className="px-3 py-3">
                  <div className="flex items-center gap-2">
                    <span className="assignee-name">
                      {getFirstLastEngineerLetter(optimizeTicket?.assignee)}
                    </span>
                    {optimizeTicket?.assignee}
                  </div>
                </td>
                <td className="px-3 py-3 w-2/5">{optimizeTicket?.subject.slice(0, 50)}....</td>
              </tr>
            ))
          ) : (
            <tr className="table-row">
              <td colSpan={5} className="text-lg text-center py-5">
                No data found
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {showTicket && selectedTicket &&
        <TicketView
          open={showTicket}
          onCloseModal={setShowTicket}
          ticketData={selectedTicket}
        />}
    </div>
  );
};

export default OptimizationTickets;
