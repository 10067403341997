// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#customTooltip {
  position: absolute;
  display: none;
  padding: 8px;
  background-color: #333;
  color: #fff;
  border-radius: 4px;
  pointer-events: none;
  font-size: 12px;
}

#chart-container {
  position: relative;
  width: 100%;
  height: 400px;
  overflow: visible;
}`, "",{"version":3,"sources":["webpack://./src/features/preInspectionSummary/components/RundaysChart.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,aAAA;EACA,YAAA;EACA,sBAAA;EACA,WAAA;EACA,kBAAA;EACA,oBAAA;EACA,eAAA;AACJ;;AACA;EACI,kBAAA;EACA,WAAA;EACA,aAAA;EACA,iBAAA;AAEJ","sourcesContent":["#customTooltip {\n    position: absolute;\n    display: none;\n    padding: 8px;\n    background-color: #333;\n    color: #fff;\n    border-radius: 4px;\n    pointer-events: none;\n    font-size: 12px;\n}\n#chart-container {\n    position: relative;\n    width: 100%;\n    height: 400px;\n    overflow: visible;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
