// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.difa-image-editor {
  margin: 0 auto;
}

.difa-img-editor-container {
  height: 80vh;
  width: 100%;
}

@media only screen and (max-width: 700px) {
  .difa-img-editor-container {
    height: 70vh;
    width: 100%;
  }
}
.control-wrapper {
  height: 100%;
}

.image-container {
  max-height: calc(100% - 140px);
}`, "",{"version":3,"sources":["webpack://./src/features/common/gallery/Gallery.scss"],"names":[],"mappings":"AAAA;EACI,cAAA;AACJ;;AAEA;EACI,YAAA;EACA,WAAA;AACJ;;AAEA;EACI;IACI,YAAA;IACA,WAAA;EACN;AACF;AAEA;EACI,YAAA;AAAJ;;AAGA;EAKI,8BAAA;AAJJ","sourcesContent":[".difa-image-editor {\n    margin: 0 auto;\n}\n\n.difa-img-editor-container {\n    height: 80vh;\n    width: 100%;\n}\n\n@media only screen and (max-width: 700px) {\n    .difa-img-editor-container {\n        height: 70vh;\n        width: 100%;\n    }\n}\n\n.control-wrapper {\n    height: 100%;\n}\n\n.image-container {\n    max-height: calc(100% - 140px);\n}\n\n.image-container {\n    max-height: calc(100% - 140px);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
