import SizingSchematic from "./SizingSchematic";
import "./ESPSizing.scss";
import PDFViewer from "../../../common/pdfViewer/FileViewer";
import { useSelector } from "react-redux";

const EspSizing: React.FC<{ wellApiNumber: string; source: string }> = ({
  wellApiNumber,
  source,
}) => {
  const { sizingSummary } = useSelector(
    (state: any) => state.difaPacketBuilder
  );
  const isPreview = source === "preview-packet";
  return (
    <div className="flex-auto flex flex-col w-full gap-5">
      {isPreview ? (
        sizingSummary?.isSelected ? (
          <PDFViewer file={sizingSummary?.fileLink} />
        ) : (
          <h3 className="file-error-msg">Nothing selected to preview</h3>
        )
      ) : (
        <SizingSchematic />
      )}
    </div>
  );
};

export default EspSizing;
