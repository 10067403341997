// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../images/calendar.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input-field-container {
  width: 100%;
  display: flex;
  gap: 4px;
  flex-direction: column;
}
.input-field-container .input-field-caption {
  color: var(--Neutral-Grey-25, #F7F9F9);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}
.input-field-container .input-field {
  width: 100%;
  color: #91a4aa;
  font-family: Mulish;
  font-size: 16px;
  box-sizing: border-box;
  font-weight: 400;
  height: 44px;
  line-height: 24px;
  border-radius: var(--radius-md, 8px);
  border: 1px solid var(--Darkmode-400, #4a5463);
  background: var(--Dark-mode-800, #001023);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  padding: 12px 14px;
}
.input-field-container .input-field-wrapper {
  background: var(--Dark-mode-800, #001023);
  border-radius: var(--radius-md, 8px);
}
.input-field-container .icon-date {
  padding-left: 40px;
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) no-repeat left 10px center;
  background-size: 20px;
}`, "",{"version":3,"sources":["webpack://./src/features/common/input/DifaInput.scss"],"names":[],"mappings":"AAAE;EACI,WAAA;EACA,aAAA;EACA,QAAA;EACA,sBAAA;AACN;AACM;EACI,sCAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;AACV;AAEM;EACI,WAAA;EACA,cAAA;EACA,mBAAA;EACA,eAAA;EACA,sBAAA;EACA,gBAAA;EACA,YAAA;EACA,iBAAA;EACA,oCAAA;EACA,8CAAA;EACA,yCAAA;EACA,kDAAA;EACA,kBAAA;AAAV;AAGM;EACI,yCAAA;EACA,oCAAA;AADV;AAIM;EACI,kBAAA;EACA,8EAAA;EACA,qBAAA;AAFV","sourcesContent":["  .input-field-container {\n      width: 100%;\n      display: flex;\n      gap: 4px;\n      flex-direction: column;\n\n      .input-field-caption {\n          color: var(--Neutral-Grey-25, #F7F9F9);\n          font-size: 14px;\n          font-style: normal;\n          font-weight: 600;\n          line-height: 20px;\n      }\n\n      .input-field {\n          width: 100%;\n          color: #91a4aa;\n          font-family: Mulish;\n          font-size: 16px;\n          box-sizing: border-box;\n          font-weight: 400;\n          height: 44px;\n          line-height: 24px;\n          border-radius: var(--radius-md, 8px);\n          border: 1px solid var(--Darkmode-400, #4a5463);\n          background: var(--Dark-mode-800, #001023);\n          box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);\n          padding: 12px 14px;\n      }\n\n      .input-field-wrapper {\n          background: var(--Dark-mode-800, #001023);\n          border-radius: var(--radius-md, 8px);\n      }\n\n      .icon-date {\n          padding-left: 40px;\n          background: url(\"../../../images/calendar.svg\") no-repeat left 10px center;\n          background-size: 20px;\n      }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
