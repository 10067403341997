
import { HTMLProps, useEffect, useRef, useState } from 'react';
import ArrowDown from './../../../images/chevron-down.svg';

export interface DifaDropdownProps extends HTMLProps<HTMLSelectElement> {
    classNames?: string;
    caption?: string;
    placeholder?: string;
    value?: string;
    options: Array<{
        value?: string;
        name?: string;
    }>;
    onFilterChange?: (filter: {
        value?: string;
        name?: string;
    }) => void;
}


const DifaDropdown: React.FC<DifaDropdownProps> = ({
    caption,
    classNames,
    options = [],
    value,
    onFilterChange = () => { },
    ...addtionalProps
}) => {
    const dropdownRef = useRef<HTMLDivElement>(null);
    const [selectedOption, setSelectedOption] = useState<{
        value?: string;
        name?: string;
    }>();
    const [isOpen, setIsOpen] = useState(false);
    const handleFilterChange = (option: {
        value?: string;
        name?: string;
    }) => {
        setSelectedOption(option);
        onFilterChange(option);
        setIsOpen(false);
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        setSelectedOption(((options || []).find(x => x.value === value) || null) as {
            value?: string;
            name?: string;
        });
    }, [value]);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };
    return (
        <div className={`flex flex-col w-full ${caption ? 'gap-2' : ''}`}>
            <div className="input-field-caption">{caption}</div>
            <div className={classNames} ref={dropdownRef}>
                <div style={{ justifyContent: selectedOption?.name ? 'space-between' : 'flex-end' }} className="selected-option field-background" onClick={toggleDropdown}>
                    {selectedOption?.name}
                    <img className="dropdown-arrow" src={ArrowDown} alt="filter-dropdown-arrow" />
                </div>
                {isOpen && (
                    <div className="filter-dropdown-menu">
                        {options.map((option, i) => (
                            <div
                                key={i}
                                className={`filter-dropdown-item ${selectedOption === option ? 'selected' : ''
                                    }`}
                                onClick={() => handleFilterChange(option)}
                            >
                                {option.name}
                                {selectedOption === option && (
                                    <span className="check-mark">✔</span>
                                )}
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>

    );
}

export default DifaDropdown;
