import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import DifaSwitch from '../../../common/switch/DifaSwitch'
import { DismantleRequestProps, NameInterface } from "../../../models/DismantleRequest";

const InboundTesting = () => {
    const [expanded, setExpanded] = useState(false);
    const [activeTab, setActiveTab] = useState<string>("PUMP MSC");
    const [tabContent, setTabContent] = useState<any>([]);
    const onExpand = () => {
        setExpanded(!expanded);
    }

    const getDifaDismantleRequest = useSelector(
        (state: any) => state.dismantleRequest?.getDismantleRequest[0]
    );
    const dismantleRequest = getDifaDismantleRequest?.dismantleRequests ?? [];

    useEffect(() => {
        if (dismantleRequest?.length > 0) {
            setTabContent(
                dismantleRequest.filter(
                    (equipment: DismantleRequestProps) => equipment.partType === activeTab
                )
            );
        }
    }, [activeTab, getDifaDismantleRequest]);

    const name: NameInterface = {
        "PUMP MSC": "Pumps",
        "GAS HANDLING": "Gas handling",
        "PROTACTOR": "Protector",
        "MOTOR DH": "Motor",
        "SENSOR-DH": "Sensor",
        "CABLE": "Cable & MLE",
    };

    return (
        <div className="difa-widget-containers">
            <div className="header-main">
                <div className="flex flex-row justify-between flex-wrap">
                    <div>
                        <h3>Inbound Testing</h3>
                        <h5>Add inbound testings results and images to your DIFA packet</h5>
                    </div>
                    <div className="flex flex-row items-center gap-3">
                        <DifaSwitch checked={expanded} onChange={onExpand} />
                        <h4 className="whitespace-nowrap">Include in report</h4>
                        <button className="btn-secondary font-bold text-sm">View inbound document</button>
                    </div>
                </div>
            </div>
            <div className="flex flex-col gap-6 border-t border-dark py-5 px-6">
                <div className="flex flex-col gap-2">
                    <h3>Results</h3>
                    <p className="text-sm font-medium">Summary of how the ESP performed during inbound testing.</p>
                </div>
                <div className="difa-detail-card-tab-content">
                    <div className="difa-card-tabs flex-col md:flex-row">
                        {Object.keys(name).map((key) => (
                            <div key={key} className={`tab-item ${activeTab === key ? "active" : ""}`} onClick={() => setActiveTab(key)}>
                                <div className={` ${activeTab === key ? "lable-active" : "tab-lable"}`}>
                                    {name[key]}
                                </div>
                            </div>
                        ))}
                    </div>

                    <div className="difa-content-table overflow-hidden">
                        <div className="table-header">
                            <div className="table-heading">{name[activeTab]}</div>
                        </div>
                        <div className="table-main">
                            <div className="table">
                                <div
                                    className="table-row sticky top-0"
                                    style={{ background: "#253240" }}
                                >
                                    <div className="th sno">Postion on ESP</div>
                                    <div className="th serial">Serial #</div>
                                    <div className="th eq-type">Equipment type</div>
                                    <div className="th i-results">Inbound results</div>
                                    <div className="th run-days">Run Time</div>
                                    <div className="th i-comments">Comments</div>
                                </div>

                                {tabContent.length === 0 ? (
                                    <div className="no-data">No Data Found</div>
                                ) : (
                                    tabContent.map((pump: any, index: number) => {
                                        return (
                                            <div className="table-row" key={index}>
                                                <div className="table-column sno">{index + 1}</div>
                                                <div className="table-column serial">
                                                    {pump?.serialNumber}
                                                </div>
                                                <div className="table-column eq-type">
                                                    {pump?.equipementType}
                                                </div>
                                                <div
                                                    className={`table-column ${pump?.inboundResults === "Fail" ? "failed" : "pass"
                                                        } i-results`}
                                                >
                                                    {pump?.inboundResults === "Fail"
                                                        ? "Failed"
                                                        : pump?.inboundResults}
                                                </div>
                                                <div className="table-column run-days">
                                                    {pump?.runtime ?? "New"}
                                                </div>
                                                <div className="table-column i-comments">
                                                    {pump?.comments !== "" ? pump?.comments : "-"}
                                                </div>
                                            </div>
                                        );
                                    })
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col gap-4 border-t border-dark pt-6">
                    <div className="flex flex-row gap-3 justify-between items-center">
                        <div className="flex flex-col gap-2">
                            <h3>Inbound image collection</h3>
                            <p className="text-sm font-medium">Upload, annotate, and edit inbound images.</p>
                        </div>
                        <button className="btn-secondary font-bold text-sm">Create Gallery</button>
                    </div>
                    <div className="flex flex-row p-6 items-center justify-center border border-dark bg-dark-800 rounded-lg">
                        <p className="text-sm font-normal text-center py-5 text-gray-300">No images have been added. To add photos and create a inbound image <br/> collection, click the "Add collection" button.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default InboundTesting
