import instance from "../../config/HttpCommon";

export const getSizingSummary = async (wellApiNumber: string) => {
    const determineSummaryRes = await instance.get(
        `/determine/GetSizingSummary?wellApiNumber=${wellApiNumber}`
    );
    return determineSummaryRes?.data;
};

export const getDaviationSurvey = async (wellApiNumber: string) => {
    const determineSurveyRes = await instance.get(
        `/determine/GetDeviationSurveyPerformance?wellApiNumber=${wellApiNumber}`
    );
    return determineSurveyRes?.data;
};

export const getGetDeviationSurveyPerformance = async (wellApiNumber: string) => {
    const determineSurveyRes = await instance.get(
        `/determine/GetDeviationSurveyPerformance?wellApiNumber=${wellApiNumber}`
    );
    return determineSurveyRes?.data;
};

export const getEquipmentDetails = async (wellApiNumber: string) => {
    const equipmentDetailsRes = await instance.get(
        `/Determine/GetSizingSummary?wellapinumber=${wellApiNumber}`
    );
    return equipmentDetailsRes?.data;
};

export const getInstallReportImages = async (wellApiNumber: string, difaId: string) => {
    const installReportImages = await instance.get(
        `/Difa/GetInstallReportImages?wellapinumber=${wellApiNumber}&difaId=${difaId}`
    );
    return installReportImages?.data;
};

export const getImagesGallery = async (wellApiNumber: string, difaId: string, reportType: string) => {
    const gallery = await instance.get(
        `/ReportImages/GetImageGallery?wellapinumber=${wellApiNumber}&difaId=${difaId}&reportType=${reportType}`
    );
    return gallery?.data;
};

export const getPullReportImages = async (wellApiNumber: string, difaId: string) => {
    const installReportImages = await instance.get(
        `/Difa/GetPullReportImages?wellapinumber=${wellApiNumber}&difaId=${difaId}`
    );
    return installReportImages?.data;
};

export const createImageGallery = async (galleryDetails: any) => {
    const installReportImages = await instance.post(
        `/ReportImages/CreateImageGallery`, galleryDetails
    );
    return installReportImages?.data;
};

export const deleteImageGallery = async (galleryId: string) => {
    const deleteResponse = await instance.delete(
        `/ReportImages/DeleteImageGallery?galleryID=${galleryId}`
    );
    return deleteResponse?.data;
};

export const getDifaTrackingDetails = async (wellApiNumber: string, difaId: string) => {
    const difaTrackingDetails = await instance.get(
        `/Difa/GetDifaTrackingDetails?wellapinumber=${wellApiNumber}&difaId=${difaId}`
    );
    return difaTrackingDetails?.data;
};

