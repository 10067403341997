import { useEffect, useRef, useState } from "react";
import { Dialog, DialogBackdrop, DialogPanel } from '@headlessui/react'
import closeIcon from "./../../../images/close-icon.svg";
import HrizontalTab from "../tabs/horizontalTab";
import DifaDropdown from "../dropdown/Dropdown";
import DifaInput from "../input/DifaInput";
import DifaCheckbox from "../checkbox/DifaCheckbox";
import { useDispatch } from "react-redux";
import Loader from "../loader/Loader";
import { useLocation } from "react-router-dom";
import { getFileName, getFileType, sentenceCase, toBase64, toDataURL } from "../services/UtilityService";
import { isNullOrUndefined, getComponent } from '@syncfusion/ej2-base';
import { registerLicense } from '@syncfusion/ej2-base';
import './Gallery.scss';
import { groupBy, keys } from 'lodash';
import CreateGallery from "./CreateGallery";
import UploadDocuments from "../uploadFiles/UploadDocuments";
import { postImageGallery } from "../../difaPacketBuilder/difaPacketBuilderSlice";
registerLicense(process.env.REACT_APP_SYNCFUSION_LICENSE_KEY!);
interface GalleryComponentProps {
    show: boolean;
    wellApiNumber: string;
    difaID?: string;
    onGalleryClose?: () => void,
    images: Array<any>,
    loading?: boolean,
    reportType?: string
}

const GalleryComponent: React.FC<GalleryComponentProps> = ({ show, reportType = '', difaID = '', wellApiNumber, onGalleryClose = () => { }, images = [], loading = false }) => {
    const [showDialog, setShowDialog] = useState(false);
    const [showCancelDialog, setShowCancelDialog] = useState(false);
    const [galleryName, setGalleryName] = useState('');
    const [activeTab, setActiveTab] = useState(0);
    const [activeItem, setActiveItem] = useState(null as any);
    const location = useLocation();
    const [locationWiseImages, setLocationWiseImages] = useState({} as any);
    const dispatch = useDispatch();
    const [selectedPhotosFromGallery, setSelectedPhotosFromGallery] = useState([] as any);
    const [uploadedFiles, setUploadedFiles] = useState<any>([]);

    function handleUploadedFiles(files: File[]): void {
        const newFiles = [...uploadedFiles];
        files.forEach(i => newFiles.push({
            originalFileName: i.name,
            blob: URL.createObjectURL(i),
            file: i
        }));
        setUploadedFiles(newFiles);
    }

    const close = () => {
        setShowDialog(false);
        onGalleryClose();
    }

    useEffect(() => {
        setShowDialog(show);
    }, [show]);
    useEffect(() => {
        if (images && images.length) {
            const grouppedImages = groupBy(images, (x: any) => x.location);
            setLocationWiseImages(grouppedImages);
        } else {
            setLocationWiseImages({});
        }
    }, [images])
    const tabs = ['Media library', 'My device'];

    const handleGalleryClose = async (name?: string) => {
        if (name) {
            const reportImages = [] as any;
            if (selectedPhotosFromGallery && selectedPhotosFromGallery.length) {
                for (const item of selectedPhotosFromGallery) {
                    let dataUrl = await toDataURL(item.fileLink) as string;
                    const fileType = getFileType(item.fileLink)
                    dataUrl = dataUrl.replace('data:application/octet-stream;base64', `data:image/${fileType};base64`);
                    reportImages.push({
                        originalFileName: getFileName(item.fileLink),
                        originalFileLink: item.fileLink,
                        description: '',
                        fileBase64: dataUrl
                    })
                }
            }
            if (uploadedFiles && uploadedFiles.length) {
                for (const item of uploadedFiles) {
                    let dataUrl = await toBase64(item.file) as string;
                    reportImages.push({
                        originalFileName: item.originalFileName,
                        originalFileLink: '',
                        description: '',
                        fileBase64: dataUrl
                    })
                }
            }

            let newGallery = {
                reportType,
                galleryName: name,
                wellApiNumber,
                difaID: difaID?.toString(),
                createdBy: 'tirupathi.temburu@championx.com',
                reportImageDetails: reportImages
            }

            dispatch(postImageGallery(newGallery));
            setShowDialog(false);
            setShowCancelDialog(false);
            setGalleryName(name);
            onGalleryClose();
        } else {
            setShowCancelDialog(false);
            setGalleryName('');
        }
    }

    const photoSelectionChange = (e: any, photo: any) => {
        if (e.target.checked) {
            setSelectedPhotosFromGallery([...selectedPhotosFromGallery, photo]);
        } else {
            setSelectedPhotosFromGallery(selectedPhotosFromGallery.length ? selectedPhotosFromGallery.filter((x: any) => x !== photo) : []);
        }
    }

    return (
        <>
            {loading && <Loader isOverlay />}
            <Dialog open={showDialog} onClose={close} className="relative z-50">
                <DialogBackdrop
                    transition
                    className="fixed inset-0 backdrop-blur-sm transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
                />

                <div className="fixed inset-0 z-50 w-screen">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <DialogPanel
                            transition
                            className="flex dialog-max-height relative transform overflow-hidden rounded-lg difa-background-with-border text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-sm md:min-w-4/5 md:w-4/5 md:max-w-full data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                        >
                            <div className="difa-widget-containers">
                                <div className="header-main border-bottom">
                                    <div className="flex flex-row justify-between">
                                        <div>
                                            <h3>Create gallery</h3>
                                        </div>
                                        <a href="javascript:void(0)" onClick={close}>
                                            <img src={closeIcon} alt="close" />
                                        </a>
                                    </div>
                                </div>
                                <div className="p-6 w-full flex flex-col overflow-y-auto image-container pb-16 table-main">
                                    <HrizontalTab tabs={tabs} tabChange={setActiveTab} />
                                    {activeTab === 0 &&
                                        <div className="flex flex-row">
                                            <div className="w-full p-4 ">
                                                {
                                                    false &&
                                                    <div className="flex flex-row justify-between items-end">
                                                        <div className="w-80"> <DifaDropdown caption="Filter by" value="All images" options={[{ name: 'All images', value: 'All images' }]} /></div>
                                                        <div className="w-80"><DifaInput caption="" /></div>
                                                    </div>
                                                }
                                                <div>
                                                    {keys(locationWiseImages).map((key: string) => (
                                                        <div className="mt-6">
                                                            <h2 className="mt-1 mb-3">{sentenceCase(key)}</h2>
                                                            <div className="flex gap-3 flex-wrap">
                                                                {
                                                                    locationWiseImages[key].map((photo: any, index: number) => (
                                                                        <div className="flex flex-col gap-3  w-48 max-w-48" onClick={() => setActiveItem(photo)}>
                                                                            <div className={`difa-widget-containers relative max-w-48 h-48 p-2 field-background ${activeItem === photo ? 'active-item-border' : ''}`}>
                                                                                <div className="absolute top-5">
                                                                                    <DifaCheckbox checked={selectedPhotosFromGallery && !!selectedPhotosFromGallery.find((x: any) => x === photo)} onChange={(e: any) => photoSelectionChange(e, photo)} />
                                                                                </div>
                                                                                <div className="w-full h-full difa-background flex items-center">
                                                                                    <img className="h-32 w-full" src={photo.fileLink} alt={photo.originalFileName}></img>
                                                                                </div>
                                                                            </div>
                                                                            <h5>
                                                                                {photo.caption}
                                                                            </h5>
                                                                        </div>
                                                                    ))
                                                                }
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                            {
                                                activeItem
                                                &&
                                                <div className="flex-auto border-left p-5 max-w-80">
                                                    <img className="h-32 w-60 min-w-60 object-cover" src={activeItem.fileLink} alt="meter"></img>
                                                    <h2 className="mt-1 mb-1">{activeItem.caption}</h2>
                                                    <h5 className="mt-1 mb-1">{getFileType(activeItem.fileLink)} image</h5>
                                                    <h2 className="mt-3 mb-3">Image details</h2>
                                                    <div className="mt-3">
                                                        <div className="border-bottom flex justify-between gap-2"> <h5>Source</h5><h5>{activeItem.source}</h5></div>
                                                    </div>
                                                    <div className="mt-3">
                                                        <div className="border-bottom flex justify-between gap-2"> <h5>Location</h5><h5>{activeItem.location}</h5></div>
                                                    </div>
                                                    <div className="mt-3">
                                                        <div className="flex justify-between gap-5"> <h5>Comments</h5><h5>{activeItem.comments}</h5></div>
                                                    </div>
                                                </div>
                                            }

                                        </div>
                                    }
                                    {activeTab === 1 && showDialog &&
                                        <div className="flex">
                                            <div className="block w-full border-t border-dark p-6">
                                                <UploadDocuments
                                                    key={'image-gallery'}
                                                    showPreview={false}
                                                    fileType={'images'}
                                                    onFilesChange={handleUploadedFiles}
                                                />
                                                <div className="flex gap-3 flex-wrap mt-8">
                                                    {
                                                        uploadedFiles.map((photo: any, index: number) => (
                                                            <div className="flex flex-col gap-3  w-48 max-w-48" onClick={() => { }}>
                                                                <div className={`difa-widget-containers relative max-w-48 h-48 p-2 field-background ${true ? 'active-item-border' : ''}`}>
                                                                    <div className="w-full h-full difa-background flex items-center">
                                                                        {/* <Loader showText={false} /> */}
                                                                        <img className="h-32 w-full" src={photo.blob} alt={photo.caption}></img>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div className="flex-row flex gap-3 border-top justify-end items-center h-16 mr-6">
                                    <div className="secondary-button">
                                        <button className="header-btn-text w-20" onClick={close}>Cancel</button>
                                    </div>
                                    <div className="primary-button">
                                        <button className="header-btn-text" onClick={() => setShowCancelDialog(true)} disabled={!(uploadedFiles.length || selectedPhotosFromGallery.length)}>Next</button>
                                    </div>
                                </div>
                            </div>
                        </DialogPanel>
                    </div>
                </div>
            </Dialog>
            {
                showCancelDialog &&
                <CreateGallery show={showCancelDialog} close={async (name: string | any) => await handleGalleryClose(name)} />
            }
        </>
    )
}

export default GalleryComponent;