import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as ArrowRightIcon } from "../../../images/chevron-right.svg";
import RouteConstant from "../../../constants/routes";
import "./Header.scss";
export interface HeaderProps {
  pageName: string;
  difaPacketName: string;
  customerName?: string;
  wellName?: string;
  source?: string;
}

const Header: React.FC<HeaderProps> = ({
  pageName,
  difaPacketName,
  customerName = 'Oscorp',
  wellName = 'CONNER 15-10 (ALLOC-D) 4NB ',
  source,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { difaId } = location.state || {};
  
  const difaDetails = () => {
    navigate("/difa-details", {
      state: { difaId, customerName, wellName },
    });
  };

  const dashboard = () => {
    navigate("/dashboard");
  };

  const redirectToWellName = () => {
    wellName &&
      navigate(
        RouteConstant.wellName.replace(
          ":wellName",
          encodeURIComponent(wellName)
        ),
        {
          state: { difaId: difaId, customerName: customerName },
        }
      );
  };

  const redirectToPreview = () => {
    navigate("/difa-details/preview-difa-packet", {
      state: { difaId: difaId, customerName: customerName, wellName },
    });
  };

  const redirectToBuilder = () => {
    navigate('/difa-details/difa-packet-builder', {
      state: { difaId: difaId, customerName: customerName, wellName },
    });
    
  };

  const isPreview = source === "preview";

  return (
    <header className="main-header">
      {isPreview && (
        
        <div className="preview-container">
  <span className="message">You are now in Preview mode.</span>
  <button className="publish-button">Publish DIFA packet</button>
  <button className="button" onClick={redirectToBuilder}>Back to Report Builder</button>
</div>
      )}
      <div className="header-content">
        {!isPreview && (
          <div className="breadcrumb-container">
            <div className="breadcrumb-item" onClick={dashboard}>
              Dashboard
            </div>
            <div className="breadcrumb-separator">
              <ArrowRightIcon />
            </div>
            <div className="breadcrumb-item" onClick={difaDetails}>
              DIFA details
            </div>
            <div className="breadcrumb-separator">
              <ArrowRightIcon />
            </div>
            <div className="breadcrumb-item active">{pageName}</div>
          </div>
        )}

        <div className="header-details">
          <div className="header-details-content">
            <div className="header-title">{difaPacketName}</div>
            <div className="header-meta">
              <div className="meta-company">{customerName} |</div>
              <div className="meta-project" onClick={redirectToWellName}>
                {wellName}
              </div>
            </div>
          </div>
          {!isPreview && (
            <div className="flex-row flex gap-3">
              <div className="header-button">
                <button className="header-btn-text" onClick={redirectToPreview}>Preview</button>
              </div>
              <div className="header-button">
                <button className="header-btn-text">Save</button>
              </div>
              <div className="header-details-button">
                <button className="header-btn-text">Publish DIFA packet</button>
              </div>
            </div>
          )}
        </div>
      </div>
          </header>
  );
};

export default Header;
