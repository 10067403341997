// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Header.css */
.main-header {
  width: auto;
  position: sticky;
  background-color: #131F2E;
  top: 0;
  z-index: 40;
}`, "",{"version":3,"sources":["webpack://./src/features/dashboard/components/Header.css"],"names":[],"mappings":"AAAA,eAAe;AACf;EACE,WAAW;EACX,gBAAgB;EAChB,yBAAyB;EACzB,MAAM;EACN,WAAW;AACb","sourcesContent":["/* Header.css */\n.main-header {\n  width: auto;\n  position: sticky;\n  background-color: #131F2E;\n  top: 0;\n  z-index: 40;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
