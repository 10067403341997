import React, { useEffect, useState } from "react";

import "./InstallReport.scss";
import "./SectionsReport.scss";
import closeSlider from "../../../images/close-slider.svg";
import downloadReport from "../../../images/download-report.svg";
import sectionExpand from "../../../images/chevron-down.svg";
import sectionCollapse from "../../../images/chevron-up.svg";
import { useSelector } from "react-redux";
import Helpers from "../../../utilities/Helpers";
import Loader from "../../common/loader/Loader";
import { sentenceCase } from "../../common/services/UtilityService";

interface Toggle {
  open: boolean;
  onCloseModal: any;
}
interface AttributeCardProps {
  wellCoordinates: string;
  installDate: string;
  modemIP: string;
  customerRep: string;
  fieldServiceTech: string;
  customer: string;
  wellName: string;
  wellAPI: string;
  field: string;
  county: string;
  state: string;
}

const AttributeCard: React.FC<AttributeCardProps> = ({
  wellCoordinates,
  installDate,
  modemIP,
  customerRep,
  fieldServiceTech,
  customer,
  wellName,
  wellAPI,
  field,
  county,
  state,
}) => {
  return (
    <div className="attribute-card">
      <h2 className="default-attribute-header">Attributes</h2>
      <div className="attribute-row">
        <span className="label">Well site coordinates</span>
        <span className="value">{Helpers.markNullValues(wellCoordinates)}</span>
      </div>
      <div className="attribute-row">
        <span className="label">Install date</span>
        <span className="value">{Helpers.formatDate(installDate)}</span>
      </div>
      <div className="attribute-row">
        <span className="label">Modem IP address</span>
        <span className="value">{Helpers.markNullValues(modemIP)}</span>
      </div>
      <div className="attribute-row">
        <span className="label">Customer rep</span>
        <span className="value">{Helpers.markNullValues(customerRep)}</span>
      </div>
      <div className="attribute-row">
        <span className="label">Field service tech</span>
        <span className="value">
          {sentenceCase(fieldServiceTech)}
        </span>
      </div>
      <div className="attribute-row">
        <span className="label">Customer</span>
        <span className="value">{sentenceCase(customer)}</span>
      </div>
      <div className="attribute-row">
        <span className="label">Well name</span>
        <span className="value">{Helpers.markNullValues(wellName)}</span>
      </div>
      <div className="attribute-row">
        <span className="label">Well API #</span>
        <span className="value">{Helpers.markNullValues(wellAPI)}</span>
      </div>
      <div className="attribute-row">
        <span className="label">Field</span>
        <span className="value">{sentenceCase(field)}</span>
      </div>
      <div className="attribute-row">
        <span className="label">County</span>
        <span className="value">{sentenceCase(county)}</span>
      </div>
      <div className="attribute-row">
        <span className="label">State</span>
        <span className="value">{sentenceCase(state)}</span>
      </div>
    </div>
  );
};

const ESPDetails: React.FC = () => {
  const { installreport } = useSelector(
    (state: any) => state.preInspectionSummary
  );
  const details: Record<string, any> = {
    "Total depth": Helpers.markNullValues(installreport?.totalDepth),
    "Casing size/wt": Helpers.markNullValues(installreport?.casingSizeWeight),
    "Liner size/wt": Helpers.markNullValues(installreport?.linerSizeWeight),
    "Liner top at": Helpers.markNullValues(installreport?.linerTopAt),
    "Perf or open hole interval": Helpers.isFieldEmpty(installreport?.perfOrOpenHoleIntervalFrom) || 
    Helpers.isFieldEmpty (installreport?.horizontalWindowIntervalFrom) ? '-' :
      Helpers.markNullValues(installreport?.perfOrOpenHoleIntervalFrom) +
      " to " +
      Helpers.markNullValues(installreport?.perfOrOpenHoleIntervalTo),
    "Horizontal window interval":
    Helpers.isFieldEmpty(installreport?.horizontalWindowIntervalFrom) || 
    Helpers.isFieldEmpty (installreport?.horizontalWindowIntervalTo) ? '-' :
      Helpers.markNullValues(installreport?.horizontalWindowIntervalFrom) +
      " to " +
      Helpers.markNullValues(installreport?.horizontalWindowIntervalTo),
    "Tubing size": Helpers.markNullValues(installreport?.tubingSize),
    KB: Helpers.markNullValues(installreport?.kb),
    "# of joints": Helpers.markNullValues(installreport?.numberOfJoints),
    "Tubing tally": Helpers.markNullValues(installreport?.tubingTally),
    "Cable clamp size": Helpers.markNullValues(installreport?.cableClampSize), // not found in json
  };

  return (
    <div className="attribute-card">
      <h2 className="default-attribute-header">ESP details</h2>
      <div>
        {Object.entries(details).map(([label, value], index) => (
          <div key={index} style={styles.row}>
            <span>{label}</span>
            <span>{value}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

const headerStyle = {
  color: "#F7F9F9",
  fontSize: "12px",
  fontFamily: "Mulish",
  fontWeight: "400",
  lineHeight: "18px",
  padding: "9px 12px",
  borderBottom: "1px #4A5463 solid",
};

const cellStyle = {
  fontWeight: 400,
  fontSize: "12px",
  lineHeight: "20px",
  fontFamily: "Mulish, sans-serif",
  color: "#f7f9f9",
  padding: "9px 12px",
};
const Section: React.FC<{ title: string }> = ({ title }) => {
  const { installreport } = useSelector(
    (state: any) => state.preInspectionSummary
  );
  const [isExpanded, setIsExpanded] = useState(false);
  const tblTail = [
    {
      headers: [
        "Size",
        "# joints of tail pipe",
        "Bull plug",
        "Length",
        "Set depth",
      ],
      data: [
        Helpers.markNullValues(installreport?.tailPipeSize),
        Helpers.markNullValues(installreport?.noOfJointsOfTailpipe),
        Helpers.formatYesNo(installreport?.bullPlug),
        Helpers.markNullValues(installreport?.tailPipeLength),
        Helpers.markNullValues(installreport?.tailPipeSetDepth,true),
      ],
    },
  ];
  const tblCTLine = [
    {
      headers: [
        "Part #",
        "Item description",
        "New/used",
        "CT check valve",
        "On reel #",
        "Vendor",
        "Length",
        "Total equip length",
      ],
      data: [
        Helpers.markNullValues(installreport?.ctLinePartNumber),
        Helpers.markNullValues(installreport?.ctLineItemDescription),
        Helpers.markNullValues(installreport?.ctLineNewOrUsed),
        Helpers.markNullValues(installreport?.ctLineCtCheckValve),
        Helpers.markNullValues(installreport?.ctLineOnReelNumber),
        Helpers.markNullValues(installreport?.ctLineVendor),
        Helpers.markNullValues(installreport?.ctLineLength),
        Helpers.markNullValues(installreport?.ctLineTotalEquipLength),
      ],
    },
  ];
  const tableData3rdparty = [
    {
      headers: [
        "Drain valve size",
        "Drain valve set at",
        "Check valve size",
        "Check valve set at",
        "D&C length",
      ],
      data: [
        Helpers.markNullValues(installreport?.drainValveSize),
        Helpers.markNullValues(installreport?.drainValveSetAt),
        Helpers.markNullValues(installreport?.checkValveSize),
        Helpers.markNullValues(installreport?.checkValveSetAt),
        Helpers.markNullValues(installreport?.dandCLength),
      ],
    },
    {
      headers: ["Sandguard serial #", "Sandguard", "length"],
      data: [
        Helpers.markNullValues(installreport?.sandguardSerialNumber),
        Helpers.markNullValues(installreport?.sandguardQty),
        Helpers.markNullValues(installreport?.sandguardLength),
      ],
    },
    {
      headers: ["Cable band type"],
      data: [Helpers.markNullValues(installreport?.cableBandType)],
    },
    {
      headers: ["Cable clamp type", "Quantity", "DH equipment clamps"],
      data: [
        Helpers.markNullValues(installreport?.cableClampType),
        Helpers.markNullValues(installreport?.sandguardQty),
        Helpers.formatYesNo(installreport?.dhEquipmentClamps),
      ],
    },
  ];
  const tblDataSubSwedge = [
    {
      headers: ["Sub / swedge and collar", "Length", "BOH", "Length"],
      data: [
        Helpers.markNullValues(installreport?.subsSwedgeAndCollar),
        Helpers.markNullValues(installreport?.subsSwedgeAndCollarLength),
        Helpers.markNullValues(installreport?.boh),
        Helpers.markNullValues(installreport?.bohLength),
      ],
    },
    {
      headers: ["Discharge pressure sub", "Length", "Set depth"],
      data: [
        Helpers.markNullValues(installreport?.dischargePressureSub),
        Helpers.markNullValues(installreport?.dischargePressureSubLength),
        Helpers.markNullValues(installreport?.dischargePressureSubSetDepth, true),
      ],
    },
  ];
  const tblCommons = [
    {
      headers: [
        "Serial #",
        "Port number",
        "Item Description",
        "Length",
        "Set depth",
      ],
    },
  ];
  const tblMotorLead = [
    {
      headers: [
        "Serial #",
        "Port number",
        "Item Description",
        "Length",
        "Set depth",
      ],
      data: [
        Helpers.markNullValues(
          installreport?.installEquipmentMotorleadSv?.serialNumber
        ),
        Helpers.markNullValues(
          installreport?.installEquipmentMotorleadSv?.partNumber
        ),
        Helpers.markNullValues(
          installreport?.installEquipmentMotorleadSv?.itemDescription
        ),
        Helpers.markNullValues(
          installreport?.installEquipmentMotorleadSv?.length
        ),
        Helpers.markNullValues(
          installreport?.installEquipmentMotorleadSv?.onReelNumber
        ),
      ],
    },
  ];

  const tblEvents = [
    {
      headers: ["Time", "Sequence of events", "Exceptions"],
    },
  ];
  const tblWellhead = [
    {
      headers: ["Well head size", "Well head manufacturer"],
      data: [
        Helpers.markNullValues(installreport?.wellHeadSize), // not found in json
        Helpers.markNullValues(installreport?.wellHeadManufacturer),
      ],
    },
    {
      headers: ["Penetrator serial #", "Manufacturer", "Type", "New"],
      data: [
        Helpers.markNullValues(installreport?.wellHeadPenetratorSerialNumber),
        Helpers.markNullValues(installreport?.wellHeadPenetratorManufacturer),
        Helpers.markNullValues(installreport?.wellHeadPenetratorType),
        Helpers.markNullValues(installreport?.wellHeadNew),
      ],
    },
  ];

  const tblSurface = [
    {
      headers: ["VSD / SWB serial #", "Part #", "Item description"],
      data: [
        Helpers.markNullValues(installreport?.vsdSwbSerialNumber),
        Helpers.markNullValues(installreport?.vsdSwbPartNumber),
        Helpers.markNullValues(installreport?.vsdSwbItemDescription),
      ],
    },
    {
      headers: ["Transformer serial # ", "Part #", "Item description"],
      data: [
        Helpers.markNullValues(installreport?.transformerSerialNumber),
        Helpers.markNullValues(installreport?.transformerPartNumber),
        Helpers.markNullValues(installreport?.transformerItemDescription),
      ],
    },
    {
      headers: [
        "Harmonic filter serial # ",
        "Part #",
        "Item description",
        "Primary S/D XFRMR kVa",
        "Secondary voltage",
        " PRI S/D XFRMR Conm",
      ],
      data: [
        Helpers.markNullValues(installreport?.harmonicFilterSerialNumber),
        Helpers.markNullValues(installreport?.harmonicFilterPartNumber),
        Helpers.markNullValues(installreport?.harmonicFilterItemDescription),
        Helpers.markNullValues(installreport?.harmonicFilterPrimarySDXfrmrKva),
        Helpers.markNullValues(installreport?.harmonicFilterSecondaryVoltage),
        Helpers.markNullValues(installreport?.harmonicFilterPriSDXfrmrConn),
      ],
    },
  ];

  const tblShroud = [
    {
      headers: ["Shroud part #", "Item description", "Shroud set depth"],
      data: [
        Helpers.markNullValues(installreport?.shroudPartNumber),
        Helpers.markNullValues(installreport?.shroudItemDescription),
        Helpers.markNullValues(installreport?.shroudSetDpth),
      ],
    },
    {
      headers: ["Hanger part # ", "Item description", "Length", "New"],
      data: [
        Helpers.markNullValues(installreport?.hangerPartNumber),
        Helpers.markNullValues(installreport?.hangerItemDescription),
        Helpers.markNullValues(installreport?.shroudLength),
        Helpers.markNullValues(installreport?.shroudNew), 
      ],
    },
  ];

  let commonData: any = [];
  switch (title) {
    case "Pump":
      commonData = installreport?.installEquipmentInfoDto?.Pump;
      break;
    case "Gas handling / gas separator":
      commonData = installreport?.installEquipmentInfoDto?.GasHandllingGasSep;
      break;
    case "Intake":
      commonData = installreport?.installEquipmentInfoDto?.Intake;
      break;
    case "Protector":
      commonData = installreport?.installEquipmentInfoDto?.Protector;
      break;
    case "Motor":
      commonData = installreport?.installEquipmentInfoDto?.Motor;
      break;
    case "Sensor":
      commonData = installreport?.installEquipmentInfoDto?.Sensor;
      break;
    case "Desander":
      commonData = installreport?.installEquipmentInfoDto?.Desander;
      break;
    case "Cable":
      commonData = installreport?.installEquipmentInfoDto?.Cable;
      break;
    default:
      break;
  }
  const electricalMetadata = ['UT MOTOR', 'CT MOTOR', 'LT MOTOR'];
  let systemElectricDataFirst: any = [];
  let systemElectricDataRemaining: any = [];
  installreport?.installEquipmentValuesDto &&
    Object.keys(installreport?.installEquipmentValuesDto)?.forEach((a) => {
      installreport?.installEquipmentValuesDto[a].map((item: any) =>  {
        if (electricalMetadata.includes(a))
          return systemElectricDataFirst.push(item);
        else return systemElectricDataRemaining.push(item)
      });
    });
  return (
    <div className="section">
      <div
        className="section-header"
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <span>{title}</span>
        <img src={isExpanded ? sectionCollapse : sectionExpand} alt="chevron" />
      </div>

      {isExpanded && title === "Third party equipment" ? (
        <div
          className="section-open-section-content"
          key="Third_party_equipment"
        >
          {tableData3rdparty.map((set, index) => (
            <div key={index} className="subsection-styling mb10">
              <div className={`flex-display thirdParty-${index}`}>
                {set.headers.map((header, i) => (
                  <div key={i} style={{ flex: 1, ...headerStyle }}>
                    {header}
                  </div>
                ))}
              </div>
              <div className={`flex-display thirdParty-${index}`}>
                {set.data.map((datum, i) => (
                  <div key={i} style={{ flex: 1, ...cellStyle }}>
                    {datum}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      ) : isExpanded &&
        title === "Sub / swedge and collar and discharge sub" ? (
        <div className="section-open-section-content" key="sub/swedge">
          {tblDataSubSwedge.map((set, index) => (
            <div key={index} className="subsection-styling mb10">
              <div className={`flex-display subSwedge-${index}`}>
                {set.headers.map((header, i) => (
                  <div key={i} style={{ flex: 1, ...headerStyle }}>
                    {header}
                  </div>
                ))}
              </div>
              <div className={`flex-display subSwedge-${index}`}>
                {set.data.map((datum, i) => (
                  <div key={i} style={{ flex: 1, ...cellStyle }}>
                    {datum}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      ) : isExpanded && title === "Motor lead" ? (
        <div className="section-open-section-content" key="motorLead">
          {tblMotorLead.map((set, index) => (
            <div key={index} className="subsection-styling mb10">
              <div className={`flex-display motorLead-${index}`}>
                {set.headers.map((header, i) => (
                  <div key={i} style={{ flex: 1, ...headerStyle }}>
                    {header}
                  </div>
                ))}
              </div>
              <div className={`flex-display motorLead-${index}`}>
                {set.data.map((datum, i) => (
                  <div key={i} style={{ flex: 1, ...cellStyle }}>
                    {datum}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      ) : isExpanded && title === "Wellhead and penetrator" ? (
        <div className="section-open-section-content" key="wellhead">
          {tblWellhead.map((set, index) => (
            <div key={index} className="subsection-styling mb10">
              <div className="flex-display wellHead">
                {set.headers.map((header, i) => (
                  <div key={i} style={{ flex: 1, ...headerStyle }}>
                    {header}
                  </div>
                ))}
              </div>
              <div className="flex-display wellHead">
                {set.data.map((datum, i) => (
                  <div key={i} style={{ flex: 1, ...cellStyle }}>
                    {datum}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      ) : isExpanded && title === "Shroud and hanger" ? (
        <div className="section-open-section-content" key="shroud">
          {tblShroud.map((set, index) => (
            <div key={index} className="subsection-styling mb10">
              <div className="flex-display shroud">
                {set.headers.map((header, i) => (
                  <div key={i} style={{ flex: 1, ...headerStyle }}>
                    {header}
                  </div>
                ))}
              </div>
              <div className="flex-display shroud">
                {set.data.map((datum, i) => (
                  <div key={i} style={{ flex: 1, ...cellStyle }}>
                    {datum}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      ) : isExpanded && title === "Surface equipment" ? (
        <div className="section-open-section-content" key="surface">
          {tblSurface.map((set, index) => (
            <div key={index} className="subsection-styling mb10">
              <div className="flex-display surfaceEquipment">
                {set.headers.map((header, i) => (
                  <div key={i} style={{ flex: 1, ...headerStyle }}>
                    {header}
                  </div>
                ))}
              </div>
              <div className="flex-display surfaceEquipment">
                {set.data.map((datum, i) => (
                  <div key={i} style={{ flex: 1, ...cellStyle }}>
                    {datum}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      ) : isExpanded && title === "Sequence of events" ? (
        <div className="section-open-section-content" key="seqEvents">
          <div className="subsection-styling">
            {tblEvents.map((set, index) => (
              <div key={index}>
                <div className="flex-display seqEvents">
                  {set.headers.map((header, i) => (
                    <div key={i} style={{ flex: 1, ...headerStyle }}>
                      {header}
                    </div>
                  ))}
                </div>
              </div>
            ))}
            {installreport?.installEventsExceptionsDto?.map(
              (datum: any, i: any) => (
                <div className="sequence-rows seqEvents">
                  <div className="sequence-cells">
                    {Helpers.isFieldEmpty(datum.eventTime) || Helpers.isFieldEmpty(datum.eventAMPM) ? '-' :
                    `${datum?.eventTime} ${datum?.eventAMPM}`}
                  </div>
                  <div className="sequence-cells">
                    {Helpers.markNullValues(datum?.eventDescription)}
                  </div>
                  <div className="sequence-cells">
                    {Helpers.markNullValues(datum?.exceptionDescription)}
                  </div>
                </div>
              )
            )}
          </div>
        </div>
      ) : isExpanded && title === "Tail pipe" ? (
        <div className="section-open-section-content" key="tailPipe">
          {tblTail.map((set, index) => (
            <div key={index} className="subsection-styling mb10">
              <div className="flex-display">
                {set.headers.map((header, i) => (
                  <div key={i} style={{ flex: 1, ...headerStyle }}>
                    {header}
                  </div>
                ))}
              </div>
              <div className="flex-display">
                {set.data.map((datum, i) => (
                  <div key={i} style={{ flex: 1, ...cellStyle }}>
                    {datum}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      ) : isExpanded && title === "CT line" ? (
        <div className="section-open-section-content" key="ctLine">
          {tblCTLine.map((set, index) => (
            <div key={index} className="subsection-styling mb10">
              <div className={`flex-display ctLine-${index}`}>
                {set.headers.map((header, i) => (
                  <div key={i} style={{ flex: 1, ...headerStyle }}>
                    {header}
                  </div>
                ))}
              </div>
              <div className={`flex-display ctLine-${index}`}>
                {set.data.map((datum, i) => (
                  <div key={i} style={{ flex: 1, ...cellStyle }}>
                    {datum}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      ) : isExpanded && title === "System electrical integrity test" ? (
        <div className="section-open-section-content" key="sytemIntegrity">
          <div className="subsection-styling mb10">
            <table className="electrical-readings" cellSpacing="0" cellPadding="0">
              <thead>
                <tr>
                  <th rowSpan={2} className="sub-section-header">
                    Electrical readings will be recorded with meter values
                  </th>
                  <th colSpan={3}>Phase to ground</th>
                  <th colSpan={3}>Phase to ground</th>
                </tr>
                <tr className="miniheader">
                  <th>A-B</th>
                  <th>B-C</th>
                  <th className="section-divider">C-A</th>
                  <th>A-GND</th>
                  <th>B-GND</th>
                  <th>C-GND</th>
                </tr>
              </thead>
              <tbody>
                {systemElectricDataFirst?.map((item: any, i: string) => {
                  return (
                    <tr key={`system${i}`}>
                      <td>{item.equipmentType}</td>
                      <td>
                        {Helpers.markNullValues(item.abValue)} {Helpers.isFieldEmpty(item.abValue) ? "" : "Ω"}
                      </td>
                      <td>
                      {Helpers.markNullValues(item.bcValue)} {Helpers.isFieldEmpty(item.bcValue) ? "" : "Ω"}
                       
                      </td>
                      <td>
                      {Helpers.markNullValues(item.caValue)} {Helpers.isFieldEmpty(item.caValue) ? "" : "Ω"}
                       
                      </td>
                      <td>
                      {Helpers.markNullValues(item.aGndValue)} {Helpers.isFieldEmpty(item.aGndValue) ? "" : "Ω"}
                       
                      </td>
                      <td>
                      {Helpers.markNullValues(item.bGndValue)} {Helpers.isFieldEmpty(item.bGndValue) ? "" : "Ω"}
                        
                      </td>
                      <td>
                      {Helpers.markNullValues(item.cGndValue)} {Helpers.isFieldEmpty(item.cGndValue) ? "" : "Ω"}
                        
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="subsection-styling">
            <table className="electrical-readings" cellSpacing="0" cellPadding="0">
              <thead>
                <tr>
                  <th rowSpan={2} className="sub-section-header">
                    Electrical readings will be recorded with meter values
                  </th>
                  <th colSpan={3}>Phase to ground</th>
                  <th colSpan={3}>Phase to ground</th>
                </tr>
                <tr className="miniheader">
                  <th>A-B</th>
                  <th>B-C</th>
                  <th className="section-divider">C-A</th>
                  <th>A-GND</th>
                  <th>B-GND</th>
                  <th>C-GND</th>
                </tr>
              </thead>
              <tbody>
                {systemElectricDataRemaining?.map((item: any, i: string) => {
                  return (
                    <tr key={`system${i}`}>
                      <td>{item.equipmentType}</td>
                      <td>
                        {Helpers.markNullValues(item.abValue)} {Helpers.isFieldEmpty(item.abValue) ? "" : "Ω"}
                      </td>
                      <td>
                      {Helpers.markNullValues(item.bcValue)} {Helpers.isFieldEmpty(item.bcValue) ? "" : "Ω"}
                       
                      </td>
                      <td>
                      {Helpers.markNullValues(item.caValue)} {Helpers.isFieldEmpty(item.caValue) ? "" : "Ω"}
                       
                      </td>
                      <td>
                      {Helpers.markNullValues(item.aGndValue)} {Helpers.isFieldEmpty(item.aGndValue) ? "" : "Ω"}
                       
                      </td>
                      <td>
                      {Helpers.markNullValues(item.bGndValue)} {Helpers.isFieldEmpty(item.bGndValue) ? "" : "Ω"}
                        
                      </td>
                      <td>
                      {Helpers.markNullValues(item.cGndValue)} {Helpers.isFieldEmpty(item.cGndValue) ? "" : "Ω"}
                        
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        isExpanded && (
          //commons
          <div className="section-open-section-content" key={title}>
            <div className="subsection-styling">
              {tblCommons.map((set, index) => (
                <div key={index}>
                  <div className="flex-display commons">
                    {set.headers.map((header, i) => (
                      <div key={i} style={{ flex: 1, ...headerStyle }}>
                        {header}
                      </div>
                    ))}
                  </div>
                </div>
              ))}
              {commonData?.map((datum: any, i: any) => (
                <div className="sequence-rows commons">
                  <div className="sequence-cells">{Helpers.markNullValues(datum?.serial)}</div>
                  <div className="sequence-cells">{Helpers.markNullValues(datum?.partNumber)}</div>
                  <div className="sequence-cells">{Helpers.markNullValues(datum?.itemDiscription)}</div>
                  <div className="sequence-cells">{Helpers.markNullValues(datum?.length)}</div>
                  <div className="sequence-cells">{Helpers.markNullValues(datum?.setdepth, true)}</div>
                </div>
              ))}
            </div>
          </div>
        )
      )}
    </div>
  );
};

const SectionList: React.FC = () => {
  const sections = [
    "Third party equipment",
    "Sub / swedge and collar and discharge sub",
    "Pump",
    "Gas handling / gas separator",
    "Intake",
    "Protector",
    "Motor",
    "Sensor",
    "Desander",
    "Tail pipe",
    "Motor lead",
    "Cable",
    "CT line",
    "Wellhead and penetrator",
    "Shroud and hanger",
    "Surface equipment",
    "System electrical integrity test",
    "Sequence of events",
  ];

  return (
    <div className="section-list">
      {sections.map((section, index) => (
        <Section key={index} title={section} />
      ))}
    </div>
  );
};

const MainComponent: React.FC<Toggle> = ({ open, onCloseModal }) => {
  const { installreport, loading } = useSelector(
    (state: any) => state.preInspectionSummary
  );
  useEffect(() => {
    document
      .getElementById("slider")
      ?.classList.toggle(open ? "open" : "close");

    return () => {
      document.getElementById("slider")?.classList.toggle("close");
    };
  }, [open]);

  document.querySelectorAll(".section-header").forEach((header) => {
    header.addEventListener("click", () => {
      header.parentElement?.classList.toggle("section-open");
    });
  });
  const onClick = () => {
    onCloseModal();
  };
  return (
    <div className="main-component">
      {loading && <Loader isOverlay />}
      <>
        <CardHeader onClose={onClick} downloadLink={installreport?.fileLink} />
        {Helpers.isObjectNotEmpty(installreport) ? (
          <>
            <AttributeCard
              wellCoordinates={installreport?.wellSiteCoordinates}
              installDate={installreport?.installDate}
              modemIP={installreport?.modemIPaddress}
              customerRep={installreport?.customerRep}
              fieldServiceTech={installreport?.fieldServiceTechnician}
              customer={installreport?.customer}
              wellName={installreport?.wellName}
              wellAPI={installreport?.wellApiNumber}
              field={installreport?.field}
              county={installreport?.county}
              state={installreport?.state}
            />
            <ESPDetails />
            <SectionList />
          </>
        ) : (
          <div>
            <div className="text-lg text-center py-40">No data found</div>
          </div>
        )}
      </>
    </div>
  );
};

const CardHeader: React.FC<any> = ({ onClose, downloadLink }) => {
  return (
    <div className="report-card-header">
      <div className="header-icon" onClick={onClose}>
        <img
          src={closeSlider}
          onClick={onClose}
          alt="icon"
          width="24"
          height="24"
        />
      </div>
      <div className="header-title">Install report</div>
      <div className={`${downloadLink ? "" : "disabled"} download-button `}>
        <a href={downloadLink} download>
          <img
            src={downloadReport}
            alt="download-icon"
            width="129"
            height="20"
          />
        </a>
      </div>
    </div>
  );
};

const styles = {
  container: {
    backgroundColor: "#001023",
    padding: "24px",
    borderRadius: "8px",
  },
  header: {
    color: "#f4fbfc",
    fontFamily: "Mulish",
    fontWeight: 600,
    fontSize: "18px",
    lineHeight: "28px",
    marginBottom: "20px",
    marginLeft: "100px !important",
  },
  h2: {
    marginLeft: "1px !important",
  },
  row: {
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "20px",
    color: "#f7f9f9",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "8px",
    borderBottom: "1px solid #4a5463",
    height: "36px",
  },
  label: {
    flex: "1",
    textAlign: "left",
    fontFamily: "Mulish",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "20px",
  },
  value: {
    flex: "1",
    textAlign: "right",
    fontFamily: "Mulish",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "20px",
  },
};

export default MainComponent;
