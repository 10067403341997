import React, { useState } from "react";
import downloadImg from "../../../images/download-01.svg";
import { ReactComponent as SearchIcon } from "../../../images/search-md.svg";
import "./PISummaryDocumentaion.scss";
import PriorRuns from "./documentationTables/PriorRuns";
import ServiceCalls from "./documentationTables/ServiceCalls";
import OptimizationTickets from "./documentationTables/OptimizationTickets";
interface Tab {
  label: string;
  component: React.ComponentType<{ searchQuery: string }>;
}

const PISummaryDocumentaion: React.FC = () => {

  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");

  const tabs: Tab[] = [
    { label: "Prior runs", component: PriorRuns },
    { label: "Service reports", component: ServiceCalls },
    { label: "Optimization tickets", component: OptimizationTickets },
  ];

  const setInputPlaceholder = () => {
    switch (activeTabIndex) {
      case 0:
        return "Search DIFA ID";
      case 1:
        return "Search diapatch ID #";
      case 2:
        return "Search by Ticket #";
      default:
        return "Search DIFA ID";
    }
  };

  const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      setSearchQuery(value);
    }
  };

  return (
    <div className="flex flex-col gap-3 PIS-doc-container pb-5">
      <div className="flex gap-2 px-6 items-center justify-between flex-wrap">
        <div className="tab-section">
          <div className="tabs">
            {tabs.map((tab, index) => (
              <div
                key={index}
                className={`tab ${index === activeTabIndex ? "active" : ""}`}
                onClick={() => setActiveTabIndex(index)}
              >
                {tab.label}
              </div>
            ))}
          </div>
        </div>
        <div className="flex justify-end items-center gap-2 flex-1">
          <div className="pr-2 download-img">
            <img src={downloadImg} alt="download-table-data" className="p-2 cursor-not-allowed" />
          </div>
          <div>
            <div className="w-40 md:w-80 mx-auto">
              <div className="relative">
                <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                  <SearchIcon width="20" height="20" />
                </div>
                <input
                  type="search"
                  id="default-search"
                  className="flex items-center w-full p-3 ps-10 text-base font-medium rounded-lg search-box"
                  placeholder={setInputPlaceholder()}
                  value={searchQuery}
                  onChange={handleSearchInputChange}
                  required
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mx-6">
        {tabs.map((tab, index) => {
          return (
            <>
              {
                index === activeTabIndex &&
                <div
                  key={index}
                  className={`documentaion-table-container ${index === activeTabIndex ? "block" : "tab-inactive"}`}
                >
                  {React.createElement(tab?.component, { searchQuery })}
                </div>
              }
            </>

          );
        })}
      </div>
    </div>
  );
};

export default PISummaryDocumentaion;
