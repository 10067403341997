import React, { useEffect, useState } from 'react'
import DifaSwitch from '../../../common/switch/DifaSwitch'
import { useDispatch, useSelector } from 'react-redux';
import { setWellInstallation } from '../../difaPacketBuilderSlice';

const OperationalSummary = () => {

    const { wellInstallation } = useSelector(
        (state: any) => state.difaPacketBuilder
    );
    const dispatch = useDispatch();
    
    const [expanded, setExpanded] = useState(false);
    const [panel, setPanel] = useState(false);
    const [operationalSummary, setOperatonalSummary] = useState("");
    const onExpand = () => {
        setExpanded(!expanded);               
    }

    useEffect(() => {
        if(!expanded) {
            setPanel(true);
        }
        return () => {
            dispatch(setWellInstallation({...wellInstallation, isOpSummarySelected: expanded, opSummary: operationalSummary}))
        }
    }, [expanded])

    return (
        <div className="difa-widget-containers">
            <div className="header-main">
                <div className="flex flex-row justify-between flex-wrap">
                    <div>
                        <h3>OperationSummary</h3>
                        <h5>Summarize your observations about the ESP's Operational life based on the data presented above.</h5>
                    </div>
                    <div className="flex flex-row items-center gap-3">
                        <DifaSwitch checked={expanded} onChange={onExpand} />
                        <h4 className="whitespace-nowrap">Include in report</h4>
                    </div>
                </div>
            </div>
            <div className="flex flex-col gap-5 py-5 px-6 border-t border-dark">
                <textarea
                    className="card-textarea"
                    maxLength={500}
                    rows={6}
                    onChange={(e) => setOperatonalSummary(e.target.value) }
                    value={operationalSummary}
                    placeholder="Enter Operation Summary here..."
                ></textarea>
            </div>
        </div>
    )
}

export default OperationalSummary
