import React, { lazy } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Layout } from "./layout/PageLayout";
import { setFavicon } from "./utilities/setFavicon";
import Dashboard from "./features/dashboard/Dashboard"
import WellDetails from "./features/wellDetail/WellDetails";
import DifaDetails from "./features/DifaPages/DifaDetails";
import PreInspectionSummary from "./features/preInspectionSummary/PreInspectionSummary";
import DismentleRequest from "./features/dismentleRequest/DismantleRequest";
import DifaReportBuilder from "./features/difaReport/DifaReportBuilder";
import DifaPacketBuilder from "./features/difaPacketBuilder/difaPacketBuilder";
import DifaPacketPreview from "./features/previewDifaPacket/previewDifaPacket";


const Login = lazy(() => import('./features/login/Login'));
const AuthComponent = lazy(() => import('./features/common/AuthComponent'));

const App: React.FC = () => {
  const applyTheme = () => {
    setFavicon();
  };

  applyTheme();

  return (
    <Router>
      <Routes>
      <Route path='/login' element={<Login />} />
      <Route path="/" element={<AuthComponent component={Layout} />} >
        <Route index element={<AuthComponent component={Dashboard} />} />
        <Route path="dashboard" element={<AuthComponent component={Dashboard} />} />
        <Route path="well-details/:wellName" element={<AuthComponent component={WellDetails} />} />
        <Route path="difa-details" element={<AuthComponent component={DifaDetails} />} />
        <Route path="difa-details/pi-summary" element={<AuthComponent component={PreInspectionSummary} />} />
        <Route path="difa-details/dismantle-request" element={<AuthComponent component={DismentleRequest} />} />
        <Route path="difa-details/difa-report-builder" element={<AuthComponent component={DifaReportBuilder} />} />
        <Route path="difa-details/difa-packet-builder" element={<AuthComponent component={DifaPacketBuilder} />} />
        <Route path="difa-details/preview-difa-packet" element={<AuthComponent component={DifaPacketPreview} />} />
      </Route>
      </Routes>
    </Router>
  );
};

export default App;
