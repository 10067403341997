import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { createImageGallery, deleteImageGallery, getDaviationSurvey, getDifaTrackingDetails, getEquipmentDetails, getImagesGallery, getInstallReportImages, getPullReportImages, getSizingSummary } from "./difaPacketBuilderServices";

export const fetchRundaysDetails: any = createAsyncThunk(
    "packet-builder/getSizingSummary",
    async ({ wellApiNumber }: { wellApiNumber: string }) => {
        return await getSizingSummary(wellApiNumber);
    }
);

export const fetchDeviationSurvey: any = createAsyncThunk(
    "packet-builder/getDeviationSurvey",
    async ({ wellApiNumber }: { wellApiNumber: string }) => {
        return await getDaviationSurvey(wellApiNumber);
    }
);

export const fetchEquipmentDetails: any = createAsyncThunk(
    "packet-builder/getEquipmentDetails",
    async ({ wellApiNumber }: { wellApiNumber: string }) => {
        return await getEquipmentDetails(wellApiNumber);
    }
);

export const fetchInstallReportImages: any = createAsyncThunk(
    "packet-builder/getInstallReportImages",
    async ({ wellApiNumber, difaId }: { wellApiNumber: string, difaId: string }) => {
        return await getInstallReportImages(wellApiNumber, difaId);
    }
);

export const fetchPullImageGallery: any = createAsyncThunk(
    "packet-builder/GetPullImageGallary",
    async ({ wellApiNumber, difaId, reportType = 'pull' }: { wellApiNumber: string, difaId: string, reportType: string }) => {
        return await getImagesGallery(wellApiNumber, difaId, reportType);
    }
);

export const fetchInstallImageGallery: any = createAsyncThunk(
    "packet-builder/GetInstallImageGallary",
    async ({ wellApiNumber, difaId, reportType = 'install' }: { wellApiNumber: string, difaId: string, reportType: string }) => {
        return await getImagesGallery(wellApiNumber, difaId, reportType);
    }
);

export const fetchPullReportImages: any = createAsyncThunk(
    "difa/GetPullReportImages",
    async ({ wellApiNumber, difaId }: { wellApiNumber: string, difaId: string }) => {
        return await getPullReportImages(wellApiNumber, difaId);
    }
);

export const postImageGallery: any = createAsyncThunk(
    "difa/createImageGallery",
    async (galleryDetails: any) => {
        return await createImageGallery(galleryDetails);
    }
);

export const removeGallery: any = createAsyncThunk(
    "difa/removeGallery",
    async (galleryId: string) => {
        return await deleteImageGallery(galleryId);
    }
);

export const fetchGeneralReport: any = createAsyncThunk(
    "difa/getGeneralReport", async ({ wellApiNumber, difaId }: { wellApiNumber: string, difaId: string }) => {
        return await getDifaTrackingDetails(wellApiNumber, difaId);
    }
);

export const resetCreateGalleryState = createAction('RESET_ACREATE_GALLERY');

const initialState: any = {
    loading: false,
    sizingSummary: null,
    generalReport: null,
    deviationSurvey: null,
    equipmentDetails: null,
    installReportImages: null,
    pullReportImages: null,
    installImageGallery: null,
    pullImageGallery: null,
    production: null,
    message: null,
    wellInstallation: null,
    galleryTransactionMsg: null
};

const DifaPacketBuilderSlice = createSlice({
    name: "difaPacketBuilder",
    initialState,
    reducers: {
        setSizingSummary: (state, action) => {
            state.sizingSummary = action.payload;
        },
        setProduction: (state, action) => {
            state.production = action.payload;
        },
        setWellInstallation: (state, action) => {
            state.wellInstallation = action.payload;
        }
        
    },
    extraReducers: (builder: any) => {
        //pending
        builder.addCase(fetchRundaysDetails.pending, (state: any) => {
            state.loading = true;
        });

        //fullfilled
        builder.addCase(fetchRundaysDetails.fulfilled, (state: any, action: any) => {
            state.sizingSummary = action.payload;
            state.loading = false;
        });

        //rejected
        builder.addCase(fetchRundaysDetails.rejected, (state: any, action: any) => {
            state.message = action.payload;
            state.loading = false;
        });

        //pending
        builder.addCase(fetchDeviationSurvey.pending, (state: any) => {
            state.loading = true;
        });

        //fullfilled
        builder.addCase(fetchDeviationSurvey.fulfilled, (state: any, action: any) => {
            state.deviationSurvey = action.payload;
            state.loading = false;
        });

        //rejected
        builder.addCase(fetchDeviationSurvey.rejected, (state: any, action: any) => {
            state.message = action.payload;
            state.loading = false;
        });

        builder.addCase(fetchEquipmentDetails.pending, (state: any) => {
            state.loading = true;
        });

        //fullfilled
        builder.addCase(fetchEquipmentDetails.fulfilled, (state: any, action: any) => {
            state.equipmentDetails = action.payload;
            state.loading = false;
        });

        //rejected
        builder.addCase(fetchEquipmentDetails.rejected, (state: any, action: any) => {
            state.message = action.payload;
            state.loading = false;
        });


        builder.addCase(fetchInstallReportImages.pending, (state: any) => {
            state.loading = true;
        });

        //fullfilled
        builder.addCase(fetchInstallReportImages.fulfilled, (state: any, action: any) => {
            state.installReportImages = action.payload;
            state.loading = false;
        });

        //rejected
        builder.addCase(fetchInstallReportImages.rejected, (state: any, action: any) => {
            state.message = action.payload;
            state.loading = false;
        });

        builder.addCase(fetchInstallImageGallery.pending, (state: any) => {
            state.loading = true;
        });

        //fullfilled
        builder.addCase(fetchInstallImageGallery.fulfilled, (state: any, action: any) => {
            state.installImageGallery = action.payload;
            state.loading = false;
        });

        //rejected
        builder.addCase(fetchInstallImageGallery.rejected, (state: any, action: any) => {
            state.message = action.payload;
            state.loading = false;
        });

        builder.addCase(fetchPullImageGallery.pending, (state: any) => {
            state.loading = true;
        });

        //fullfilled
        builder.addCase(fetchPullImageGallery.fulfilled, (state: any, action: any) => {
            state.pullImageGallery = action.payload;
            state.loading = false;
        });

        //rejected
        builder.addCase(fetchPullImageGallery.rejected, (state: any, action: any) => {
            state.message = action.payload;
            state.loading = false;
        });

        builder.addCase(fetchPullReportImages.pending, (state: any) => {
            state.loading = true;
        });

        //fullfilled
        builder.addCase(fetchPullReportImages.fulfilled, (state: any, action: any) => {
            state.pullReportImages = action.payload;
            state.loading = false;
        });

        //rejected
        builder.addCase(fetchPullReportImages.rejected, (state: any, action: any) => {
            state.message = action.payload;
            state.loading = false;
        });

        builder.addCase(fetchGeneralReport.pending, (state: any) => {
            state.loading = true;
        });

        //fullfilled
        builder.addCase(fetchGeneralReport.fulfilled, (state: any, action: any) => {
            state.generalReport = action.payload;
            state.loading = false;
        });

        //rejected
        builder.addCase(fetchGeneralReport.rejected, (state: any, action: any) => {
            state.message = action.payload;
            state.loading = false;
        });

        builder.addCase(postImageGallery.pending, (state: any) => {
            state.loading = true;
        });

        //fullfilled
        builder.addCase(postImageGallery.fulfilled, (state: any, action: any) => {
            state.galleryTransactionMsg = {
                key: new Date().getTime(),
                message: action.payload,
                type: 'create-success'
            };
            state.loading = false;
        });

        //rejected
        builder.addCase(postImageGallery.rejected, (state: any, action: any) => {
            state.galleryTransactionMsg = {
                key: new Date().getTime(),
                message: action.payload,
                type: 'create-error'
            };
            state.loading = false;
        });

        builder.addCase(removeGallery.pending, (state: any) => {
            state.loading = true;
        });

        //fullfilled
        builder.addCase(removeGallery.fulfilled, (state: any, action: any) => {
            state.galleryTransactionMsg = {
                key: new Date().getTime(),
                message: action.payload,
                type: 'remove-success'
            };
            state.loading = false;
        });

        //rejected
        builder.addCase(removeGallery.rejected, (state: any, action: any) => {
            state.galleryTransactionMsg = {
                key: new Date().getTime(),
                message: action.payload,
                type: 'remove-error'
            };
            state.loading = false;
        });

        builder.addCase(resetCreateGalleryState, (state: any, action: any) => {
            state.galleryTransactionMsg = null;
        });
    },
});

export const { setSizingSummary, setProduction, setWellInstallation } = DifaPacketBuilderSlice.actions;


export default DifaPacketBuilderSlice.reducer;