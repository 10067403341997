import deleteConfirmationIcon from "./../../../images/delete-confirmation.svg";
import { Dialog, DialogBackdrop, DialogPanel } from "@headlessui/react";


const RemoveConfirmation: React.FC<{ show: boolean, mode?: 'single' | 'gallery', close?: (show: boolean) => void }> = ({
    show = false,
    mode = "gallery",
    close = () => { },
}) => {
    return (
        <Dialog open={show} onClose={() => close(false)} className="relative z-50">
            <DialogBackdrop
                transition
                className="fixed inset-0 backdrop-blur-sm transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
            />
            <DialogBackdrop
                transition
                className="fixed inset-0 backdrop-blur-sm transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
            />

            <div className="fixed inset-0 z-10 w-screen">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <DialogPanel
                        transition
                        className="flex dialog-max-height relative transform overflow-hidden rounded-lg difa-background-with-border text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-sm md:min-w-96 md:w-96  data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                    >
                        <div className="difa-widget-containers flex flex-col">
                            <div className="p-6 w-full flex flex-col h-full pb-8 table-main">
                                <div className="flex flex-col items-center justify-center gap-6">
                                    <img src={deleteConfirmationIcon} alt="delete confimation"></img>
                                    <div>
                                        <h3>Remove {mode === 'gallery' ? 'gallery' : 'Image'}</h3>
                                    </div>
                                    <h5>Are you sure you want to remove this {mode === 'gallery' ? 'gallery' : 'Image'}? </h5>
                                </div>
                            </div>
                            <div className="flex-row flex gap-3 border-top justify-end items-center h-16 pt-8 pb-8">
                                <div className="secondary-button w-2/4 ml-3">
                                    <button className="header-btn-text w-20" onClick={() => close(false)}>Cancel</button>
                                </div>
                                <div className="remove-button-fill w-2/4 mr-3">
                                    <button className="header-btn-text" onClick={() => close(true)}>Remove</button>
                                </div>
                            </div>
                        </div>
                    </DialogPanel>
                </div>
            </div>
        </Dialog>

    );
}

export default RemoveConfirmation;
