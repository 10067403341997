import React, { useEffect } from "react";
import "./PullAndInbound.scss";
import PullReason from "./PullReason";
import InboundTesting from "./InboundTesting";
import { getDismantleRequestByDifa } from "../../../dismentleRequest/DismantleRequestSlice";
import { useDispatch } from "react-redux";

const PullAndInbound: React.FC<{ wellApiNumber: string, difaId: string }> = ({ wellApiNumber, difaId }) => {

  const dispatch = useDispatch();

  useEffect(() => {
    if (wellApiNumber) {
      dispatch(
        getDismantleRequestByDifa({
          dismantleRequestId: difaId,
          wellApiNumber: wellApiNumber,
        })
      );
    }
  }, [dispatch, difaId, wellApiNumber]);

  return (

    <div className="flex-auto flex flex-col w-full gap-5">
      <PullReason />
      <InboundTesting />
    </div>
  );
};

export default PullAndInbound;
