import React, { useState } from 'react'
import { ReactComponent as DownArrow } from "../../../../images/chevron-down.svg";
import { ReactComponent as ArrowUpIcon } from "../../../../images/arrow-narrow-up.svg";
import { ReactComponent as ArrowDownIcon } from "../../../../images/arrow-down.svg";
import { formatDateInNumbers } from "../../../common/services/UtilityService";
import { useDispatch, useSelector } from 'react-redux';
import { fetchServiceReport } from '../../PreInspectionSummarySlice';
import ServiceReport from '../ServiceReport';

const ServiceCalls: React.FC<{ searchQuery: string }> = ({ searchQuery }) => {
  const [showServiceReport, setShowServiceReport] = useState(false);
  const dispatch = useDispatch();
  const { difaRundaysDetails = [], loading } = useSelector(
    (state: any) => state.preInspectionSummary
  );

  const openReport = (wellApiNumber: string) => {
    dispatch(fetchServiceReport({ wellApiNumber: wellApiNumber }));
    setShowServiceReport(true);
  };

  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc");

  const handleSort = () => {
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };

  const sortedDifaTrackings = Array.isArray(difaRundaysDetails)
    ? [...difaRundaysDetails].sort((div, b) => {
      const dateA = new Date(div?.serviceDate).getTime();
      const dateB = new Date(b?.serviceDate).getTime();
      return sortOrder === "asc" ? dateA - dateB : dateB - dateA;
    })
    : [];

  const filteredDifaServices = sortedDifaTrackings.filter((item) =>
    item?.difaId.includes(searchQuery)
  );

  return (
    <div className="table-main relative border border-dark overflow-auto shadow-md sm:rounded-lg max-h-80">
      <table className="w-full text-left">
        <thead className="text-xs font-semibold border-b border-dark top-0 sticky text-neutral-grey">
          <tr>
            <th scope="col" className="px-3 py-4">
              <div className="flex items-center whitespace-nowrap">
                Dispatch #
                <div className="cursor-pointer">
                  <DownArrow className="ms-1.5" />
                </div>
              </div>
            </th>
            <th scope="col" className="px-3 py-4">
              <div className="flex items-center">
                Dispatch reason
                <div className="cursor-pointer">
                  <DownArrow className="ms-1.5" />
                </div>
              </div>
            </th>
            <th scope="col" className="px-3 py-4">
              <div className="flex items-center">
                Start date
                <div className="flex items-center cursor-pointer">
                  {sortOrder === "asc" ? <ArrowDownIcon onClick={handleSort} className="ms-1.5" /> : <ArrowUpIcon onClick={handleSort} className="ms-1.5" />}
                  <DownArrow className="ms-1.5" />
                </div>
              </div>
            </th>
            <th scope="col" className="px-3 py-4">
              <div className="flex items-center">
                Complete date
                <div className="cursor-pointer">
                  <DownArrow className="ms-1.5" />
                </div>
              </div>
            </th>
            <th scope="col" className="px-3 py-4">
              <div className="flex items-center">
                Problem
                <div className="cursor-pointer">
                  <DownArrow className="ms-1.5" />
                </div>
              </div>
            </th>
            <th scope="col" className="px-3 py-4">
              <div className="flex items-center">
                Resolution
                <div className="cursor-pointer">
                  <DownArrow className="ms-1.5" />
                </div>
              </div>
            </th>
          </tr>
        </thead>
        <tbody className="text-sm font-normal text-neutral-grey">
          {loading ? (
            <tr>
              <td colSpan={6} className="text-lg text-center py-5">
                Loading...
              </td>
            </tr>
          ) : filteredDifaServices?.length > 0 ? (
            filteredDifaServices.map((difaRundaysDetail: any, index: number) => (
              <tr key={index} className="border-b border-dark">
                <th scope='row' className="px-3 py-4 difa-id whitespace-nowrap" onClick={() => openReport(difaRundaysDetail?.wellApiNumber)}>{difaRundaysDetail?.difaId}</th>
                <td className="px-3 py-4">{difaRundaysDetail?.pullReason ?? "-"}</td>
                <td className="px-3 py-4">{formatDateInNumbers(difaRundaysDetail?.serviceDate)}</td>
                <td className="px-3 py-4">{difaRundaysDetail?.serviceCompleteDate ? formatDateInNumbers(difaRundaysDetail?.serviceCompleteDate) : "-"}</td>
                <td className="px-3 py-4">{difaRundaysDetail?.serviceProblem}</td>
                <td className="px-3 py-4 w-2/5">{difaRundaysDetail?.serviceResolution ? difaRundaysDetail?.serviceResolution.slice(0, 100) + "...." : "-"}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={6} className="text-lg text-center py-5">
                No data found
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {showServiceReport && (
        <ServiceReport
          open={showServiceReport}
          onCloseModal={setShowServiceReport}
        />
      )}
    </div>
  );
}

export default ServiceCalls
