import { useLocation } from "react-router-dom";
import Header from "./components/Header";
import { useDispatch, useSelector } from "react-redux";
import './difaPacketBuilder.scss';
import { useEffect, useState } from "react";
import { fetchDifaDetails } from "../DifaPages/DifaDetailsSlice";
import WellRunLifeInformation from "./components/WellRunLifeInformation";
import { fetchOptimizationTickets, fetchRundaysDetails } from "../preInspectionSummary/PreInspectionSummarySlice";
import { fetchGeneralReport } from "./difaPacketBuilderSlice";
import EspSizing from "./components/espSizing/EspSizing";
import InstallAndWellOperation from "./components/installWellOperation/InstallAndWellOperation";
import Production from "./components/production/Production";
import PullAndInbound from "./components/pullAndInbound/PullAndInbound";
const PAGE_WELL_RUN_LIFE = 'Well and run life information';
const PAGE_ESP_SIZING = 'ESP sizing';
const PAGE_INSTALL_AND_WELL_OPERATIONS = 'Install and well operations';
const PAGE_PRODUCTION = 'Production';
const PAGE_PULL_AND_INBOUND = 'Pull and inbound';

const DifaPacketBuilder = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const { difaId, customerName, wellName } = location.state || {};
    const { difaDetails } = useSelector((state: any) => state.difaDetails);

    const [currentPage, setCurrentPage] = useState(PAGE_WELL_RUN_LIFE);

    useEffect(() => {
        dispatch(fetchDifaDetails({ difaId }));
    }, [dispatch, difaId]);

    useEffect(() => {
        if (difaDetails?.wellApiNumber !== undefined) {
            dispatch(fetchRundaysDetails({ wellApiNumber: difaDetails?.wellApiNumber }));
            dispatch(fetchGeneralReport({ wellApiNumber: difaDetails?.wellApiNumber, difaId: difaDetails?.difaId }));
            dispatch(fetchOptimizationTickets({ wellName: encodeURIComponent(difaDetails?.wellName) }));
        }
    }, [dispatch, difaDetails]);

    return (
        <div className="packet-builder">
            <Header
                pageName="DIFA packet builder"
                difaPacketName="DIFA packet #12345"
                customerName={customerName}
                wellName={wellName}
                source="builder"
            />
            <div className="flex flex-col md:flex-row gap-5 p-7 h-fit">
                <div className="flex-col flex flex-auto w-full md:w-1/4 gap-4">
                    <div onClick={() => setCurrentPage(PAGE_WELL_RUN_LIFE)} className={`difa-widget-containers cursor-pointer ${currentPage === PAGE_WELL_RUN_LIFE ? 'active-page' : ''}`}>
                        <div className="container-body"><div className="header-small">{PAGE_WELL_RUN_LIFE}</div>
                        </div>
                    </div>
                    <div onClick={() => setCurrentPage(PAGE_ESP_SIZING)} className={`difa-widget-containers cursor-pointer ${currentPage === PAGE_ESP_SIZING ? 'active-page' : ''}`}>
                        <div className="container-body"><div className="header-small">{PAGE_ESP_SIZING}</div>
                        </div>
                    </div>
                    <div onClick={() => setCurrentPage(PAGE_INSTALL_AND_WELL_OPERATIONS)} className={`difa-widget-containers cursor-pointer ${currentPage === PAGE_INSTALL_AND_WELL_OPERATIONS ? 'active-page' : ''}`}>
                        <div className="container-body"><div className="header-small">{PAGE_INSTALL_AND_WELL_OPERATIONS}</div>
                        </div>
                    </div>
                    <div onClick={() => setCurrentPage(PAGE_PRODUCTION)} className={`difa-widget-containers cursor-pointer ${currentPage === PAGE_PRODUCTION ? 'active-page' : ''}`}>
                        <div className="container-body"><div className="header-small">{PAGE_PRODUCTION}</div>
                        </div>
                    </div>
                    <div onClick={() => setCurrentPage(PAGE_PULL_AND_INBOUND)} className={`difa-widget-containers cursor-pointer ${currentPage === PAGE_PULL_AND_INBOUND ? 'active-page' : ''}`}>
                        <div className="container-body"><div className="header-small">{PAGE_PULL_AND_INBOUND}</div>
                        </div>
                    </div>
                </div>
                <div className="flex-auto flex flex-col w-full md:w-3/4 gap-5">
                    {currentPage === PAGE_WELL_RUN_LIFE && <WellRunLifeInformation difaId={difaId} />}
                    {currentPage === PAGE_ESP_SIZING && <EspSizing wellApiNumber={difaDetails?.wellApiNumber} source=""/>}
                    {currentPage === PAGE_INSTALL_AND_WELL_OPERATIONS && <InstallAndWellOperation wellApiNumber={difaDetails?.wellApiNumber} difaId={difaDetails?.difaId} source=""/>}
                    {currentPage === PAGE_PRODUCTION && <Production source=""/>}
                    {currentPage === PAGE_PULL_AND_INBOUND && <PullAndInbound wellApiNumber={difaDetails?.wellApiNumber} difaId={difaDetails?.difaId}/>}
                </div>
            </div>
        </div>
    )
}

export default DifaPacketBuilder;