import AlarmEvents from "./AlarmEvents";
import DifaSwitch from "../../../common/switch/DifaSwitch";
import GalleryComponent from "../../../common/gallery/Gallery";
import { useEffect, useState } from "react";
import OperationalSummary from "./OperationalSummary";
import DPBDocumentation from "./DPBDocumentation";
import MainComponent from "../../../preInspectionSummary/components/InstallReport";
import { fetchInstallReport } from "../../../preInspectionSummary/PreInspectionSummarySlice";
import { ToastContainer, toast } from 'react-toastify';
import EditGalleryComponent from "../../../common/gallery/EditGallery";
import { useDispatch, useSelector } from "react-redux";
import { fetchInstallImageGallery, fetchInstallReportImages, removeGallery, resetCreateGalleryState } from "../../difaPacketBuilderSlice";
import Loader from "../../../common/loader/Loader";

const InstallAndWellOperation: React.FC<{
    wellApiNumber: string;
    difaId: string;
    source: string;
}> = ({ wellApiNumber, difaId, source }) => {
    const { wellInstallation } = useSelector(
        (state: any) => state.difaPacketBuilder
    );
    const [showGallery, setShowGallery] = useState(false);
    const [showEditGallery, setShowEditGallery] = useState(false);
    const [showReport, setShowReport] = useState(false);
    const [activeGalleryItem, setActiveGalleryItem] = useState(null);

    const dispatch = useDispatch();

    const { installReportImages, installImageGallery, loading, galleryTransactionMsg, message } = useSelector(
        (state: any) => state?.difaPacketBuilder
    );

    useEffect(() => {
        dispatch(fetchInstallReportImages({ wellApiNumber, difaId }));
    }, [showGallery]);

    useEffect(() => {
        dispatch(fetchInstallImageGallery({ wellApiNumber, difaId }));
    }, []);

    useEffect(() => {
        if (galleryTransactionMsg && galleryTransactionMsg.type === 'create-success') {
            dispatch(fetchInstallImageGallery({ wellApiNumber, difaId }));
            toast.success(<div className="flex flex-col">
                <div className="text-lg">
                    Gallery added
                </div>
                <div className="text-sm">
                    To see your images select the gallery.
                </div>
            </div>)
            dispatch(resetCreateGalleryState());
        } else if (galleryTransactionMsg && galleryTransactionMsg.type === 'create-error') {
            toast.error(<div className="flex flex-col">
                <div className="text-lg">
                    Opps!!
                </div>
                <div className="text-sm">
                    The gallery name already exists. Please choose a different name.
                </div>
            </div>)
            dispatch(resetCreateGalleryState());
        }
        else if (galleryTransactionMsg && galleryTransactionMsg.type === 'remove-success') {
            dispatch(fetchInstallImageGallery({ wellApiNumber, difaId }));
            toast.success(<div className="flex flex-col">
                <div className="text-lg">
                    Gallery removed
                </div>
                <div className="text-sm">
                    To see your images select the gallery.
                </div>
            </div>)
            dispatch(resetCreateGalleryState());
        }
    }, [galleryTransactionMsg]);

    const handleRemove = (galleryId: string) => {
        dispatch(removeGallery(galleryId));
    }

    const isPreview = source === "preview-packet";
    const openReport = () => {
        if (!showReport) {
            dispatch(fetchInstallReport({ wellApiNumber: wellApiNumber }));
            setShowReport(!showReport);
        }
    };
    return (
        <div className="flex-auto flex flex-col w-full gap-5">
            <ToastContainer position='top-right' autoClose={3000} />
            {loading && !showGallery && <Loader isOverlay />}
            {isPreview ? (
                <>
                    {wellInstallation?.isAlarmsSelected && <AlarmEvents wellApiNumber={wellApiNumber} source={source} />}
                    <div className="difa-widget-containers">
                        {showReport && (
                            <MainComponent open={showReport} onCloseModal={setShowReport} />
                        )}
                        <div className="header-main">
                            <div className="flex flex-row justify-between flex-wrap">
                                <div>
                                    <h3>Install image gallery</h3>
                                    <h5>Photo evidence taken during installation</h5>
                                </div>
                                <div className="flex flex-row items-center gap-3">
                                    <button
                                        className="btn-secondary font-bold text-sm"
                                        onClick={() => openReport()}
                                    >
                                        View install report
                                    </button>
                                </div>
                            </div>
                        </div>
                        {installImageGallery && !installImageGallery.length ? (
                            <div className="flex flex-col gap-5 p-6 border-t border-dark">
                                <div className="flex p-6 border border-dark rounded-lg items-center justify-center bg-dark-800">
                                    <p className="font-normal text-sm text-center py-6">
                                        No images have been added. To add trend images select the
                                        “Create trend gallery” button.
                                    </p>
                                </div>
                            </div>
                        ) : (
                            <div className="flex flex-row gap-5 p-6 border-t border-dark cursor-pointer rounded-md flex-wrap ">
                                {
                                    installImageGallery && installImageGallery.map((gl: any, index: number) => (
                                        <div
                                            className="flex flex-col gap-3 w-72 max-w-72"
                                            onClick={() => {
                                                setShowEditGallery(true);
                                                setActiveGalleryItem(gl);
                                            }}>
                                            <div
                                                className={`difa-widget-containers  difa-background relative max-w-72 h-64 p-2`}>
                                                <div className="w-full h-full flex items-center flex-col">
                                                    <img
                                                        className="h-48 w-full"
                                                        src={gl.reportImageDetails[0].fileLink}
                                                        alt={"gallery"}
                                                    ></img>
                                                    <div className="flex justify-between w-full items-center p-2">
                                                        <h2> {gl.galleryName}</h2>
                                                        <h5>{gl && gl.reportImageDetails.length} images</h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))

                                }
                            </div>
                        )}
                    </div>
                    {showGallery && <GalleryComponent difaID={difaId} reportType="install" show={showGallery} images={installReportImages} loading={loading} onGalleryClose={() => setShowGallery(false)} wellApiNumber={wellApiNumber} />}
                    {showEditGallery && <EditGalleryComponent show={showEditGallery}
                        galleryDetails={activeGalleryItem}
                        onRemoveGallery={handleRemove}
                        onGalleryClose={() => {
                            setShowEditGallery(false);
                            setActiveGalleryItem(null);
                        }} wellApiNumber={wellApiNumber} source={source} />}

                    {wellInstallation?.isDocumentSelected &&<DPBDocumentation wellApiNumber={wellApiNumber} source={source} />}
                </>
            ) : (
                <>
                    <DPBDocumentation wellApiNumber={wellApiNumber} source="" />
                    <div className="difa-widget-containers">
                        <div className="header-main">
                            <div className="flex flex-row justify-between flex-wrap">
                                <div>
                                    <h3>Install image gallery</h3>
                                    <h5>
                                        Upload, edit, and annotate images of installation photos.
                                    </h5>
                                </div>
                                <div className="flex flex-row items-center gap-3">
                                    <DifaSwitch onChange={() => { }} />
                                    <h4 className="whitespace-nowrap">Include in report</h4>
                                    <button
                                        className="btn-secondary font-bold text-sm"
                                        onClick={() => setShowGallery(true)}
                                    >
                                        Add image gallery
                                    </button>
                                </div>
                            </div>
                        </div>
                        {!installImageGallery || !installImageGallery.length ? (
                            <div className="flex flex-col gap-5 p-6 border-t border-dark">
                                <div className="flex p-6 border border-dark rounded-lg items-center justify-center bg-dark-800">
                                    <p className="font-normal text-sm text-center py-6">
                                        No images have been added. To add trend images select the
                                        “Create trend gallery” button.
                                    </p>
                                </div>
                            </div>
                        ) : (
                            <div className="flex flex-row gap-5 p-6 border-t border-dark cursor-pointer rounded-md flex-wrap">
                                {
                                    installImageGallery && installImageGallery.map((gl: any, index: number) => (
                                        <div
                                            className="flex flex-col gap-3  w-72 max-w-72 "
                                            onClick={() => {
                                                setShowEditGallery(true);
                                                setActiveGalleryItem(gl);
                                            }}>
                                            <div
                                                className={`difa-widget-containers  difa-background relative max-w-72 h-64 p-2`}>
                                                <div className="w-full h-full flex items-center flex-col">
                                                    {
                                                        gl.reportImageDetails && gl.reportImageDetails.length
                                                        &&
                                                        <img
                                                            className="h-48 w-full"
                                                            src={gl.reportImageDetails[0].fileLink}
                                                            alt={"gallery"}
                                                        ></img>
                                                    }
                                                    <div className="flex justify-between w-full items-center p-2">
                                                        <h2> {gl.galleryName}</h2>
                                                        <h5>{gl && gl.reportImageDetails.length} images</h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))

                                }
                            </div>
                        )}
                    </div>
                    {showGallery && <GalleryComponent difaID={difaId} reportType="install" show={showGallery} images={installReportImages} loading={loading} onGalleryClose={() => setShowGallery(false)} wellApiNumber={wellApiNumber} />}
                    {showEditGallery && (
                        <EditGalleryComponent
                            show={showEditGallery}
                            galleryDetails={activeGalleryItem}
                            onRemoveGallery={handleRemove}
                            onGalleryClose={() => {
                                setShowEditGallery(false);
                                setActiveGalleryItem(null);
                            }}
                            wellApiNumber={wellApiNumber}
                            source={source}
                        />
                    )}
                    <AlarmEvents wellApiNumber={wellApiNumber} source="" />
                    <OperationalSummary />
                </>
            )
            }
        </div >
    );
};

export default InstallAndWellOperation;
