import React, { useEffect, useState } from "react";

import "./InstallReport.scss";
import "./SectionsReport.scss";
import closeSlider from "../../../images/close-slider.svg";
import downloadReport from "../../../images/download-report.svg";
import sectionExpand from "../../../images/chevron-down.svg";
import sectionCollapse from "../../../images/chevron-up.svg";
import { useSelector } from "react-redux";
import Helpers from "../../../utilities/Helpers";
import Loader from "../../common/loader/Loader";
import { sentenceCase } from "../../common/services/UtilityService";

interface Toggle {
  open: boolean;
  onCloseModal: any;
}

const AttributeCard: React.FC<any> = ({
  installDate,
  serviceDate,
  serviceTech,
  startupDate,
  modemIP,
  customer,
  wellName,
  wellAPI,
  field,
  county,
  state,
}) => {
  return (
    <div className="attribute-card">
      <h2 className="default-attribute-header">Job specific information</h2>
      <div className="attribute-row">
        <span className="label">Service tech</span>
        <span className="value">{serviceTech}</span>
      </div>
      
      <div className="attribute-row">
        <span className="label">Install date</span>
        <span className="value">{Helpers.formatDate(installDate)}</span>
      </div>
      <div className="attribute-row">
        <span className="label">Service date</span>
        <span className="value">{Helpers.formatDate(serviceDate)}</span>
      </div>
      <div className="attribute-row">
        <span className="label">Modem IP address</span>
        <span className="value">{modemIP}</span>
      </div>
      <div className="attribute-row">
        <span className="label">Customer</span>
        <span className="value">{customer}</span>
      </div>
      <div className="attribute-row">
        <span className="label">Well name</span>
        <span className="value">{wellName}</span>
      </div>
      <div className="attribute-row">
        <span className="label">Well API #</span>
        <span className="value">{wellAPI}</span>
      </div>
      <div className="attribute-row">
        <span className="label">Field</span>
        <span className="value">{Helpers.isFieldEmpty(field) ? "-" : sentenceCase(field)}</span>
      </div>
      <div className="attribute-row">
        <span className="label">County</span>
        <span className="value">{Helpers.isFieldEmpty(county) ? "-" : sentenceCase(county)}</span>
      </div>
      <div className="attribute-row">
        <span className="label">State</span>
        <span className="value">{Helpers.isFieldEmpty(state) ? "-" : sentenceCase(state)}</span>
      </div>
    </div>
  );
};

const ESPDetails: React.FC = () => {
  const { serviceReport } = useSelector(
    (state: any) => state.preInspectionSummary
  );
  const details: Record<string, string> = {
    'Controller S/N': Helpers.markNullValues(serviceReport?.equipmentinfoSettingsDto.controllerSN), // Not found in JSON
    "Controller model": Helpers.markNullValues(serviceReport?.equipmentinfoSettingsDto.controllerModel),
    "Max output Amps": Helpers.markNullValues(serviceReport?.equipmentinfoSettingsDto.maxOutputAmps), // Not found in JSON
    "kVa rating": Helpers.markNullValues(serviceReport?.equipmentinfoSettingsDto.drive), // Not found in JSON
    "Xformer S/N": Helpers.markNullValues(serviceReport?.equipmentinfoSettingsDto.xformerSN),
    "Xformer kVa": Helpers.markNullValues(serviceReport?.equipmentinfoSettingsDto.xformerKVA),
    "Motor HP": Helpers.markNullValues(serviceReport?.equipmentinfoSettingsDto.motorHP),
    "Motor Volts": Helpers.markNullValues(serviceReport?.equipmentinfoSettingsDto.motorVolts),
    "Motor Amps": Helpers.markNullValues(serviceReport?.equipmentinfoSettingsDto.motorAmps),
    "Cable size": Helpers.markNullValues(serviceReport?.equipmentinfoSettingsDto.cableSize), // Not found in JSON
    "Cable length": Helpers.markNullValues(serviceReport?.equipmentinfoSettingsDto.cableLength),
    "Downhole temperature": Helpers.isFieldEmpty(serviceReport?.equipmentinfoSettingsDto.downholetemp) ? '-' 
    : Helpers.markNullValues(serviceReport?.equipmentinfoSettingsDto.downholetemp) + '° F',
    "Min Hz": Helpers.markNullValues(serviceReport?.equipmentinfoSettingsDto.minHz),
    "Max Hz": Helpers.markNullValues(serviceReport?.equipmentinfoSettingsDto.maxHZ),
    "Harmonic filter S/N": Helpers.markNullValues(serviceReport?.equipmentinfoSettingsDto.harmonicFilterSN),
    "Xformer secondary voltage chosen":
    Helpers.markNullValues(serviceReport?.equipmentinfoSettingsDto.xformerSecondaryVoltageChosen),
    "Connection type": Helpers.markNullValues(serviceReport?.equipmentinfoSettingsDto.connectiontype),
    "Tap switch 1": Helpers.markNullValues(serviceReport?.equipmentinfoSettingsDto.tapSwtch1),
    "Tap switch 2": Helpers.markNullValues(serviceReport?.equipmentinfoSettingsDto.tapSwtch2),
  };

  return (
    <div className="attribute-card">
      <h2 className="default-attribute-header">
        Equipment information and settings
      </h2>
      <div>
        {Object.entries(details).map(([label, value], index) => (
          <div key={index} style={styles.row}>
            <span>{label}</span>
            <span>{value}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

const headerStyle = {
  color: "#F7F9F9",
  fontSize: "12px",
  fontFamily: "Mulish",
  fontWeight: "400",
  lineHeight: "18px",
  padding: "9px 12px",
  borderRight: "1px #4A5463 solid",
  borderBottom: "1px #4A5463 solid",
};

const cellStyle = {
  fontWeight: 400,
  fontSize: "12px",
  lineHeight: "20px",
  fontFamily: "Mulish, sans-serif",
  borderRight: "1px #4A5463 solid",
  color: "#f7f9f9",
  padding: "9px 12px",
};
const Section: React.FC<{ title: string }> = ({ title }) => {
  const { serviceReport } = useSelector(
    (state: any) => state.preInspectionSummary
  );
  const [isExpanded, setIsExpanded] = useState(false);
  const tblOperating = {
    "Set frequency": Helpers.markNullValues(serviceReport?.setupOperatingParametersDto?.setFrequency),
    "Max frequency": Helpers.markNullValues(serviceReport.setupOperatingParametersDto?.maxFrequency),
    "Min frequency": Helpers.markNullValues(serviceReport.setupOperatingParametersDto?.minFrequency),
    "Start current limit": Helpers.markNullValues(serviceReport.setupOperatingParametersDto?.startCurrentLimit),
    "Run current limit": Helpers.markNullValues(serviceReport.setupOperatingParametersDto?.runCurrentLimit),
    "Accel time": Helpers.markNullValues(serviceReport.setupOperatingParametersDto?.accelTime) + ' seconds',
    "Decel time": Helpers.markNullValues(serviceReport.setupOperatingParametersDto?.decelTime) + ' seconds',
    "Starting mode": Helpers.markNullValues(serviceReport.setupOperatingParametersDto?.startingMode),
    "Single phase volts": Helpers.markNullValues(serviceReport.setupOperatingParametersDto?.singlePhaseVolts),
    "Operating Amps" : Helpers.markNullValues(serviceReport.setupOperatingParametersDto?.operatingAmps),
  };

  const tblOperatingSub1 = {
    "Overload set point": Helpers.markNullValues(serviceReport?.setupOperatingParametersDto?.overloadSetPoint),
    "OL fault delay": Helpers.processTimeUnits(serviceReport.setupOperatingParametersDto?.olFaultDelay),
    "Restarts on OL": Helpers.markNullValues(serviceReport.setupOperatingParametersDto?.restartsOnOL),
    "Restart delay on OL": Helpers.processTimeUnits(serviceReport.setupOperatingParametersDto?.restartDelayOnOL),
    "Underload set point": Helpers.markNullValues(serviceReport.setupOperatingParametersDto?.underloadSetPoint),
    "Underload fault delay": Helpers.processTimeUnits(serviceReport.setupOperatingParametersDto?.ulFaultDelay),
    "Restarts on underload": Helpers.markNullValues(serviceReport.setupOperatingParametersDto?.restartsOnUL),
    "Restart delay on underload": Helpers.processTimeUnits(serviceReport.setupOperatingParametersDto?.restartDelayOnUL),
    "Low frequency fault delay": Helpers.markNullValues(serviceReport.setupOperatingParametersDto?.lowFreqFaultDelay),
    "Low frequency enabled": Helpers.isFieldEmpty(serviceReport.setupOperatingParametersDto?.lowFreqEnabled) ? 
    Helpers.markNullValues(serviceReport.setupOperatingParametersDto?.lowFreqEnabled) :Helpers.formatYesNo(serviceReport.setupOperatingParametersDto?.lowFreqEnabled),
    "Low frequency disabled": Helpers.isFieldEmpty(serviceReport.setupOperatingParametersDto?.lowFreqDisabled) ? 
    Helpers.markNullValues(serviceReport.setupOperatingParametersDto?.lowFreqDisabled) :
     Helpers.formatYesNo(serviceReport.setupOperatingParametersDto?.lowFreqDisabled)
  };
  const tblOperatingSub2 = {
    "High motor temp set point":
    Helpers.markNullValues(serviceReport?.setupOperatingParametersDto?.highMtrTempSetPnt),
    "Motor temp fault delay": Helpers.processTimeUnits(serviceReport.setupOperatingParametersDto?.mtrTempFaultDelay),
    "Motor temp restart": Helpers.markNullValues(serviceReport.setupOperatingParametersDto?.mtrTempRestart),
    "Motor temp restart delay":
    Helpers.processTimeUnits(serviceReport.setupOperatingParametersDto?.mtrTempRestartDelay),
    "Motor temp fault enabled":
    Helpers.formatYesNo(serviceReport.setupOperatingParametersDto?.mtrTempFaultEnabled),
    "Motor temp fault disabled":
    Helpers.formatYesNo(serviceReport.setupOperatingParametersDto?.mtrTempFaultDisabled),
    "PID enabled/disabled": 
    serviceReport.setupOperatingParametersDto?.pidEnabledDisabled === 'X' ? 'Disabled' : 'Enabled',
    "PID PSI set point": Helpers.markNullValues(serviceReport.setupOperatingParametersDto?.pidpsiSetPoint),
    "PID low level shutdown": serviceReport.setupOperatingParametersDto?.pidLowLevelShtdwn === 'X' ? 'Disabled' : 'Enabled',
    
    "PID gain": Helpers.markNullValues(serviceReport.setupOperatingParametersDto?.pidGain),
    "PID I-time": Helpers.markNullValues(serviceReport.setupOperatingParametersDto?.pidiTime)  ,
  };
  const tblStartupReading1 = {
    "Tubing PSI at start": Helpers.isFieldEmpty(serviceReport?.startupReadingsHeaderDto?.tubingPSIStart) 
    ? Helpers.markNullValues(serviceReport?.startupReadingsHeaderDto?.tubingPSIStart)
    : serviceReport?.startupReadingsHeaderDto?.tubingPSIStart +' PSI',
    "Tubing PSI at 1 hour": Helpers.isFieldEmpty(serviceReport?.startupReadingsHeaderDto?.tubingPSI1Hr) 
    ? Helpers.markNullValues(serviceReport?.startupReadingsHeaderDto?.tubingPSI1Hr)
    : serviceReport?.startupReadingsHeaderDto?.tubingPSI1Hr +' PSI',

    "Casing PSI at start": Helpers.isFieldEmpty(serviceReport?.startupReadingsHeaderDto?.casingPSIStart) 
    ? Helpers.markNullValues(serviceReport?.startupReadingsHeaderDto?.casingPSIStart)
    : serviceReport?.startupReadingsHeaderDto?.casingPSIStart +' PSI',
    "Casing PSI at 1 hour": Helpers.isFieldEmpty(serviceReport?.startupReadingsHeaderDto?.casingPSI1HR) 
    ? Helpers.markNullValues(serviceReport?.startupReadingsHeaderDto?.casingPSI1HR)
    : serviceReport?.startupReadingsHeaderDto?.casingPSI1HR +' PSI',
    "Motor temp at start": Helpers.isFieldEmpty(serviceReport?.startupReadingsHeaderDto?.motorTempStart) 
    ? Helpers.markNullValues(serviceReport?.startupReadingsHeaderDto?.motorTempStart)
    : serviceReport?.startupReadingsHeaderDto?.motorTempStart +' PSI',
    "Motor temp at 1 hour": Helpers.isFieldEmpty(serviceReport?.startupReadingsHeaderDto?.motorTemp1hr) 
    ? Helpers.markNullValues(serviceReport?.startupReadingsHeaderDto?.motorTemp1hr)
    : serviceReport?.startupReadingsHeaderDto?.motorTemp1hr +' PSI',
    "Static intake at start": Helpers.isFieldEmpty(serviceReport?.startupReadingsHeaderDto?.staticIntakeStart) 
    ? Helpers.markNullValues(serviceReport?.startupReadingsHeaderDto?.staticIntakeStart)
    : serviceReport?.startupReadingsHeaderDto?.staticIntakeStart +' PSI',

    "Intake PSI at 1 hour": Helpers.isFieldEmpty(serviceReport?.startupReadingsHeaderDto?.intakePsi1hr) 
    ? Helpers.markNullValues(serviceReport?.startupReadingsHeaderDto?.intakePsi1hr)
    : serviceReport?.startupReadingsHeaderDto?.intakePsi1hr +' PSI',

    "Flow rate at start": Helpers.markNullValues(serviceReport.startupReadingsHeaderDto?.flowRateStart),
    "Flow rate at 1 hour": Helpers.markNullValues(serviceReport.startupReadingsHeaderDto?.flowRate1hr),
    "Pump up time": Helpers.processTimeUnits(serviceReport.startupReadingsHeaderDto?.pumpUpTime),
  };

  const tblStartupReading2 = {
    "Trending parameters established at start up (as per tech alert VSD best practice VFD trending)":
    Helpers.isFieldEmpty(serviceReport?.trendingParametersEst) ? '-' : 
    Helpers.formatYesNo(serviceReport?.trendingParametersEst),
    "Tubing PSI at 1 hour SMARTEN VSD dec. ramp adapt set to NO position":
    Helpers.isFieldEmpty(serviceReport?.smartenVsdnoposition) ? '-' : 
    Helpers.formatYesNo(serviceReport?.smartenVsdnoposition),   
    
    "Customer permissive to fault the drive": 
    Helpers.isFieldEmpty(serviceReport?.customerPermtoFlt) ? '-' : 
    Helpers.formatYesNo(serviceReport?.customerPermtoFlt),
    
    
    "Tested customer permissive to fault the drive":
    Helpers.isFieldEmpty(serviceReport?.testedCustomerPermtoFlt) ? '-' : 
    Helpers.formatYesNo(serviceReport?.testedCustomerPermtoFlt),
    
  };

  const tblReadingsHeaders1 = [
    {
      headers: ["", "Hz", "A/B", "A/C", "B/C"],
    },
  ];
  const tblReadingsHeaders2 = [
    {
      headers: ["", "Hz", "A / ground", "B / ground", "C / ground"],
    },
  ];

  const tblReadingsHeaders3 = [
    {
      headers: ["", "Hz", "A", "B", "C"],
    },
  ];

  const metaDataReadings1 = ["DriveOutputVoltsǾtoǾ", "MotorDownholeVoltsǾtoǾ"];

  const enumStartupDetailType: any = {
    DriveOutputVoltsǾtoǾ: "Drive output volts Ǿ to Ǿ",
    MotorDownholeVoltsǾtoǾ: "Motor / downhole volts Ǿ to Ǿ",
    DriveOutputVoltsǾtogrnd: "Drive output volts Ǿ to Ǿ to ground",
    MotorDownholeVoltsǾtogrnd: "Motor / downhole volts Ǿ to Ǿ to ground",
    DriveOutputCurrent: "Drive output current",
    MotorDownholeCurrent: "Motor / downhole current",
    MotorDownholeCurrent_Onehr: "Motor / downhole current at 1 hour",
  };
  const metaDataReadings2 = [
    "DriveOutputVoltsǾtogrnd",
    "MotorDownholeVoltsǾtogrnd",
  ];
  const metaDataReadings3 = [
    "DriveOutputCurrent",
    "MotorDownholeCurrent",
    "MotorDownholeCurrent_Onehr",
  ];

  const enumDataNoloadType: any = {
    MotorCableǾtoǾ_Resistance: "Motor / cable Ǿ to Ǿ resistance",
    DriveOutputVoltsǾtoǾ: "Drive output volts Ǿ to Ǿ",
    TransformerSecondaryVoltsǾtoǾ: "Transformer secondary volts Ǿ to Ǿ",
    MotorCableǾtoGroundResistance: "Motor / cable Ǿ to ground resistance",
    DriveOutputVoltsǾtogrnd: "Drive output volts Ǿ to ground",
    TransformerSecondaryVoltsǾtogrnd: "Transformer secondary volts Ǿ to ground",
    DCBusVoltage: "DS bus voltage",
  };
  const metaDataNoload1 = ["MotorCableǾtoǾ_Resistance",'DriveOutputVoltsǾtoǾ', "TransformerSecondaryVoltsǾtoǾ"];
  const metaDataNoload2 = [
    "MotorCableǾtoGroundResistance",
    'DriveOutputVoltsǾtogrnd',
    "TransformerSecondaryVoltsǾtogrnd",
  ];
  const metaDataNoload3 = [
    "DCBusVoltage",
  ];

  const tblProblemsReorted = [
    {
      headers: ["Problem reported"],
      data: [Helpers.markNullValues(serviceReport?.problemsReported)],
    },
    {
      headers: ["Date", " Changes made", "Customer representative"],
      data: [
        Helpers.markNullValues(serviceReport?.changesMadeDate),
        Helpers.markNullValues(serviceReport?.changesMade),
        Helpers.markNullValues(serviceReport?.changesMadeCustomerRep),
      ],
    },
  ];
  const tblProbreportedExtra = {
    "Condemned well ": Helpers.isFieldEmpty(serviceReport?.condemnedWell) 
    ? Helpers.markNullValues(serviceReport?.condemnedWell)
    : serviceReport?.condemnedWell ? 'Yes' : 'No',
  }

  const tblBrakeHP = {
    'Series': Helpers.markNullValues(serviceReport?.brakeHpdataDto?.series),
    "Stage type": Helpers.markNullValues(serviceReport?.brakeHpdataDto?.stageType),
    "HP per stage": Helpers.markNullValues(serviceReport.brakeHpdataDto?.hpPerStage),
    "Stage count": Helpers.markNullValues(serviceReport.brakeHpdataDto?.stageCount),
    "Required HP": Helpers.markNullValues(serviceReport.brakeHpdataDto?.requiredHP),
    "50 Hz": Helpers.markNullValues(serviceReport.brakeHpdataDto?.frequency50Hz),
    "55 Hz": Helpers.markNullValues(serviceReport.brakeHpdataDto?.frequency55Hz),
    "60 Hz": Helpers.markNullValues(serviceReport.brakeHpdataDto?.frequency60Hz),
    "65 Hz": Helpers.markNullValues(serviceReport.brakeHpdataDto?.frequency65Hz),
    "70 Hz": Helpers.markNullValues(serviceReport.brakeHpdataDto?.frequency70Hz),
    "75 Hz": Helpers.markNullValues(serviceReport.brakeHpdataDto?.frequency75Hz),
  };

  return (
    <div className="section">
      <div
        className="section-header"
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <span>{title}</span>
        <img src={isExpanded ? sectionCollapse : sectionExpand} alt="chevron" />
      </div>

      {isExpanded && title === "Setup / Operating parameters" ? (
        <>
          <div className="section-open-section-content" key={title}>
            <div className="subsection-styling pd15">
              {Object.entries(tblOperating).map(([label, value], index) => (
                <div key={index} style={styles.row}>
                  <span>{label}</span>
                  <span>{value}</span>
                </div>
              ))}
            </div>
          </div>
          <div className="display-flex">
            <div
              className="section-open-section-content sub-section"
              key={title}
            >
              <div className="subsection-styling pd15">
                {Object.entries(tblOperatingSub1).map(
                  ([label, value], index) => (
                    <div key={index} style={styles.row}>
                      <span>{label}</span>
                      <span>{value}</span>
                    </div>
                  )
                )}
              </div>
            </div>
            <div
              className="section-open-section-content sub-section"
              key={title}
            >
              <div className="subsection-styling pd15">
                {Object.entries(tblOperatingSub2).map(
                  ([label, value], index) => (
                    <div key={index} style={styles.row}>
                      <span>{label}</span>
                      <span>{value}</span>
                    </div>
                  )
                )}
              </div>
            </div>
          </div>
        </>
      ) : isExpanded && title === "Start up readings" ? (
        <>
          <div className="section-open-section-content" key={title}>
            <div className="subsection-styling pd15">
              {Object.entries(tblStartupReading1).map(
                ([label, value], index) => (
                  <div key={index} style={styles.row}>
                    <span>{label}</span>
                    <span>{value}</span>
                  </div>
                )
              )}
            </div>
            <div className="subsection-styling margin-top-bottom">
              {tblReadingsHeaders1.map((set, index) => (
                <div key={index} className="bb">
                  <div className="flex-display tblstartupReading">
                    {set.headers.map((header, i) => (
                      <div key={i} className="headerStyle">
                        {header}
                      </div>
                    ))}
                  </div>
                </div>
              ))}
              {serviceReport?.startupReadingsDetailDto
                ?.filter((item: any) => {
                  return metaDataReadings1.includes(item.startupDetailType);
                })
                .map((datum: any, i: any) => (
                  <div className="sequence-rows">
                    <div className="sequence-cells">
                      {enumStartupDetailType[datum.startupDetailType]}
                    </div>
                    <div className="sequence-cells">{Helpers.isFieldEmpty(datum?.hz_value) ? 
                      '-' : datum?.hz_value + " Hz"}</div>
                    <div className="sequence-cells">{Helpers.isFieldEmpty(datum?.a_B_value) ? 
                      '-' :datum?.a_B_value + " V"}</div>
                    <div className="sequence-cells">
                      {Helpers.isFieldEmpty(datum?.a_C_value) ? 
                      '-' :datum?.a_C_value + " V"}
                    </div>
                    <div className="sequence-cells">{Helpers.isFieldEmpty(datum?.b_C_value) ? 
                      '-' :datum?.b_C_value + " V"}</div>
                  </div>
                ))}
            </div>

            <div className="subsection-styling margin-top-bottom">
              {tblReadingsHeaders2.map((set, index) => (
                <div key={index} className="bb">
                  <div className="flex-display tblstartupReading" >
                    {set.headers.map((header, i) => (
                      <div key={i} className="headerStyle">
                        {header}
                      </div>
                    ))}
                  </div>
                </div>
              ))}
              {serviceReport?.startupReadingsDetailDto
                ?.filter((item: any) => {
                  return metaDataReadings2.includes(item.startupDetailType);
                })
                .map((datum: any, i: any) => (
                  <div className="sequence-rows">
                    <div className="sequence-cells">
                      {enumStartupDetailType[datum.startupDetailType]}
                    </div>
                    <div className="sequence-cells">{Helpers.isFieldEmpty(datum?.hz_value) ? 
                      '-' :datum?.hz_value + " Hz"}</div>
                    <div className="sequence-cells">{Helpers.isFieldEmpty(datum?.a_Grnd_value) ? 
                      '-' :datum?.a_Grnd_value + " V"}</div>
                    <div className="sequence-cells">{Helpers.isFieldEmpty(datum?.b_Grnd_value) ? 
                      '-' :datum?.b_Grnd_value + " V"}</div>
                    <div className="sequence-cells">{Helpers.isFieldEmpty(datum?.c_Grnd_value) ? 
                      '-' :datum?.c_Grnd_value + " V"}</div>
                  </div>
                ))}
            </div>
            <div className="subsection-styling margin-top-bottom">
              {tblReadingsHeaders3.map((set, index) => (
                <div key={index} className="bb">
                  <div className="flex-display tblstartupReading">
                    {set.headers.map((header, i) => (
                      <div key={i} className="headerStyle">
                        {header}
                      </div>
                    ))}
                  </div>
                </div>
              ))}
              {serviceReport?.startupReadingsDetailDto
                ?.filter((item: any) => {
                  return metaDataReadings3.includes(item.startupDetailType);
                })
                .map((datum: any, i: any) => (
                  <div className="sequence-rows">
                    <div className="sequence-cells">
                      {enumStartupDetailType[datum.startupDetailType]}
                    </div>
                    <div className="sequence-cells">{Helpers.isFieldEmpty(datum?.hz_value) ? 
                      '-' :datum?.hz_value + " Hz"}</div>
                    <div className="sequence-cells">{Helpers.isFieldEmpty(datum?.a_value) ? 
                      '-' :datum?.a_value + " A"}</div>
                    <div className="sequence-cells">{Helpers.isFieldEmpty(datum?.b_value) ? 
                      '-' :datum?.b_value + " A"}</div>
                    <div className="sequence-cells">{Helpers.isFieldEmpty(datum?.c_value) ? 
                      '-' :datum?.c_value + " A"}</div>
                  </div>
                ))}
            </div>

            <div className="subsection-styling pd15">
              {Object.entries(tblStartupReading2).map(
                ([label, value], index) => (
                  <div key={index} style={styles.row}>
                    <span>{label}</span>
                    <span>{value}</span>
                  </div>
                )
              )}
            </div>
          </div>
        </>
      ) : isExpanded && title==='No load test' ? (
        <>
        <div className="section-open-section-content" key={title}>
          
        <div className="subsection-styling margin-top-bottom">
              {tblReadingsHeaders2.map((set, index) => (
                <div key={index} className="bb">
                  <div className="flex-display tblstartupReading" >
                    {set.headers.map((header, i) => (
                      <div key={i} className="headerStyle">
                        {header}
                      </div>
                    ))}
                  </div>
                </div>
              ))}
              {serviceReport?.noLoadTestDto
                ?.filter((item: any) => {
                  return metaDataNoload1.includes(item.loadTestType);
                })
                .map((datum: any, i: any) => (
                  <div className="sequence-rows">
                    <div className="sequence-cells">
                      {enumDataNoloadType[datum.loadTestType]}
                    </div>
                    <div className="sequence-cells">{Helpers.isFieldEmpty(datum?.hzValue) ? 
                      '-' :datum?.hzValue + " Hz"}</div>
                    <div className="sequence-cells">{Helpers.isFieldEmpty(datum?.aGrndValue) ? 
                      '-' :datum?.aGrndValue + " V"}</div>
                    <div className="sequence-cells">{Helpers.isFieldEmpty(datum?.bGrndValue) ? 
                      '-' :datum?.bGrndValue + " V"}</div>
                    <div className="sequence-cells">{Helpers.isFieldEmpty(datum?.cGrndValue) ? 
                      '-' :datum?.cGrndValue + " V"}</div>
                  </div>
                ))}
            </div>
            <div className="subsection-styling margin-top-bottom">
              {tblReadingsHeaders3.map((set, index) => (
                <div key={index} className="bb">
                  <div className="flex-display tblstartupReading">
                    {set.headers.map((header, i) => (
                      <div key={i} className="headerStyle">
                        {header}
                      </div>
                    ))}
                  </div>
                </div>
              ))}
              {serviceReport?.noLoadTestDto
                ?.filter((item: any) => {
                  return metaDataNoload2.includes(item.loadTestType);
                })
                .map((datum: any, i: any) => (
                  <div className="sequence-rows">
                    <div className="sequence-cells">
                      {enumDataNoloadType[datum.loadTestType]}
                    </div>
                    <div className="sequence-cells">{Helpers.isFieldEmpty(datum?.hzValue) ? 
                      '-' :datum?.hzValue + " Hz"}</div>
                    <div className="sequence-cells">{Helpers.isFieldEmpty(datum?.aGrndValue) ? 
                      '-' :datum?.aGrndValue + " A"}</div>
                    <div className="sequence-cells">{Helpers.isFieldEmpty(datum?.bGrndValue) ? 
                      '-' :datum?.bGrndValue + " A"}</div>
                    <div className="sequence-cells">{Helpers.isFieldEmpty(datum?.cGrndValue) ? 
                      '-' :datum?.cGrndValue + " A"}</div>
                  </div>
                ))}
            </div>   
            <div className="subsection-styling margin-top-bottom">
              {tblReadingsHeaders3.map((set, index) => (
                <div key={index} className="bb">
                  <div className="flex-display tblstartupReading">
                    {set.headers.map((header, i) => (
                      <div key={i} className="headerStyle">
                        {header}
                      </div>
                    ))}
                  </div>
                </div>
              ))}
              {serviceReport?.noLoadTestDto
                ?.filter((item: any) => {
                  return metaDataNoload3.includes(item.loadTestType);
                })
                .map((datum: any, i: any) => (
                  <div className="sequence-rows">
                    <div className="sequence-cells">
                      {enumDataNoloadType[datum.loadTestType]}
                    </div>
                    <div className="sequence-cells">{Helpers.isFieldEmpty(datum?.hzValue) ? 
                      '-' :datum?.hzValue + " Hz"}</div>
                    <div className="sequence-cells">{Helpers.isFieldEmpty(datum?.aGrndValue) ? 
                      '-' :datum?.aGrndValue + " A"}</div>
                    <div className="sequence-cells">{Helpers.isFieldEmpty(datum?.bGrndValue) ? 
                      '-' :datum?.bGrndValue + " A"}</div>
                    <div className="sequence-cells">{Helpers.isFieldEmpty(datum?.cGrndValue) ? 
                      '-' :datum?.cGrndValue + " A"}</div>
                  </div>
                ))}
            </div>            
        </div>
        </>
      ): isExpanded && title === "Brake HP data" ? (
        <>
          <div className="section-open-section-content" key={title}>
            <div className="subsection-styling pd15">
              {Object.entries(tblBrakeHP).map(([label, value], index) => (
                <div key={index} style={styles.row}>
                  <span>{label}</span>
                  <span>{value}</span>
                </div>
              ))}
            </div>
          </div>
        </>
      ) : isExpanded && title === "Problem reported and changes made" ? (
        <div className="section-open-section-content" key={title}>
          {tblProblemsReorted.map((set, index) => (
            <div
              key={index}
              className="subsection-styling mb10"
            >
              <div className="flex-display">
                {set.headers.map((header, i) => (
                  <div key={i} style={{ flex: 1, ...headerStyle }}>
                    {header}
                  </div>
                ))}
              </div>
              <div className="flex-display">
                {set.data.map((datum, i) => (
                  <div key={i} style={{ flex: 1, ...cellStyle }}>
                    {datum}
                  </div>
                ))}
              </div>
            </div>
          ))}
          <div className="subsection-styling pd15">
              {Object.entries(tblProbreportedExtra).map(
                ([label, value], index) => (
                  <div key={index} style={styles.row}>
                    <span>{label}</span>
                    <span>{value}</span>
                  </div>
                )
              )}
            </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

const SectionList: React.FC = () => {
  const sections = [
    "No load test",
    "Setup / Operating parameters",
    "Brake HP data",
    "Start up readings",
    "Problem reported and changes made",
  ];

  return (
    <div className="section-list">
      {sections.map((section, index) => (
        <Section key={index} title={section} />
      ))}
    </div>
  );
};

const ServiceReport: React.FC<Toggle> = ({ open, onCloseModal }) => {
  const { serviceReport, loading } = useSelector(
    (state: any) => state.preInspectionSummary
  );
  useEffect(() => {
    document
      .getElementById("slider")
      ?.classList.toggle(open ? "open" : "close");

    return () => {
      document.getElementById("slider")?.classList.toggle("close");
    };
  }, [open]);

  document.querySelectorAll(".section-header").forEach((header) => {
    header.addEventListener("click", () => {
      header.parentElement?.classList.toggle("section-open");
    });
  });
  const onClick = () => {
    onCloseModal();
  };
  return (
    <div className="main-component">
      {loading && <Loader isOverlay />}

      <CardHeader onClose={onClick} downloadLink={serviceReport?.fileLink} />
      {Helpers.isObjectNotEmpty(serviceReport) ? (
        <>
          <AttributeCard
            installDate={serviceReport?.installDate}
            serviceDate={serviceReport?.serviceDate}
            serviceTech={serviceReport?.serviceTechnician}
            startupDate={serviceReport?.startupDate}
            modemIP={serviceReport.modemIPAddress}
            customer={serviceReport?.customer}
            wellName={serviceReport?.wellName}
            wellAPI={serviceReport?.wellAPI}
            field={serviceReport?.field}
            county={serviceReport?.county}
            state={serviceReport?.state}
          />
          <ESPDetails />
          <SectionList />
        </>
      ) : (
        <div>
          <div className="text-lg text-center py-40">No data found</div>
        </div>
      )}
    </div>
  );
};

const CardHeader: React.FC<any> = ({ onClose, downloadLink }) => {
  return (
    <div className="report-card-header">
      <div className="header-icon" onClick={onClose}>
        <img
          src={closeSlider}
          onClick={onClose}
          alt="icon"
          width="24"
          height="24"
        />
      </div>
      <div className="header-title">Service report</div>
      <div className={`${downloadLink ? "" : "disabled"} download-button `}>
        <a href={downloadLink} download>
          <img
            src={downloadReport}
            alt="download-icon"
            width="129"
            height="20"
          />
        </a>
      </div>
    </div>
  );
};

const styles = {
  container: {
    backgroundColor: "#001023",
    padding: "24px",
    borderRadius: "8px",
  },
  header: {
    color: "#f4fbfc",
    fontFamily: "Mulish",
    fontWeight: 600,
    fontSize: "18px",
    lineHeight: "28px",
    marginBottom: "20px",
    marginLeft: "100px !important",
  },
  h2: {
    marginLeft: "1px !important",
  },
  row: {
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "20px",
    color: "#f7f9f9",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "8px",
    borderBottom: "1px solid #4a5463",
    height: "36px",
  },
  label: {
    flex: "1",
    textAlign: "left",
    fontFamily: "Mulish",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "20px",
  },
  value: {
    flex: "1",
    textAlign: "right",
    fontFamily: "Mulish",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "20px",
  },
};

export default ServiceReport;
