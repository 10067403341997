import { useState } from "react";
import DifaInput from "../input/DifaInput";
import closeIcon from "./../../../images/close-icon.svg";
import { Dialog, DialogBackdrop, DialogPanel } from "@headlessui/react";


const CreateGallery: React.FC<{ show: boolean, close?: (name?: string) => void }> = ({
    show = false,
    close = (name?: string) => { },
}) => {
    const [galleryName, setGalleryName] = useState('');
    return (
        <Dialog open={show} onClose={() => close()} className="relative z-50">
            <DialogBackdrop
                transition
                className="fixed inset-0 backdrop-blur-sm transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
            />
            <DialogBackdrop
                transition
                className="fixed inset-0 backdrop-blur-sm transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
            />

            <div className="fixed inset-0 z-10 w-screen">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <DialogPanel
                        transition
                        className="flex dialog-max-height relative transform overflow-hidden rounded-lg difa-background-with-border text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-sm md:min-w-96 md:w-96  data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                    >
                        <div className="difa-widget-containers">
                            <div className="header-main border-bottom">
                                <div className="flex flex-row justify-between">
                                    <div>
                                        <h3>Create gallery</h3>
                                    </div>
                                    <a href="javascript:void(0)" onClick={() => close()}>
                                        <img src={closeIcon} alt="close" />
                                    </a>
                                </div>
                            </div>
                            <div className="p-6 w-full flex flex-col overflow-y-auto image-container pb-16 table-main">
                                <div className="flex flex-col h-24 items-center justify-center gap-6">
                                    <h5>Give your gallery a name</h5>
                                    <DifaInput value={galleryName} onChange={(e: any) => setGalleryName(e.target.value)} placeholder="e.g Pump 1" caption="" />
                                </div>
                            </div>
                            <div className="flex-row flex gap-3 border-top justify-end items-center h-16 mr-6">
                                <div className="secondary-button">
                                    <button className="header-btn-text w-20" onClick={() => close()}>Back</button>
                                </div>
                                <div className="primary-button">
                                    <button className="header-btn-text" onClick={() => close(galleryName)} disabled={!galleryName}>Create Gallery</button>
                                </div>
                            </div>
                        </div>
                    </DialogPanel>
                </div>
            </div>
        </Dialog>

    );
}

export default CreateGallery;
