import React, { useState } from 'react';
import UploadDocuments from '../../../../common/uploadFiles/UploadDocuments';

const AdditionalDocument = () => {
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);

  function handleFilesChange(files: File[]): void {
    setUploadedFiles(files);
  }

  return (
    <div className="block w-full">
      <UploadDocuments
        fileType="pdf"
        maxFileSize={100} // Max size: 100 MB
        onFilesChange={handleFilesChange}
      />
    </div>
  )
}

export default AdditionalDocument
