
import { HTMLProps } from 'react';
import './DifaCheckbox.scss';

const DifaCheckbox: React.FC<HTMLProps<HTMLInputElement>> = ({
    ...addtionalProps
}) => {
    return (
        <label className="custom-checkbox">
            <input
                type="checkbox" {...addtionalProps}
            />
            <span className="checkmark"></span>
        </label>
    );
}

export default DifaCheckbox;
