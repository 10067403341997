import React, { useEffect } from 'react';
import closeSlider from "../../../../images/close-slider.svg";
import downArrow from "../../../../images/chevron-down.svg";
import Helpers from '../../../../utilities/Helpers';
import { formatDateInNumbers, formatTime } from '../../../common/services/UtilityService';

interface Toggle {
    open: boolean;
    onCloseModal: any;
    ticketData: any;
}

const TicketView: React.FC<Toggle> = ({ open, onCloseModal, ticketData }) => {

    useEffect(() => {
        document
            .getElementById("slider")
            ?.classList.toggle(open ? "open" : "close");

        return () => {
            document.getElementById("slider")?.classList.toggle("close");
        };
    }, [open]);

    document.querySelectorAll(".section-header").forEach((header) => {
        header.addEventListener("click", () => {
            header.parentElement?.classList.toggle("section-open");
        });
    });

    const onClick = () => {
        onCloseModal();
    };

    return (
        <div className="main-component">
            <CardHeader onClose={onClick} />
            {Helpers.isObjectNotEmpty(ticketData) ? (
                <div className="flex flex-col gap-8 text-neutral-grey">
                    <div className="flex items-center border-b border-dark px-8 py-4">
                        <div className="font-bold text-2xl">#{ticketData?.ticketId}</div>
                    </div>
                    <div className="flex flex-col px-8 gap-8">
                        <div className="flex flex-col gap-2 text-sm font-medium">
                            <div className="flex items-center gap-6">
                                <label className="w-1/3">Brand</label>
                                <label className="w-2/3">{ticketData?.brandName ?? "-"}</label>
                            </div>
                            <div className="flex items-center gap-6">
                                <label className="w-1/3">Requester</label>
                                <label className="w-2/3">{ticketData?.requester ?? "-"}</label>
                            </div>
                            <div className="flex items-center gap-6">
                                <label className="w-1/3">Assignee</label>
                                <label className="w-2/3">{ticketData?.assignee ?? "-"}</label>
                            </div>
                            <div className="flex items-center gap-6">
                                <label className="w-1/3">CCs</label>
                                <label className="w-2/3">{ticketData?.ccIds ?? "-"}</label>
                            </div>
                            <div className="flex items-center gap-6">
                                <label className="w-1/3">Tags</label>
                                <label className="w-2/3">{ticketData?.tags ?? "-"}</label>
                            </div>
                            <div className="flex items-center gap-4">
                                <label className="w-1/3">Request channel</label>
                                <label className="w-2/3">-</label>
                            </div>
                            <div className="flex items-center gap-6">
                                <label className="w-1/3">Pending flow up</label>
                                <label className="w-2/3">{ticketData?.pendingfollowup ?? "-"}</label>
                            </div>
                        </div>
                        <div className="border-t border-dark flex flex-col">
                            <div className="flex flex-row items-center justify-between py-4 gap-4">
                                <h3 className="text-lg font-semibold w-4/5">{ticketData?.subject}</h3>
                                <p className="font-normal text-xs w-1/5">{formatDateInNumbers(ticketData?.lastUpdated)} {formatTime(ticketData?.lastUpdated)}</p>
                            </div>
                            <div className="flex flex-col gap-5 font-semibold text-sm">
                                <p>{ticketData?.description}</p>
                                <p className="ticket-requester">{ticketData?.requester} <br />{ticketData?.groupName} </p>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div>
                    <div className="text-lg text-center py-40">No data found</div>
                </div>
            )}
        </div>
    )
}

const CardHeader: React.FC<any> = ({ onClose }) => {
    return (
        <div className="report-card-header !justify-start">
            <div className="header-icon" onClick={onClose}>
                <img
                    src={closeSlider}
                    onClick={onClose}
                    alt="icon"
                />
            </div>
            <div className="header-icon">
                <img
                    src={downArrow}
                    alt="download-icon"
                />
            </div>
        </div>
    );
};

export default TicketView
