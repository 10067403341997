import React, { useEffect, useState } from "react";
import Loader from "../../common/loader/Loader";
import "./InstallReport.scss";
import "./SectionsReport.scss";
import closeSlider from "../../../images/close-slider.svg";
import downloadReport from "../../../images/download-report.svg";
import sectionExpand from "../../../images/chevron-down.svg";
import sectionCollapse from "../../../images/chevron-up.svg";
import { useSelector } from "react-redux";
import Helpers from "../../../utilities/Helpers";
import { sentenceCase } from "../../common/services/UtilityService";

interface Toggle {
  open: boolean;
  onCloseModal: any;
}

const AttributeCard: React.FC<any> = ({
  installDate,
  serviceDate,
  serviceTech,
  startupDate,
  modemIP,
  customer,
  wellName,
  wellAPI,
  field,
  county,
  state,
}) => {
  return (
    <div className="attribute-card">
      <h2 className="default-attribute-header">Attributes</h2>
      <div className="attribute-row">
        <span className="label">Install date</span>
        <span className="value">{Helpers.formatDate(installDate)}</span>
      </div>
      <div className="attribute-row">
        <span className="label">Service date</span>
        <span className="value">{Helpers.formatDate(serviceDate)}</span>
      </div>
      <div className="attribute-row">
        <span className="label">Service tech</span>
        <span className="value">{sentenceCase(serviceTech)}</span>
      </div>
      <div className="attribute-row">
        <span className="label">Start up date</span>
        <span className="value">{Helpers.formatDate(startupDate)}</span>
      </div>
      <div className="attribute-row">
        <span className="label">Modem IP address</span>
        <span className="value">{Helpers.markNullValues(modemIP)}</span>
      </div>
      <div className="attribute-row">
        <span className="label">Customer</span>
        <span className="value">{Helpers.markNullValues(customer)}</span>
      </div>
      <div className="attribute-row">
        <span className="label">Well name</span>
        <span className="value">{sentenceCase(wellName)}</span>
      </div>
      <div className="attribute-row">
        <span className="label">Well API #</span>
        <span className="value">{Helpers.markNullValues(wellAPI)}</span>
      </div>
      <div className="attribute-row">
        <span className="label">Field</span>
        <span className="value">{sentenceCase(field)}</span>
      </div>
      <div className="attribute-row">
        <span className="label">County</span>
        <span className="value">{sentenceCase(county)}</span>
      </div>
      <div className="attribute-row">
        <span className="label">State</span>
        <span className="value">{sentenceCase(state)}</span>
      </div>
    </div>
  );
};

const ESPDetails: React.FC = () => {
  const { startupReport } = useSelector(
    (state: any) => state.preInspectionSummary
  );
  const details: Record<string, any> = {
    Controller: Helpers.markNullValues(startupReport?.controller), // Not found in JSON
    "Controller model": Helpers.markNullValues(startupReport?.controllerModel),
    "Max output Amps": Helpers.markNullValues(startupReport?.motorAmps), // Not found in JSON
    "Drive PN": Helpers.markNullValues(startupReport?.drive), // Not found in JSON
    "Xformer S/N": Helpers.markNullValues(startupReport?.xformerSn),
    "Xformer PN": Helpers.markNullValues(startupReport?.xformerPn),
    "Motor HP": Helpers.markNullValues(startupReport?.motorHp),
    "Motor Volts": Helpers.markNullValues(startupReport?.motorVolts),
    "Motor Amps": Helpers.markNullValues(startupReport?.motorAmps),
    "Cable size": Helpers.markNullValues(startupReport?.cableSize), // Not found in JSON
    "Cable length": Helpers.markNullValues(startupReport?.cableLength),
    "Downhole temperature": Helpers.isFieldEmpty(startupReport?.downholetemp)? "-" : startupReport?.downholetemp + "° F",
    "Min Hz": Helpers.markNullValues(startupReport?.minHz),
    "Max Hz": Helpers.markNullValues(startupReport?.maxHz),
    "Harmonic filter S/N": Helpers.markNullValues(
      startupReport?.harmonicFilterSn
    ),
    "Xformer secondary voltage chosen": Helpers.markNullValues(
      startupReport?.xformerSecondaryVoltageChosen
    ),
    "Connection type": Helpers.markNullValues(startupReport?.connectiontype),
    "Tap switch 1": Helpers.markNullValues(startupReport?.tapSwtch1),
    "Tap switch 2": Helpers.markNullValues(startupReport?.tapSwtch2),
  };

  return (
    <div className="attribute-card">
      <h2 className="default-attribute-header">
        Equipment information and settings
      </h2>
      <div>
        {Object.entries(details).map(([label, value], index) => (
          <div key={index} style={styles.row}>
            <span>{label}</span>
            <span>{value}</span>
          </div>
        ))}
      </div>
    </div>
  );
};


const Section: React.FC<{ title: string }> = ({ title }) => {
  const { startupReport } = useSelector(
    (state: any) => state.preInspectionSummary
  );
  const [isExpanded, setIsExpanded] = useState(false);
  const tblOperating = {
    "Set frequency": Helpers.markNullValues(
      startupReport?.operatingParameter?.setFrequency
    ),
    "Max frequency": Helpers.markNullValues(
      startupReport?.operatingParameter?.maxFrequency
    ),
    "Min frequency": Helpers.markNullValues(
      startupReport?.operatingParameter?.minFrequency
    ),
    "Start current limit": Helpers.markNullValues(
      startupReport?.operatingParameter?.startCurrentLimit
    ),
    "Run current limit": Helpers.markNullValues(
      startupReport?.operatingParameter?.runCurrentLimit
    ),
    "Accel time": Helpers.markNullValues(
      startupReport?.operatingParameter?.accelTime
    ),
    "Decel time": Helpers.markNullValues(
      startupReport?.operatingParameter?.decelTime
    ),
    "Starting mode": Helpers.markNullValues(
      startupReport?.operatingParameter?.startingMode
    ),
    "Single phase volts": Helpers.markNullValues(
      startupReport?.operatingParameter?.singlePhaseVolts
    ),
  };

  const tblOperatingSub1 = {
    "Overload set point": Helpers.markNullValues(
      startupReport?.operatingParameter?.overloadSetPoint
    ),
    "OL fault delay": Helpers.processTimeUnits(
      startupReport?.operatingParameter?.olfaultDelay
    ),
    "Restarts on OL": Helpers.markNullValues(
      startupReport?.operatingParameter?.restartsonOl
    ),
    "Restart delay on OL": Helpers.processTimeUnits(
      startupReport?.operatingParameter?.restartDelayonOl
    ),
    "Underload set point": Helpers.markNullValues(
      startupReport?.operatingParameter?.underloadSetPoint
    ),
    "Underload fault delay": Helpers.processTimeUnits(
      startupReport?.operatingParameter?.ulfaultDelay
    ),
    "Restarts on underload": Helpers.markNullValues(
      startupReport?.operatingParameter?.restartsonUl
    ),
    "Restart delay on underload": Helpers.markNullValues(
      startupReport?.operatingParameter?.restartDelayonUl
    ),
    "Low frequency fault delay": Helpers.processTimeUnits(
      startupReport?.operatingParameter?.lowFreqFaultDelay
    ),
    "Low frequency enabled": Helpers.formatYesNo(
      startupReport?.operatingParameter?.lowFreqEnabled
    ),
    "Low frequency disabled": Helpers.formatYesNo(
      startupReport?.operatingParameter?.lowFreqDisabled
    ),
  };
  const tblOperatingSub2 = {
    "High motor temp set point": Helpers.markNullValues(
      startupReport?.operatingParameter?.highMtrTempSetPnt
    ),
    "Motor temp fault delay": Helpers.processTimeUnits(
      startupReport?.operatingParameter?.mtrTempFaultDelay
    ),
    "Motor temp restart": Helpers.markNullValues(
      startupReport?.operatingParameter?.mtrTempRestart
    ),
    "Motor temp restart delay": Helpers.processTimeUnits(
      startupReport?.operatingParameter?.mtrTempRestartDelay
    ),
    "Motor temp fault enabled": Helpers.formatYesNo(
      startupReport?.operatingParameter?.mtrTempFaultEnabled
    ),
    "Motor temp fault disabled": Helpers.formatYesNo(
      startupReport?.operatingParameter?.mtrTempFaultDisabled
    ),
    "PID enabled/disabled": Helpers.formatYesNo(
      startupReport?.operatingParameter?.pidEnabled
    ),
    "PID PSI set point": Helpers.markNullValues(
      startupReport?.operatingParameter?.pidpsisetPoint
    ),
    "PID low level shutdown": Helpers.markNullValues(
      startupReport?.operatingParameter?.pidlowLevelShtdwn
    ),
    "PID gain": Helpers.markNullValues(
      startupReport?.operatingParameter?.pidgain
    ),
    "PID I-time": Helpers.markNullValues(
      startupReport?.operatingParameter?.pidiTime
    ),
  };
  const tblStartupReading1 = {
    "Tubing PSI at start": Helpers.isFieldEmpty(startupReport?.startUpReadings?.tubingPsistart) ? '-' :
     startupReport?.startUpReadings?.tubingPsistart + ' PSI' ,
    "Tubing PSI at 1 hour":Helpers.isFieldEmpty(startupReport?.startUpReadings?.tubingPsi1hr) ? '-' :
    startupReport?.startUpReadings?.tubingPsi1hr + ' PSI',
    "Casing PSI at start": Helpers.isFieldEmpty(startupReport?.startUpReadings?.casingPsistart) ? '-' :
    startupReport?.startUpReadings?.casingPsistart +' PSI',
    "Casing PSI at 1 hour": Helpers.isFieldEmpty(startupReport?.startUpReadings?.casingPsi1hr) ? '-' :
    startupReport?.startUpReadings?.casingPsi1hr + ' PSI',
    "Motor temp at start":  Helpers.isFieldEmpty(startupReport?.startUpReadings?.motorTempStart) ? '-' 
    :startupReport?.startUpReadings?.motorTempStart + '° F',
    "Motor temp at 1 hour":  Helpers.isFieldEmpty(startupReport?.startUpReadings?.motorTemp1hr) ? '-' :
    startupReport?.startUpReadings?.motorTemp1hr + '° F',
    "Static intake at start":  Helpers.isFieldEmpty(startupReport?.startUpReadings?.staticIntakePsi) ? '-' :
    startupReport?.startUpReadings?.staticIntakePsi +'PSI' ,
    "Intake PSI at 1 hour": Helpers.isFieldEmpty(startupReport?.startUpReadings?.intakePsi1hr) ? '-' :
    startupReport?.startUpReadings?.intakePsi1hr + ' PSI',
    "Flow rate at start":  Helpers.isFieldEmpty(startupReport?.startUpReadings?.flowRateStart) ? '-' :
      startupReport?.startUpReadings?.flowRateStart
    ,
    "Flow rate at 1 hour": Helpers.isFieldEmpty(startupReport?.startUpReadings?.flowRate1hr) ? '-' :
      startupReport?.startUpReadings?.flowRate1hr
    ,
    "Pump up time":  Helpers.isFieldEmpty(startupReport?.startUpReadings?.pumpUpTime) ? '-' :
    Helpers.processTimeUnits(
      startupReport?.startUpReadings?.pumpUpTime
    ),
  };

  const tblStartupReading2 = {
    "Trending parameters established at start up (as per tech alert VSD best practice VFD trending)":
      Helpers.formatYesNo(startupReport?.trendingParametersEst),
    "SMARTEN VSD dec. ramp adapt set to NO position":
      Helpers.formatYesNo(startupReport?.smartenVsdnoposition),
    "Customer permissive to fault the drive": Helpers.formatYesNo(
      startupReport?.customerPermtoFlt
    ),
    "Tested customer permissive to fault the drive": Helpers.formatYesNo(
      startupReport?.testedCustomerPermtoFlt
    ),
  };

  const tblReadingsHeaders1 = [
    {
      headers: ["", "Hz", "A/B", "A/C", "B/C"],
    },
  ];
  const tblReadingsHeaders2 = [
    {
      headers: ["", "Hz", "A / ground", "B / ground", "C / ground"],
    },
  ];

  const tblReadingsHeaders3 = [
    {
      headers: ["", "Hz", "A", "B", "C"],
    },
  ];

  const metaDataReadings1 = ["DriveOutputVoltsǾtoǾ", "MotorDownholeVoltsǾtoǾ"];

  const enumStartupDetailType: any = {
    DriveOutputVoltsǾtoǾ: "Drive output volts Ǿ to Ǿ",
    MotorDownholeVoltsǾtoǾ: "Motor / downhole volts Ǿ to Ǿ",
    DriveOutputVoltsǾtogrnd: "Drive output volts Ǿ to Ǿ to ground",
    MotorDownholeVoltsǾtogrnd: "Motor / downhole volts Ǿ to Ǿ to ground",
    DriveOutputCurrent: "Drive output current",
    MotorDownholeCurrent: "Motor / downhole current",
    MotorDownholeCurrent_Onehr: "Motor / downhole current at 1 hour",
  };
  const metaDataReadings2 = [
    "DriveOutputVoltsǾtogrnd",
    "MotorDownholeVoltsǾtogrnd",
  ];
  const metaDataReadings3 = [
    "DriveOutputCurrent",
    "MotorDownholeCurrent",
    "MotorDownholeCurrent_Onehr",
  ];

  const tblProblemsReorted = [
    {
      headers: ["Problem reported"],
      data: [Helpers.formatYesNo(startupReport?.problemsreported)],
    },
    {
      headers: ["Date", " Changes made", "Customer representative"],
      data: [
        new Date(startupReport?.changesMadeDate).toLocaleDateString(),
        Helpers.markNullValues(startupReport?.changesMade),
        Helpers.isFieldEmpty(startupReport?.changesMadeCustomerRep) ? 
          Helpers.markNullValues(startupReport?.changesMadeCustomerRep) :
          sentenceCase(startupReport?.changesMadeCustomerRep),
      ],
    },
  ];

  const tblBrakeHP = {
    Series: Helpers.markNullValues(startupReport?.startupBrakeHpdata?.series),
    "Stage type": Helpers.markNullValues(
      startupReport?.startupBrakeHpdata?.stagetype)
     ,
    "HP per stage": Helpers.markNullValues(
      startupReport?.startupBrakeHpdata?.hpperstage
    ),
    "Stage count": Helpers.markNullValues(
      startupReport?.startupBrakeHpdata?.stagecount
    ),
    "Required HP": Helpers.markNullValues(
      startupReport?.startupBrakeHpdata?.reqiredhp
    ),
    "50 Hz": Helpers.markNullValues(startupReport?.startupBrakeHpdata?._50hertz, true ),
    "55 Hz": Helpers.markNullValues(startupReport?.startupBrakeHpdata?._55hertz, true ),
    "60 Hz": Helpers.markNullValues(startupReport?.startupBrakeHpdata?._60hertz, true ),
    "65 Hz": Helpers.markNullValues(startupReport?.startupBrakeHpdata?._65hertz, true ),
    "70 Hz": Helpers.markNullValues(startupReport?.startupBrakeHpdata?._70hertz, true ),
    "75 Hz": Helpers.markNullValues(startupReport?.startupBrakeHpdata?._75hertz, true ),
  };

  return (
    <div className="section">
      <div
        className="section-header"
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <span>{title}</span>
        <img src={isExpanded ? sectionCollapse : sectionExpand} alt="chevron" />
      </div>

      {isExpanded && title === "Setup / operating parameters" ? (
        <>
          <div className="section-open-section-content" key={title}>
            <div className="subsection-styling pd15">
              {Object.entries(tblOperating).map(([label, value], index) => (
                <div key={index} style={styles.row}>
                  <span>{label}</span>
                  <span>{value}</span>
                </div>
              ))}
            </div>
          </div>
          <div className="display-flex">
            <div
              className="section-open-section-content sub-section"
              key={title}
            >
              <div className="subsection-styling pd15">
                {Object.entries(tblOperatingSub1).map(
                  ([label, value], index) => (
                    <div key={index} style={styles.row}>
                      <span>{label}</span>
                      <span>{value}</span>
                    </div>
                  )
                )}
              </div>
            </div>
            <div
              className="section-open-section-content sub-section"
              key={title}
            >
              <div className="subsection-styling pd15">
                {Object.entries(tblOperatingSub2).map(
                  ([label, value], index) => (
                    <div key={index} style={styles.row}>
                      <span>{label}</span>
                      <span>{value}</span>
                    </div>
                  )
                )}
              </div>
            </div>
          </div>
        </>
      ) : isExpanded && title === "Start up readings" ? (
        <>
          <div className="section-open-section-content" key={title}>
            <div className="subsection-styling pd15">
              {Object.entries(tblStartupReading1).map(
                ([label, value], index) => (
                  <div key={index} style={styles.row}>
                    <span>{label}</span>
                    <span>{value}</span>
                  </div>
                )
              )}
            </div>
            <div className="subsection-styling margin-top-bottom">
              {tblReadingsHeaders1.map((set, index) => (
                <div key={index} className="bb">
                  <div className="flex-display tblstartupReading">
                    {set.headers.map((header, i) => (
                      <div key={i}  className="headerStyle">
                        {header}
                      </div>
                    ))}
                  </div>
                </div>
              ))}
              {startupReport?.startUpReadingDetails
                ?.filter((item: any) => {
                  return metaDataReadings1.includes(item.startupDetailType);
                })
                .map((datum: any, i: any) => (
                  <div className="sequence-rows tblstartupReading">
                    <div className="sequence-cells">
                      {enumStartupDetailType[datum.startupDetailType]}
                    </div>
                    <div className="sequence-cells">{Helpers.isFieldEmpty(datum?.hzValue)? '-' : datum?.hzValue + ' Hz'}</div>
                    <div className="sequence-cells"> {Helpers.isFieldEmpty(datum?.abValue)? '-' : datum?.abValue + ' V'}</div>
                    <div className="sequence-cells">
                    {Helpers.isFieldEmpty(datum?.acValue)? '-' : datum?.acValue + ' V'}
                      
                    </div>
                    <div className="sequence-cells">{Helpers.isFieldEmpty(datum?.bcValue)? '-' : datum?.bcValue + ' V'}
                      </div>
                  </div>
                ))}
            </div>

            <div className="subsection-styling margin-top-bottom">
              {tblReadingsHeaders2.map((set, index) => (
                <div key={index} className="bb">
                  <div className="flex-display tblstartupReading">
                    {set.headers.map((header, i) => (
                      <div key={i}  className="headerStyle">
                        {header}
                      </div>
                    ))}
                  </div>
                </div>
              ))}
              {startupReport?.startUpReadingDetails
                ?.filter((item: any) => {
                  return metaDataReadings2.includes(item.startupDetailType);
                })
                .map((datum: any, i: any) => (
                  <div className="sequence-rows">
                    <div className="sequence-cells">
                      {enumStartupDetailType[datum.startupDetailType]}
                    </div>
                    <div className="sequence-cells"> {Helpers.isFieldEmpty(datum?.aValue)? '-' : datum?.aValue + ' Hz'}
                      </div>
                    <div className="sequence-cells"> {Helpers.isFieldEmpty(datum?.aGrndValue)? '-' : datum?.aGrndValue + ' V'}
                      </div>
                    <div className="sequence-cells"> {Helpers.isFieldEmpty(datum?.acValue)? '-' : datum?.acValue + ' V'}
                       </div>
                    <div className="sequence-cells"> {Helpers.isFieldEmpty(datum?.cGrndValue)? '-' : datum?.cGrndValue + ' V'}
                      </div>
                  </div>
                ))}
            </div>
            <div className="subsection-styling margin-top-bottom">
              {tblReadingsHeaders3.map((set, index) => (
                <div key={index} className="bb">
                  <div className="flex-display tblstartupReading">
                    {set.headers.map((header, i) => (
                      <div key={i} className="headerStyle">
                        {header}
                      </div>
                    ))}
                  </div>
                </div>
              ))}
              {startupReport?.startUpReadingDetails
                ?.filter((item: any) => {
                  return metaDataReadings3.includes(item.startupDetailType);
                })
                .map((datum: any, i: any) => (
                  <div className="sequence-rows">
                    <div className="sequence-cells">
                      {enumStartupDetailType[datum.startupDetailType]}
                    </div>
                    <div className="sequence-cells"> {Helpers.isFieldEmpty(datum?.hzValue)? '-' : datum?.hzValue + ' Hz'}
                      </div>
                    <div className="sequence-cells"> {Helpers.isFieldEmpty(datum?.aValue)? '-' : datum?.aValue + ' V'}
                      </div>
                    <div className="sequence-cells"> {Helpers.isFieldEmpty(datum?.bValue)? '-' : datum?.bValue + ' V'}
                      </div>
                    <div className="sequence-cells"> {Helpers.isFieldEmpty(datum?.cValue)? '-' : datum?.cValue + ' V'}
                      </div>
                  </div>
                ))}
            </div>

            <div className="subsection-styling pd15">
              {Object.entries(tblStartupReading2).map(
                ([label, value], index) => (
                  <div key={index} style={styles.row}>
                    <span>{label}</span>
                    <span>{value}</span>
                  </div>
                )
              )}
            </div>
          </div>
        </>
      ) : isExpanded && title === "Brake HP data" ? (
        <>
          <div className="section-open-section-content" key={title}>
            <div className="subsection-styling pd15">
              {Object.entries(tblBrakeHP).map(([label, value], index) => (
                <div key={index} style={styles.row}>
                  <span>{label}</span>
                  <span>{value}</span>
                </div>
              ))}
            </div>
          </div>
        </>
      ) : isExpanded && title === "Problem reported and changes made" ? (
        <div className="section-open-section-content" key={title}>
          {tblProblemsReorted.map((set, index) => (
            <div key={index} className="subsection-styling mb10 bb">
              <div className={`flex-display divProblemsReported-${index}`}>
                {set.headers.map((header, i) => (
                  <div key={i}  className="headerStyle">
                    {header}
                  </div>
                ))}
              </div>
              <div className={`flex-display divProblemsReported-${index}`}>
                {set.data.map((datum, i) => (
                  <div key={i}  className="cellStyle">
                    {datum}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

const SectionList: React.FC = () => {
  const sections = [
    "Setup / operating parameters",
    "Start up readings",
    "Brake HP data",
    "Problem reported and changes made",
  ];

  return (
    <div className="section-list">
      {sections.map((section, index) => (
        <Section key={index} title={section} />
      ))}
    </div>
  );
};

const StartupReport: React.FC<Toggle> = ({ open, onCloseModal }) => {
  const { startupReport, loading } = useSelector(
    (state: any) => state.preInspectionSummary
  );
  useEffect(() => {
    document
      .getElementById("slider")
      ?.classList.toggle(open ? "open" : "close");

    return () => {
      document.getElementById("slider")?.classList.toggle("close");
    };
  }, [open]);

  document.querySelectorAll(".section-header").forEach((header) => {
    header.addEventListener("click", () => {
      header.parentElement?.classList.toggle("section-open");
    });
  });
  const onClick = () => {
    onCloseModal();
  };
  return (
    <div className="main-component">
      {loading && <Loader isOverlay /> }
      <>
      <CardHeader onClose={onClick} downloadLink={startupReport?.fileLink} />
      {Helpers.isObjectNotEmpty(startupReport) ? 
      <>
      <AttributeCard
        installDate={startupReport?.unitInstallDate}
        serviceTech={sentenceCase(startupReport?.serviceTechnician)}
        startupDate={startupReport?.startupDate}
        modemIP={startupReport?.modemIP}
        customer={sentenceCase(startupReport?.customer)}
        wellName={startupReport?.wellName}
        wellAPI={startupReport?.wellApiNumber}
        field={startupReport?.field}
        county={startupReport?.county}
        state={startupReport?.state}
      />
      <ESPDetails />
      <SectionList />
      </>
      : (
        <div>
          <div className="text-lg text-center py-40">No data found</div>
        </div>
      )
      
    }
    </>
    </div>
  );
};

const CardHeader: React.FC<any> = ({ onClose, downloadLink }) => {
  return (
    <div className="report-card-header">
      <div className="header-icon" onClick={onClose}>
        <img
          src={closeSlider}
          onClick={onClose}
          alt="icon"
          width="24"
          height="24"
        />
      </div>
      <div className="header-title">Start up report</div>
      <div className={`${downloadLink ? "" : "disabled"} download-button `}>
        <a href={downloadLink} download>
          <img
            src={downloadReport}
            alt="download-icon"
            width="129"
            height="20"
          />
        </a>
      </div>
    </div>
  );
};

const styles = {
  container: {
    backgroundColor: "#001023",
    padding: "24px",
    borderRadius: "8px",
  },
  header: {
    color: "#f4fbfc",
    fontFamily: "Mulish",
    fontWeight: 600,
    fontSize: "18px",
    lineHeight: "28px",
    marginBottom: "20px",
    marginLeft: "100px !important",
  },
  h2: {
    marginLeft: "1px !important",
  },
  row: {
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "20px",
    color: "#f7f9f9",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "8px",
    borderBottom: "1px solid #4a5463",
    height: "36px",
  },
  label: {
    flex: "1",
    textAlign: "left",
    fontFamily: "Mulish",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "20px",
  },
  value: {
    flex: "1",
    textAlign: "right",
    fontFamily: "Mulish",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "20px",
  },
};

export default StartupReport;
