// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.active-tab {
  border-color: #FA6020;
  margin-bottom: -1.5px;
}`, "",{"version":3,"sources":["webpack://./src/features/common/tabs/horizontalTab.scss"],"names":[],"mappings":"AAAA;EACI,qBAAA;EACA,qBAAA;AACJ","sourcesContent":[".active-tab {\n    border-color: #FA6020;\n    margin-bottom: -1.5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
