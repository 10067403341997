import React, { useState } from 'react'
import { ReactComponent as CloudUploadAlt } from '../../../images/upload-cloud-02.svg';
import { FileType, FileUploadProps } from '../../models/difaPacketBuilder';
import { ReactComponent as FileImg } from '../../../images/Page.svg';
import { ReactComponent as DeleteIcon } from '../../../images/trash-01.svg';
interface ExtendedFile {
  name: string;
  progress?: number;
}

const UploadDocuments: React.FC<FileUploadProps> = ({
  fileType,
  showPreview = true,
  maxFileSize = 5, // Default max size: 5 MB
  onFilesChange,
}) => {
  const [files, setFiles] = useState<File[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [isDragging, setIsDragging] = useState(false);

  const acceptedFileTypes: Record<FileType, string> = {
    pdf: "application/pdf",
    csv: "text/csv",
    images: "image/*",
  };

  const validateFile = (file: File): boolean => {
    const isValidType = file.type.match(acceptedFileTypes[fileType]);
    const isValidSize = file.size <= maxFileSize * 1024 * 1024;
    return Boolean(isValidType) && isValidSize;
  };

  const handleFiles = (selectedFiles: File[]) => {
    const validFiles = selectedFiles.filter(validateFile);

    if (validFiles.length) {
      setFiles((prevFiles) => [...prevFiles, ...validFiles]);
      onFilesChange && onFilesChange([...files, ...validFiles]);
      error && setError(null);
    } else {
      setError(
        `Invalid file(s). Please upload ${fileType.toUpperCase()} under ${maxFileSize} MB.`
      );
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      handleFiles(Array.from(event.target.files));
    }
  };

  const handleDragEvents = (
    event: React.DragEvent<HTMLDivElement>,
    isDragging: boolean
  ) => {
    event.preventDefault();
    setIsDragging(isDragging);
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(false);
    handleFiles(Array.from(event.dataTransfer.files));
  };

  const deleteFile = (index: number) => {
    const updatedFiles = files.filter((_, i) => i !== index);
    setFiles(updatedFiles);
    onFilesChange && onFilesChange(updatedFiles);
  };

  return (
    <>
      <div
        className={`upload-container p-6 border border-dark shadow-md sm:rounded-lg bg-dark-800 cursor-pointer relative text-center ${isDragging ? "dragging" : ""}`}
        onDragOver={(event) => handleDragEvents(event, true)}
        onDragLeave={(event) => handleDragEvents(event, false)}
        onDrop={handleDrop}
      >
        <input
          type="file"
          id="fileInput"
          multiple
          accept={acceptedFileTypes[fileType]}
          onChange={handleFileChange}
          style={{ display: "none" }}
        />
        <label htmlFor="fileInput" className="flex flex-col items-center gap-3">
          <CloudUploadAlt className="w-10 h-10 p-2 border border-dark rounded-lg cursor-pointer" />
          <p className="font-normal text-sm">
            <span className="font-bold text-Indigo cursor-pointer">Click to upload</span> or drag and drop
            <br />
            {fileType.toUpperCase()} files only
          </p>
        </label>
      </div>
      {error && <p className="text-red-500 text-sm mt-2">{error}</p>}
      {showPreview && files.map((file: ExtendedFile, index) => (
        <div key={index} className="flex flex-wrap items-center p-4 gap-3 mt-2 border border-dark shadow-md sm:rounded-lg">
          <FileImg className="w-7 h-8" />
          <div className="flex flex-col justify-center gap-1" style={{ width: "calc(100% - 40px)" }}>
            <div className="flex justify-between items-center gap-2">
              <p className="text-sm font-normal">{file.name}</p>
              <button
                type="button"
                className="p-1 text-sm font-semibold"
                onClick={() => deleteFile(index)}
              >
                <DeleteIcon />
              </button>
            </div>
            <div className='flex items-center gap-2'>
              <div className="w-full bg-gray-200 rounded-full h-2">
                <div
                  className="bg-orange-600 h-2 rounded-full"
                  style={{ width: `${file.progress}%` }}
                ></div>
              </div>
              <p className="text-xs font-normal text-right">{!file.progress && 100}%</p>
            </div>

          </div>
        </div>
      ))}
    </>
  )
}

export default UploadDocuments
