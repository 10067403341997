import React, { useEffect, useState } from 'react'
import closeSlider from "../../../../../images/close-slider.svg";
import { ReactComponent as ArrowUpIcon } from "../../../../../images/arrow-narrow-up.svg";
import { ReactComponent as ArrowDownIcon } from "../../../../../images/arrow-down.svg";
import { formatDateInNumbers, formatTime } from '../../../../common/services/UtilityService';

interface Toggle {
    open: boolean;
    onCloseModal: () => void;
    AlarmEventData: any;
}

const AlarmEventsList: React.FC<Toggle> = ({ open, onCloseModal, AlarmEventData }) => {

    const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc");
    const [sortColumn, setSortColumn] = useState<string>("");

    useEffect(() => {
        document
            .getElementById("slider")
            ?.classList.toggle(open ? "open" : "close");

        return () => {
            document.getElementById("slider")?.classList.toggle("close");
        };
    }, [open]);

    document.querySelectorAll(".section-header").forEach((header) => {
        header.addEventListener("click", () => {
            header.parentElement?.classList.toggle("section-open");
        });
    });

    const onClick = () => {
        onCloseModal();
    };

    const handleSorting = (column: string) => {
        const newOrder = sortOrder === "asc" ? "desc" : "asc";
        setSortOrder(newOrder);
        setSortColumn(column);
    };

    const sortedAlarmEvents = Array.isArray(AlarmEventData)
        ? [...AlarmEventData].sort((a: any, b: any) => {
            if (sortColumn === "event") {
                return sortOrder === "asc"
                    ? a.event.localeCompare(b.event)
                    : b.event.localeCompare(a.event);
            } else if (sortColumn === "date") {
                return sortOrder === "asc"
                    ? new Date(a.eventDate).getTime() - new Date(b.eventDate).getTime()
                    : new Date(b.eventDate).getTime() - new Date(a.eventDate).getTime();
            }
            return 0;
        })
        : [];

    return (
        <div className="main-component">
            <CardHeader onClose={onClick} />
            <div className="flex flex-col gap-8 text-neutral-grey p-6">
                <div className="table-main relative overflow-x-auto shadow-md">
                    <table className="w-full text-left">
                        <thead className="text-xs font-semibold top-0 sticky text-neutral-grey border-b border-dark">
                            <tr className="table-row">
                                <th scope="col" className="px-3 py-4">
                                    <div className="flex items-center">
                                        Date
                                        <div className="flex items-center cursor-pointer" onClick={() => handleSorting("date")}>
                                            {sortOrder === "asc" && sortColumn === "date" ? <ArrowDownIcon className="ms-1.5" /> : <ArrowUpIcon className="ms-1.5" />}
                                        </div>
                                    </div>
                                </th>
                                <th scope="col" className="px-3 py-4">
                                    <div className="flex items-center">
                                        Event
                                        <div className="flex items-center cursor-pointer" onClick={() => handleSorting("event")}>
                                            {sortOrder === "asc" && sortColumn === "event" ? <ArrowDownIcon className="ms-1.5" /> : <ArrowUpIcon className="ms-1.5" />}
                                        </div>
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody className="text-sm font-normal text-neutral-grey">
                            {sortedAlarmEvents && sortedAlarmEvents.length > 0 ? (
                                sortedAlarmEvents.map((alarmdata: any, index: number) => (
                                    <tr key={index} className="border-b border-dark">
                                        <td className="px-3 py-3">{formatDateInNumbers(alarmdata?.eventDate)} {formatTime(alarmdata?.eventDate)}</td>
                                        <td className="px-3 py-3">
                                            {alarmdata?.event}
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr className="table-row">
                                    <td colSpan={2} className="text-lg text-center py-5">
                                        No data found
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

const CardHeader: React.FC<any> = ({ onClose }) => {
    return (
        <div className="report-card-header !justify-start">
            <div className="header-icon" onClick={onClose}>
                <img
                    src={closeSlider}
                    onClick={onClose}
                    alt="icon"
                />
            </div>
            <div className="header-icon font-bold text-2xl">
                Alarm event list
            </div>
        </div>
    );
};

export default AlarmEventsList
