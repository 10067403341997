import React, { useEffect, useRef, useState } from 'react'
import DifaSwitch from '../../../common/switch/DifaSwitch'
import DifaInput from '../../../common/input/DifaInput';
import DifaDropdown from '../../../common/dropdown/Dropdown';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPullImageGallery, fetchPullReportImages, removeGallery, resetCreateGalleryState } from '../../difaPacketBuilderSlice';
import { useLocation } from 'react-router-dom';
import GalleryComponent from '../../../common/gallery/Gallery';
import EditGalleryComponent from '../../../common/gallery/EditGallery';
import { toast, ToastContainer } from 'react-toastify';
import Loader from '../../../common/loader/Loader';

const PullReason = () => {
    const [expanded, setExpanded] = useState(false);
    const onExpand = () => {
        setExpanded(!expanded);
    };
    const location = useLocation();
    const { difaDetails } = useSelector((state: any) => state.difaDetails);
    const { difaId, customerName, wellName } = location.state || {};
    const [activeGalleryItem, setActiveGalleryItem] = useState(null);
    const [reasionforpull, setreasionforpull] = useState({
        failureCategory: "",
        failureDescriptors: "",
        pullSummary: "",
    });

    const [categorySearchTerm, setcategorySearchTerm] = useState("");
    const [categoryDropdownOpen, setcategoryDropdownOpen] = useState(false);
    const catogoryOptions = [
        "Coated",
        "Corroded",
        "Electrical",
        "External",
        "External / Mechanical",
        "Material",
        "Material / External",
        "Mechanical",
        "Mechanical, Material",
        "Non-ESP Component",
        "Primary Failed Sub-Component Impellers/Diffusers",
        "Short Circuit",
        "Vibration",
        "Unknown",
        "Other",
        "None",
    ];
    const dispatch = useDispatch();
    const [descriptorsSearchTerm, setdescriptorsSearchTerm] = useState("");
    const [descriptorsDropdownOpen, setdescriptorsDropdownOpen] = useState(false);
    const descriptorsOptions = [
        "Brittle",
        "Broken",
        "Broken / Fractured",
        "Broken Shaft",
        "Broken, Damaged",
        "Broken/ fractured/vibration",
        "Buckled",
        "Burn",
        "Burn Overheated",
        "Burn/Melted",
        "Burst / Ruptured",
        "Cause General",
        "Coated",
        "Coated - Internal",
        "Coated - solids",
        "Coated External",
        "Coated Internal",
        "Coated internal / plugged",
        "Coated internal/eroded",
        "Collapsed",
        "Contaminated / Contamination",
        "Corroded",
        "Corroded ( Eroded/Pressure Washed)",
        "Cracked",
        "Cracked / Swollen",
        "Current Leakage",
        "Damage / Damaged",
        "Damaged / Punctured",
        "Degraded",
        "Discolored",
        "Disconnected",
        "Eroded",
        "Eroded (Up thrust wear)",
        "Eroded / Pressure Washed",
        "Failed Hypot Test",
        "Fracture/Broken",
        "Fractured",
        "Fractured/Broken",
        "High Impedance/Resistance",
        "Imploded and Eroded",
        "Internal/ External",
        "Leaking",
        "Leaking / Loose / Spinning",
        "Loose/Spinning",
        "Low Impedance / Resistance",
        "Melted",
        "Melted and Overheated",
        "Missing",
        "Missing (Third Party INNOVEX)",
        "Non-ESP Component",
        "None",
        "Open Circuit",
        "Other",
        "Overheated",
        "Overheated ( Worn)",
        "Overheated (Swollen in cable)",
        "Overheated / Worn",
        "Overheated Burn",
        "Overheated/brittle",
        "Overheated/Degraded",
        "Overheating",
        "Parted",
        "Phase Imbalance / Phase Unbalance / Phase Unbalanced",
        "Plugged",
        "Plugged ( Pump Base Leaking - Secondary)",
        "Plugged ( tailpipe)",
        "Plugged / Broken/Fractured",
        "Plugged-Coated",
        "Plugged / Stuck",
        "Punctured",
        "Punctured - Damaged",
        "Short",
        "Short Circuit",
        "Short Circuit Well",
        "Short Circuit, Phase Unbalance",
        "Squashed/Flattened",
        "Stuck",
        "Stuck / worn",
        "Stuck Closed",
        "Twisted",
        "Unknown",
        "Vibration",
        "Vibration/ Rub Marks",
        "Vibration/Rub Marks",
        "Vibration/Unbalanced/ Loose-Spinning",
        "Wear / Worn",
        "Worn Overheated",
        "Worn/ Overheated",
        "Worn/ Plugged",
        "Worn/Corroded",
        "Worn/Eroded",
    ];
    const [showGallery, setShowGallery] = useState(false);
    const [showEditGallery, setShowEditGallery] = useState(false);
    const { pullReportImages, pullImageGallery, loading, galleryTransactionMsg } = useSelector(
        (state: any) => state?.difaPacketBuilder
    );

    useEffect(() => {
        dispatch(fetchPullReportImages({ wellApiNumber: difaDetails.wellApiNumber, difaId }));
    }, [showGallery]);

    useEffect(() => {
        dispatch(fetchPullImageGallery({ wellApiNumber: difaDetails.wellApiNumber, difaId }));
    }, [])

    useEffect(() => {
        if (galleryTransactionMsg && galleryTransactionMsg.type === 'create-success') {
            dispatch(fetchPullReportImages({ wellApiNumber: difaDetails.wellApiNumber, difaId }));
            toast.success(<div className="flex flex-col">
                <div className="text-lg">
                    Gallery added
                </div>
                <div className="text-sm">
                    To see your images select the gallery.
                </div>
            </div>)
            dispatch(resetCreateGalleryState());
        } else if (galleryTransactionMsg && galleryTransactionMsg.type === 'create-error') {
            toast.error(<div className="flex flex-col">
                <div className="text-lg">
                    Opps!!
                </div>
                <div className="text-sm">
                    The gallery name already exists. Please choose a different name.
                </div>
            </div>)
            dispatch(resetCreateGalleryState());
        }
        else if (galleryTransactionMsg && galleryTransactionMsg.type === 'remove-success') {
            dispatch(fetchPullReportImages({ wellApiNumber: difaDetails.wellApiNumber, difaId }));
            toast.success(<div className="flex flex-col">
                <div className="text-lg">
                    Gallery removed
                </div>
                <div className="text-sm">
                    To see your images select the gallery.
                </div>
            </div>)
            dispatch(resetCreateGalleryState());
        }
    }, [galleryTransactionMsg]);

    const handleRemove = (galleryId: string) => {
        dispatch(removeGallery(galleryId));
    }
    const catogoryDropdownRef = useRef<HTMLDivElement>(null);

    const descriptorsDropdownRef = useRef<HTMLDivElement>(null);

    const HandleInputChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const { name, value } = e.target;
        setreasionforpull({
            ...reasionforpull,
            [name]: value,
        });
    };

    const catogoryFilteredOptions = catogoryOptions.filter((option) =>
        option.toLowerCase().includes(categorySearchTerm.toLowerCase())
    );

    const descriptorsFilteredOptions = descriptorsOptions.filter((option) =>
        option.toLowerCase().includes(descriptorsSearchTerm.toLowerCase())
    );

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                catogoryDropdownRef.current &&
                !catogoryDropdownRef.current.contains(event.target as Node)
            ) {
                setcategoryDropdownOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [catogoryDropdownRef]);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                descriptorsDropdownRef.current &&
                !descriptorsDropdownRef.current.contains(event.target as Node)
            ) {
                setdescriptorsDropdownOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [descriptorsDropdownRef]);
    return (
        <div className="difa-widget-containers">
            <ToastContainer position='top-right' autoClose={3000} />
            {loading && !showGallery && <Loader isOverlay />}
            <div className="header-main">
                <div className="flex flex-row justify-between flex-wrap">
                    <div>
                        <h3>Reason for pull</h3>
                        <h5>Notice any issues during the pull? Please describe your observations and include supporting photos.</h5>
                    </div>
                    <div className="flex flex-row items-center gap-3">
                        <DifaSwitch checked={expanded} onChange={onExpand} />
                        <h4 className="whitespace-nowrap">Include in report</h4>
                        <button className="btn-secondary font-bold text-sm">View pull report</button>
                    </div>
                </div>
            </div>
            <div className="flex flex-col gap-5 py-5 px-6 border-t border-dark">
                <div className="flex flex-col md:flex-row gap-4">
                    <div className="input-field-container">
                        <div className="input-field-caption">Failure category</div>
                        <div
                            className="input-field-wrapper relative"
                            ref={catogoryDropdownRef}
                        >
                            <button
                                className="input-field w-full text-left !h-full"
                                onClick={() => setcategoryDropdownOpen(!categoryDropdownOpen)}
                            >
                                {reasionforpull.failureCategory || "Select an option"}
                            </button>
                            {categoryDropdownOpen && (
                                <div className="absolute mt-1 w-full bg-white border border-gray-300 rounded shadow-lg z-10">
                                    <input
                                        type="text"
                                        className="w-full p-2 border-b border-gray-300"
                                        placeholder="Search..."
                                        value={categorySearchTerm}
                                        onChange={(e) => setcategorySearchTerm(e.target.value)}
                                    />
                                    <ul className="max-h-60 overflow-y-auto">
                                        {catogoryFilteredOptions.map((option, index) => (
                                            <li
                                                key={index}
                                                className="p-2 hover:bg-gray-200 cursor-pointer"
                                                onClick={() => {
                                                    setreasionforpull({
                                                        ...reasionforpull,
                                                        failureCategory: option,
                                                    });
                                                    setcategoryDropdownOpen(false);
                                                }}
                                            >
                                                {option}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="input-field-container">
                        <div className="input-field-caption">Failure descriptors</div>
                        <div
                            className="input-field-wrapper relative"
                            ref={descriptorsDropdownRef}
                        >
                            <button
                                className="input-field w-full text-left !h-full"
                                onClick={() =>
                                    setdescriptorsDropdownOpen(!descriptorsDropdownOpen)
                                }
                            >
                                {reasionforpull.failureDescriptors || "Select an option"}
                            </button>
                            {descriptorsDropdownOpen && (
                                <div className="absolute mt-1 w-full bg-white border border-gray-300 rounded shadow-lg z-10">
                                    <input
                                        type="text"
                                        className="w-full p-2 border-b border-gray-300"
                                        placeholder="Search..."
                                        value={descriptorsSearchTerm}
                                        onChange={(e) => setdescriptorsSearchTerm(e.target.value)}
                                    />
                                    <ul className="max-h-60 overflow-y-auto">
                                        {descriptorsFilteredOptions.map((option, index) => (
                                            <li
                                                key={index}
                                                className="p-2 hover:bg-gray-200 cursor-pointer"
                                                onClick={() => {
                                                    setreasionforpull({
                                                        ...reasionforpull,
                                                        failureDescriptors: option,
                                                    });
                                                    setdescriptorsDropdownOpen(false);
                                                }}
                                            >
                                                {option}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className="flex flex-col gap-5 border-dark">
                    <div className="input-field-container">
                        <div className="input-field-caption">Pull summary</div>
                        <textarea
                            className="card-textarea"
                            maxLength={500}
                            rows={6}
                            value={reasionforpull.pullSummary}
                            placeholder="Add comments here..."
                            onChange={(e) =>
                                setreasionforpull({
                                    ...reasionforpull,
                                    pullSummary: e.target.value,
                                })
                            }
                        ></textarea>
                    </div>
                </div>
            </div>
            <div>
                <div className="header-main">
                    <div className="flex flex-row justify-between flex-wrap">
                        <div>
                            <h3>Pull report images</h3>
                            <h5>Upload, annotate, and edit pull images.</h5>
                        </div>
                        <div className="flex flex-row items-center gap-3">
                            <button className="btn-secondary font-bold text-sm" onClick={() => setShowGallery(true)}>Create gallery</button>
                        </div>
                    </div>
                </div>
                {!pullImageGallery || !pullImageGallery.length ? (
                    <div className="flex flex-col gap-5 p-6 ">
                        <div className="flex p-6 border border-dark rounded-lg items-center justify-center bg-dark-800">
                            <p className="font-normal text-sm text-center py-6">No images have been added. To add trend images select the “Create trend gallery” button.</p>
                        </div>
                    </div>
                ) :
                    <div className="flex flex-row gap-5 p-6 border-t border-dark cursor-pointer rounded-md flex-wrap">
                        {
                            pullImageGallery && pullImageGallery.map((gl: any, index: number) => (
                                <div className="flex flex-col gap-3  w-72 max-w-72" onClick={() => {
                                    setShowEditGallery(true);
                                    setActiveGalleryItem(gl);
                                }}>

                                    <div className={`difa-widget-containers  difa-background relative max-w-72 h-64 p-2`}>
                                        <div className="w-full h-full flex items-center flex-col">
                                            <img className="h-48 w-full" src={gl.reportImageDetails[0].fileLink} alt={'gallery'}></img>
                                            <div className='flex justify-between w-full items-center p-2'>
                                                <h2>
                                                    {gl.galleryName}
                                                </h2>
                                                <h5>
                                                    {gl && gl.reportImageDetails.length} images
                                                </h5>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            ))
                        }
                    </div>
                }
            </div>
            {showGallery && <GalleryComponent show={showGallery} difaID={difaId} reportType="pull" images={pullReportImages} loading={loading} onGalleryClose={() => setShowGallery(false)} wellApiNumber={difaDetails.wellApiNumber} />}
            {showEditGallery && <EditGalleryComponent source='' galleryDetails={activeGalleryItem}
                onRemoveGallery={handleRemove}
                show={showEditGallery}
                onGalleryClose={() => {
                    setShowEditGallery(false);
                    setActiveGalleryItem(null);
                }}
                wellApiNumber={difaDetails.wellApiNumber}
            />}

        </div>
    )
}

export default PullReason
