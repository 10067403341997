import React, { ChangeEvent, useState } from 'react';
import { ReactComponent as DownArrow } from "../../../../../images/chevron-down.svg";
import { ReactComponent as UpArrow } from "../../../../../images/chevron-up.svg";
import { formatDateInNumbers } from '../../../../common/services/UtilityService';
import { useDispatch, useSelector } from 'react-redux';
import DifaCheckbox from '../../../../common/checkbox/DifaCheckbox';
import { fetchInstallReport, fetchPullReport, fetchStartupReport } from '../../../../preInspectionSummary/PreInspectionSummarySlice';
import MainComponent from '../../../../preInspectionSummary/components/InstallReport';
import StartupReport from '../../../../preInspectionSummary/components/StartupReport';
import PullReport from '../../../../preInspectionSummary/components/PullReport';
import { useSortableData } from '../../../../hooks/useSortableData';

const GeneralReportTable: React.FC<{ searchQuery: string, source: string }> = ({ searchQuery, source }) => {
    const dispatch = useDispatch();
    const [showReport, setShowReport] = useState(false);
    const [showStartup, setStartupReport] = useState(false);
    const [showPullReport, setPullReport] = useState(false);
    const { generalReport, loading } = useSelector(
        (state: any) => state.difaPacketBuilder
    );
    const isPreview = source === "preview-packet";

    const generalReportData = [
        {
            name: "Install report",
            startDate: generalReport[0].installDate ?? "",
            completeDate: generalReport[0].installCompleteDate ?? "",
            serviceProblem: generalReport[0].serviceProblem ?? "",
            serviceResolution: generalReport[0].serviceResolution ?? "",
        },
        {
            name: "Start up report",
            startDate: generalReport[0].actionDate ?? "",
            completeDate: generalReport[0].startupCompleteDate ?? "",
            serviceProblem: generalReport[0].serviceProblem ?? "",
            serviceResolution: generalReport[0].serviceResolution ?? "",
        },
        {
            name: "Pull report",
            startDate: generalReport[0].pullDate ?? "",
            completeDate: generalReport[0].pullCompleteDate ?? "",
            serviceProblem: generalReport[0].serviceProblem ?? "",
            serviceResolution: generalReport[0].serviceResolution ?? "",
        },
    ];

    const { items: sortedDifaTrackings, requestSort, sortConfig } = useSortableData(generalReportData);
    
    const filteredDifaServices = sortedDifaTrackings.filter((item) =>
        item?.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const openReport = (type: string) => {
        if (type === "Install report") {
            if (!showReport) {
                dispatch(fetchInstallReport({ wellApiNumber: generalReport[0]?.wellApiNumber }));
                setPullReport(false);
                setStartupReport(false);
                setShowReport(!showReport);
            }
        } else if (type === "Start up report") {
            if (!showStartup) {
                dispatch(fetchStartupReport({ wellApiNumber: generalReport[0]?.wellApiNumber }));
                setStartupReport(!showStartup);
                setPullReport(false);
                setShowReport(false);
            }
        } else if (type === "Pull report") {
            if (!showPullReport) {
                dispatch(fetchPullReport({ wellApiNumber: generalReport[0]?.wellApiNumber }));
                setPullReport(!showPullReport);
                setShowReport(false);
                setStartupReport(false);
            }
        }
    };

    return (
        <div className="table-main relative border border-dark overflow-auto shadow-md sm:rounded-lg max-h-80">
            <table className="w-full text-left">
                <thead className="text-xs font-semibold border-b border-dark top-0 sticky text-neutral-grey z-10">
                    <tr>
                        {!isPreview && (
                        <th scope="col" className="px-3 py-4">
                            <DifaCheckbox onChange={(e: ChangeEvent<HTMLInputElement>) => { }} />
                        </th>
                        )}
                        <th scope="col" className="px-3 py-4">
                            <div className="flex items-center whitespace-nowrap">
                                Report name
                            </div>
                        </th>
                        <th scope="col" className="px-3 py-4">
                            <div className="flex items-center cursor-pointer" onClick={() => requestSort('startDate')}>
                                Start date
                                {sortConfig?.key === 'startDate' && sortConfig.direction === 'asc' ? (
                                    <UpArrow className="ms-1.5" />
                                ) : (
                                    <DownArrow className="ms-1.5" />
                                )}
                            </div>
                        </th>
                        <th scope="col" className="px-3 py-4">
                            <div className="flex items-center cursor-pointer" onClick={() => requestSort('completeDate')}>
                                Complete date
                                {sortConfig?.key === 'completeDate' && sortConfig.direction === 'asc' ? (
                                    <UpArrow className="ms-1.5" />
                                ) : (
                                    <DownArrow className="ms-1.5" />
                                )}
                            </div>
                        </th>
                        <th scope="col" className="px-3 py-4">
                            <div className="flex items-center">
                                Problem
                            </div>
                        </th>
                        <th scope="col" className="px-3 py-4">
                            <div className="flex items-center">
                                Resolution
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody className="text-sm font-normal text-neutral-grey">
                    {loading ? (
                        <tr>
                            <td colSpan={6} className="text-lg text-center py-5">
                                Loading...
                            </td>
                        </tr>
                    ) : filteredDifaServices?.length > 0 ? (
                        filteredDifaServices.map((difaRundaysDetail: any, index: number) => (
                            <tr key={index} className="border-b border-dark">
                                {!isPreview && (
                                <th scope="row" className="px-3 py-4">
                                    <DifaCheckbox onChange={(e: ChangeEvent<HTMLInputElement>) => { }} />
                                </th>
                                )}
                                <th scope='row' className="px-3 py-4 text-Indigo whitespace-nowrap hover:underline cursor-pointer" onClick={() => openReport(difaRundaysDetail?.name)}>{difaRundaysDetail?.name}</th>
                                <td className="px-3 py-4">{formatDateInNumbers(difaRundaysDetail?.startDate || "-")}</td>
                                <td className="px-3 py-4">{formatDateInNumbers(difaRundaysDetail?.completeDate) || "-"}</td>
                                <td className="px-3 py-4">{difaRundaysDetail?.serviceProblem}</td>
                                <td className="px-3 py-4 w-2/5">{difaRundaysDetail?.serviceResolution ? difaRundaysDetail?.serviceResolution.slice(0, 80) + "...." : "-"}</td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan={6} className="text-lg text-center py-5">
                                No data found
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
            {showReport && (
                <MainComponent open={showReport} onCloseModal={setShowReport} />
            )}
            {showStartup && (
                <StartupReport open={showStartup} onCloseModal={setStartupReport} />
            )}
            {showPullReport && (
                <PullReport open={showStartup} onCloseModal={setPullReport} />
            )}
        </div>
    );
}

export default GeneralReportTable;