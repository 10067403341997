import { Dialog, DialogBackdrop, DialogPanel } from "@headlessui/react";
import { useEffect, useRef, useState } from "react";
import closeIcon from "./../../../images/close-icon.svg";
import DeleteIcon from './../../../images/trash-01.svg';

import {
  ImageEditorComponent,
  Theme,
} from "@syncfusion/ej2-react-image-editor";
import {
  Browser,
  getComponent,
  isNullOrUndefined,
  registerLicense,
} from "@syncfusion/ej2-base";
import { getFileType, toDataURL } from "../services/UtilityService";
import { useDispatch, useSelector } from "react-redux";
import RemoveConfirmation from "./RemoveConfirmation";
import { removeGallery } from "../../difaPacketBuilder/difaPacketBuilderSlice";
registerLicense(process.env.REACT_APP_SYNCFUSION_LICENSE_KEY!);

interface EditGalleryComponentProps {
  show: boolean;
  wellApiNumber: string;
  source: string;
  galleryDetails?: any;
  onGalleryClose?: () => void;
  onRemoveGallery?: (galleryId: string) => void
}

const EditGalleryComponent: React.FC<EditGalleryComponentProps> = ({
  show,
  wellApiNumber,
  onGalleryClose = () => { },
  galleryDetails = {},
  onRemoveGallery = (galleryId: string) => { },
  source,
}) => {
  const [showDialog, setShowDialog] = useState(false);
  const [showRemove, setShowRemove] = useState(false);
  const [removeMode, setRemoveMode] = useState('gallery');
  const dispatch = useDispatch();
  const isPreview = source === "preview-packet";

  const toolbar = [
    "Annotate",
    "Undo",
    "Redo",
    "Transform",
    "Finetune",
    "Crop",
    "Filter",
    "Frame",
    "Resize",
    "Redact",
    "Confirm",
    "Reset",
    "ZoomIn",
    "ZoomOut",
    "Pan",
    "Save",
    { id: 'delete', template: `<div><img src='${DeleteIcon}'/></div>`, align: 'Right' }
  ] as any;
  useEffect(() => {
    setShowDialog(show);
  }, [show]);
  const close = () => {
    setShowDialog(false);
    onGalleryClose();
  };

  useEffect(() => {
    if (galleryDetails && galleryDetails.reportImageDetails && galleryDetails.reportImageDetails.length)
      setTimeout(() => {
        setActiveItem(
          galleryDetails.reportImageDetails[0]
        );
      });
  }, []);

  let imgObj: any = useRef(null);
  const [activeItem, setActiveItem] = useState(null as any);

  const handleRemove = (remove: boolean) => {
    setShowRemove(false);
    if (remove) {
      onRemoveGallery(galleryDetails.galleryID);
      setShowDialog(false);
      onGalleryClose();
    }
  }

  const handleChangeActiveItem = (item: any) => {
    setActiveItem(item);
    imageEditorCreated(item);
  }

  const toolbarItemClicked = (args: any) => {
    if (args.item.id === 'delete') {
      setShowRemove(true);
      setRemoveMode('single');
    }
  }

  const imageEditorCreated = (item: any) => {
    if (item && imgObj) {
      toDataURL(item.fileLink).then((dataUrl: any) => {
        const fileType = getFileType(item.fileLink);
        dataUrl = dataUrl.replace(
          "data:application/octet-stream;base64",
          `data: image/${fileType}; base64`
        );
        imgObj.current.open(dataUrl);
      });
    }
  };
  // Handler used to reposition the tooltip on page scroll
  const onScroll = () => {
    if (document.getElementById("image-editor_sliderWrapper")) {
      let slider = getComponent(
        document.getElementById("image-editor_sliderWrapper") as any,
        "slider"
      ) as any;
      slider.refreshTooltip(slider.tooltipTarget);
    }
  };
  if (!isNullOrUndefined(document.getElementById("right-pane"))) {
    (document.getElementById("right-pane") as any).addEventListener(
      "scroll",
      onScroll.bind(this)
    );
  }
  return (
    <>
      {showDialog && (
        <Dialog open={showDialog} onClose={close} className="relative z-50">
          <DialogBackdrop
            transition
            className="fixed inset-0 backdrop-blur-sm transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
          />

          <div className="fixed inset-0 z-50 w-screen">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <DialogPanel
                transition
                className="flex dialog-max-height relative transform overflow-hidden rounded-lg difa-background-with-border text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-sm md:min-w-4/5 md:w-4/5 md:max-w-full data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
              >
                <div className="difa-widget-containers">
                  <div className="header-main border-bottom">
                    <div className="flex flex-row justify-between">
                      <div>
                        {isPreview ? (
                          <h3>Image gallery</h3>
                        ) : (
                          <h3>{galleryDetails.galleryName} gallery</h3>
                        )}
                      </div>
                      <a href="javascript:void(0)" onClick={close}>
                        <img src={closeIcon} alt="close" />
                      </a>
                    </div>
                  </div>
                  <div className="pl-6 pr-6 w-full flex flex-col overflow-y-auto image-container pb-16 table-main">
                    {showDialog && galleryDetails && galleryDetails.reportImageDetails && (
                      <div className="flex">
                        <div className="w-full max-w-80 pt-5 pr-5">
                          <h3>Images ({galleryDetails.reportImageDetails.length})</h3>
                          <a className="mt-3 difa-background-with-border w-full p-1 rounded-xl flex items-center justify-center gap-2">
                            <span className="text-2xl">+</span> Add images
                          </a>
                          <div className="mt-6 flex gap-3 flex-wrap">
                            {
                              galleryDetails.reportImageDetails.map((photo: any) => (
                                <div className="flex flex-col gap-3 max-w-48 cursor-pointer" onClick={() => handleChangeActiveItem(photo)}>
                                  <div className={`difa - widget - containers max - w - 36 h - 28 pt - 2 pb - 2 field - background ${activeItem === photo ? 'active-item-border' : ''} `}>
                                    <div className="w-full h-full difa-background flex items-center">
                                      <img
                                        className="h-20 w-32"
                                        src={photo.fileLink}
                                        alt="meter"
                                      ></img>
                                    </div>
                                  </div>
                                </div>
                              ))
                            }
                          </div>
                        </div>
                        <div className="w-full">
                          <div className="control-pane w-full">
                            <div>
                              <div className="row">
                                <div className="col-lg-12">
                                  <div className="difa-img-editor-container border-left">
                                    {activeItem && (
                                      <div className="inline ">
                                        <div className="inline">
                                          <ImageEditorComponent
                                            id="image-editor"
                                            ref={imgObj}
                                            toolbar={toolbar}
                                            created={() => imageEditorCreated(activeItem)}
                                            theme={Theme.Tailwind}
                                            toolbarItemClicked={(arags) => toolbarItemClicked(arags)}
                                          />
                                        </div>
                                        <div className="flex flex-col gap-5 py-5 px-6 bg-dark-800 border-left">
                                          <textarea
                                            className="card-textarea"
                                            maxLength={500}
                                            rows={3}
                                            placeholder="Write a short description to help people understand what this image is"
                                          ></textarea>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="flex-row flex border-top justify-between items-center h-16 mr-6 ">
                    {!isPreview && (
                      <div className="btn-remove ml-6">
                        <button
                          className="header-btn-text w-20"
                          onClick={() => {
                            setRemoveMode('gallery');
                            setShowRemove(true);
                          }}
                        >
                          Remove
                        </button>
                      </div>
                    )}
                    <div className="flex  gap-3">
                      <div className="secondary-button">
                        <button
                          className="header-btn-text w-20"
                          onClick={close}
                        >
                          Cancel
                        </button>
                      </div>
                      <div className="primary-button">
                        <button
                          className="header-btn-text"
                          onClick={close}
                          disabled={false}
                        >
                          Next
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </DialogPanel>
            </div>
          </div >
        </Dialog >
      )}

      <RemoveConfirmation
        show={showRemove}
        mode={removeMode as any}
        close={(remove: boolean) => handleRemove(remove)}
      />
    </>
  );
};

export default EditGalleryComponent;
